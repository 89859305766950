import { useState, useEffect } from "react";
import axios from "axios-config";
import ConsoleHelper from "utils/ConsoleHelper";

export default function useUserSelect({
  multiSelect = false,
  includeNotAssigned = false,
} = {}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(multiSelect ? [] : null);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const openPopover = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsSelecting(true);
  };

  const closePopover = (event) => {
    event && event.stopPropagation();
    setAnchorEl(null);
    setIsSelecting(false);
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/organizations/users");
      if (response.status === 200) {
        if (includeNotAssigned) {
          setUsers([
            { id: "none", name: "Not Assigned", email: "" },
            ...response.data,
          ]);
        } else {
          setUsers(response.data);
        }
      }
    } catch (error) {
      ConsoleHelper(error, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (option) => {
    if (multiSelect) {
      setSelectedUsers((prev) => {
        if (option.id === "none") {
          // Clear all if "Not Assigned" is selected
          return [];
        }
        const alreadySelected = prev.find((u) => u.id === option.id);
        return alreadySelected
          ? prev.filter((u) => u.id !== option.id)
          : [...prev.filter((u) => u.id !== "none"), option];
      });
    } else {
      setSelectedUsers(option.id === "none" ? null : option);
      closePopover();
    }
  };

  const filterOptions = (options, { inputValue }) =>
    options.filter(
      (opt) =>
        opt.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        opt.email.toLowerCase().includes(inputValue.toLowerCase())
    );

  useEffect(() => {
    if (isSelecting) {
      fetchUsers();
    }
  }, [isSelecting]);

  return {
    anchorEl,
    isSelecting,
    users,
    selectedUsers,
    openAutocomplete,
    setOpenAutocomplete,
    openPopover,
    closePopover,
    handleSelect,
    filterOptions,
    loading,
  };
}
