import {
  TextField,
  Grid,
  Stack,
  Box,
  Divider,
  InputAdornment,
  Button,
  Typography,
  Chip,
  Skeleton,
  Tooltip,
  CircularProgress,
} from "@mui/material";

import BrandsSearch from "./BrandsSearch";
import CustomersSearch from "./CustomersSearch";
import CategorySearch from "./CategorySearch";

import { useSelector, useDispatch } from "react-redux";
import { newEntryModalActions } from "../../store/new-entry-modal";
import { useTranslation } from "react-i18next";
import PasswordTextField from "./PasswordTextField";
import { useEffect, useRef, useState } from "react";
import axios from "axios-config";
import { blue, grey, lightGreen, red, yellow } from "@mui/material/colors";
import { S } from "memfs/lib/constants";
import {
  AutoAwesome,
  CheckCircleOutline,
  ErrorOutlineOutlined,
  PendingOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";

const Step1 = (props) => {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.auth.currency);

  const modalState = useSelector((state) => state.newEntryModal);

  const dispatch = useDispatch();
  const updateModel = (payload) => {
    dispatch(newEntryModalActions.updateModel(payload));
  };
  const updateMalfunction = (payload) => {
    dispatch(newEntryModalActions.updateMalfunction(payload));
  };
  const updatePassword = (payload) => {
    dispatch(newEntryModalActions.updatePassword(payload));
  };
  const updatePasswordIsPasswordPattern = (payload) => {
    dispatch(newEntryModalActions.updateIsPasswordPattern(payload));
  };

  const updateImei = (payload) => {
    dispatch(newEntryModalActions.updateImei(payload));
  };
  const updateNote = (payload) => {
    dispatch(newEntryModalActions.updateNote(payload));
  };
  const updateCategory = (payload) => {
    dispatch(newEntryModalActions.updateCategory(payload));
  };

  const updateClient = (payload) => {
    dispatch(newEntryModalActions.updateClient(payload));
  };

  const updateEstimatedServices = (payload) => {
    dispatch(newEntryModalActions.updateEstimatedServices(payload));
  };
  const updateRepairPaymentDeposit = (payload) => {
    dispatch(newEntryModalActions.updateRepairPaymentDeposit(payload));
  };

  return (
    <Box id="step">
      <Stack direction={"column"} spacing={2} pt={1}>
        {/* TO BE ADDED */}
        {/* <AiInput /> */}

        <CategorySearch
          onCategoryUpdate={updateCategory}
          value={modalState.entryData.category}
        />
        <Stack direction={"row"} spacing={2}>
          <BrandsSearch
            fullWidth
            required
            token={props.token}
            updateState={props.updateState}
            brandState={props.brand}
            // suggestionBrand={brand}
          />

          <TextField
            // if model === modalState.entryData.model
            // make it a saturated blue
            sx={
              {
                // backgroundColor:
                //   model !== "" &&
                //   model === modalState.entryData.model &&
                //   yellow[100],
                // color:
                //   model !== "" && model === modalState.entryData.model && "white",
                // "& .MuiFilledInput-root": {
                //   backgroundColor: "inherit",
                // },
                // "& .Mui-focused": {
                //   borderColor: lightGreen[200],
                // },
              }
            }
            fullWidth
            autoComplete={"off"}
            required
            label={t("Model")}
            id="model"
            variant="filled"
            size="small"
            value={modalState.entryData.model}
            onChange={(event) => updateModel(event.target.value)}
          />
        </Stack>
        <TextField
          fullWidth
          autoComplete={"off"}
          // error={"test"}
          // helperText="This field is required"
          required
          label={t("Malfunction")}
          id="malfunction"
          multiline
          rows={2}
          variant="filled"
          size="small"
          value={modalState.entryData.malfunction}
          onChange={(event) => updateMalfunction(event.target.value)}
        />
        <CustomersSearch
          required
          onOptionSelect={updateClient}
          state={modalState.entryData.customer}
        />
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <TextField
            fullWidth
            autoComplete={"off"}
            // error={"test"}
            // helperText="This field is required"
            label={t("Estimated services")}
            id="estimatedServices"
            variant="filled"
            size="small"
            value={modalState.entryData.estimatedServices || ""}
            onChange={(event) => updateEstimatedServices(event.target.value)}
            placeholder={"0.00"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{currency}</InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            autoComplete={"off"}
            // error={"test"}
            // helperText="This field is required"
            label={t("Deposit")}
            id="repairPaymentDeposit"
            variant="filled"
            size="small"
            value={modalState.entryData.repairPaymentDeposit || ""}
            onChange={(event) => updateRepairPaymentDeposit(event.target.value)}
            placeholder={"0.00"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{currency}</InputAdornment>
              ),
            }}
          />
        </Stack>
        <Divider />

        <Stack direction={"row"} spacing={2}>
          <PasswordTextField
            value={modalState.entryData.password}
            setValue={updatePassword}
            isPasswordPattern={modalState.entryData.isPasswordPattern}
            setIsPasswordPattern={updatePasswordIsPasswordPattern}
          />

          {/* <TextField
          fullWidth
          autoComplete={'off'}
          label={t("Password")}
          id="password"
          variant="filled"
          size="small"
          value={modalState.entryData.password}
          onChange={(event) => updatePassword(event.target.value)}
        /> */}
          <TextField
            fullWidth
            autoComplete={"off"}
            label="IMEI"
            id="imei"
            variant="filled"
            size="small"
            value={modalState.entryData.imei}
            onChange={(event) => updateImei(event.target.value)}
          />
        </Stack>
        <TextField
          id="note"
          autoComplete={"off"}
          label={t("Note")}
          multiline
          rows={2}
          variant="filled"
          value={modalState.entryData.note}
          onChange={(event) => updateNote(event.target.value)}
        />
      </Stack>
    </Box>
  );
};

const AiInput = () => {
  const { t } = useTranslation();
  const [repairName, setRepairName] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [confidence, setConfidence] = useState("");
  const [category, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [suggestionState, setSuggestionState] = useState("empty");

  const requestSuggestion = async (input) => {
    setSuggestionState("loading");
    try {
      const response = await axios.post(`/assistant/extract-brand-model`, {
        name: input ? input : repairName,
      });
      console.log(response);
      if (response.data.brand) {
        setBrand(response.data.brand);
        // dispatch(newEntryModalActions.updateBrand(response.data.brand));
      }
      if (response.data.model) {
        setModel(response.data.model);
        // updateModel(response.data.model);
      }
      if (response.data.category) {
        setCategory(response.data.category);
      }
      if (response.data.confidence) {
        setConfidence(response.data.confidence);
      }
      setSuggestionState("loaded");
    } catch (error) {
      setSuggestionState("empty");
      console.error("Error fetching suggestion:", error);
    }
  };

  const debounceTimeout = useRef(null);
  // Flag to detect if the change came from a paste event
  const pasteTriggered = useRef(false);

  const handlePaste = (event) => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);

    event.preventDefault(); // Prevent the default paste behavior
    const pastedText = event.clipboardData.getData("Text");
    pasteTriggered.current = true; // Mark that a paste has occurred
    setRepairName(pastedText);
    requestSuggestion(pastedText); // Immediately fire the request
  };

  useEffect(() => {
    if (repairName) {
      if (pasteTriggered.current) {
        // Reset the flag so future changes are debounced normally.
        pasteTriggered.current = false;
      } else {
        // For non-paste changes, debounce the request.
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
          requestSuggestion();
        }, 2000); // Adjust the debounce delay as needed.
      }
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [repairName]);

  const handleBlur = (e) => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    requestSuggestion();
  };

  return (
    <>
      <TextField
        fullWidth
        autoComplete="off"
        required
        label={t("Name")}
        id="repair-name"
        variant="filled"
        size="small"
        value={repairName}
        onChange={(event) => setRepairName(event.target.value)}
        onPaste={handlePaste}
        onBlur={handleBlur}
      />
      <Tooltip
        title={t("Information provided by the AI assistant")}
        placement="top"
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          {suggestionState === "empty" && (
            <>
              <RadioButtonUncheckedOutlined />
              <Typography variant={"caption"} color={"error"}>
                {t("No suggestion found")}
              </Typography>
            </>
          )}
          {suggestionState === "loading" && (
            <>
              <CircularProgress size={"24px"} />
              <Typography variant={"caption"} color={"info"}>
                {t("Loading suggestion...")}
              </Typography>
            </>
          )}

          {suggestionState === "loaded" && (
            <>
              {confidence && confidence > 0.8 ? (
                <CheckCircleOutline sx={{ color: lightGreen[500] }} />
              ) : confidence > 0.6 ? (
                <PendingOutlined sx={{ color: red[400] }} />
              ) : (
                <ErrorOutlineOutlined sx={{ color: red[700] }} />
              )}

              <Typography variant={"caption"} color={"success"}>
                {confidence && confidence > 0.8
                  ? "High confidence"
                  : confidence > 0.6
                  ? "Medium confidence"
                  : "Low confidence"}
              </Typography>
            </>
          )}
        </Stack>
      </Tooltip>

      <Stack spacing={"5px"}>
        <Stack direction={"row"} spacing={2}>
          <AutoAwesome />
          <Stack direction={"row"} spacing={"50px"}>
            <Stack>
              <Typography noWrap>
                <strong>Brand:</strong>
              </Typography>
              <Typography noWrap>
                {suggestionState === "empty" ? (
                  "-"
                ) : suggestionState === "loading" ? (
                  <Skeleton width={50} />
                ) : (
                  brand
                )}
              </Typography>
            </Stack>
            <Stack>
              <Typography noWrap>
                <strong>Model:</strong>
              </Typography>
              <Typography noWrap>
                {suggestionState === "empty" ? (
                  "-"
                ) : suggestionState === "loading" ? (
                  <Skeleton width={50} />
                ) : (
                  model
                )}
              </Typography>
            </Stack>
            <Stack>
              <Typography noWrap>
                <strong>Category:</strong>
              </Typography>
              <Typography noWrap>
                {suggestionState === "empty" ? (
                  "-"
                ) : suggestionState === "loading" ? (
                  <Skeleton width={50} />
                ) : (
                  category
                )}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
export default Step1;
