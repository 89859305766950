import * as React from "react";
import {
  Button,
  InputAdornment,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  ToggleButtonGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { memo } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import ColoredToggleButton from "../../../common/ColoredToggleButton";
import { getCurrencySymbol } from "../../../utils/currency";
import useUpdatePaymentField from "hooks/mutations/orders/useUpdatePayment";
import EditableNumber from "../EditableNumber";
import { useTranslation } from "react-i18next";

const EditPaymentDialog = ({
  dialogOpen,
  handleDialogClose,
  payment,
  onEdit,
}) => {
  const currency = useSelector((state) => state.auth.currency);
  const updatePaymentField = useUpdatePaymentField();
  const { t } = useTranslation();
  const onBlur = async (key, newValue) => {
    const value = newValue;
    updatePaymentField.mutate({
      repairOrderId: payment.repairOrderId,
      paymentId: payment.id,
      key,
      value,
    });
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle variant="h4">Payment Edit</DialogTitle>
      <DialogContent>
        <Stack direction={"row"} spacing={2} pt={3}>
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={payment.paymentType}
            onChange={(event) => onBlur("paymentType", event.target.value)}
          >
            <ColoredToggleButton color="green" value={"in"}>
              Pay in
            </ColoredToggleButton>
            <ColoredToggleButton color="red" value={"out"}>
              Pay out{" "}
            </ColoredToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Stack direction={"row"} spacing={2} pt={3}>
          <FormControl fullWidth variant="filled">
            {/* <InputLabel id="Amount">Amount</InputLabel> */}
            <EditableNumber
              label={t("Amount")}
              value={payment.amount}
              onUpdate={(value) => onBlur("amount", value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {getCurrencySymbol(currency)}
                  </InputAdornment>
                ),
              }}
              onlyFloat
              onlyPositive
              withinRange={[0, 9999999]}
            />
          </FormControl>
          <FormControl fullWidth variant="filled">
            <InputLabel>{t("Payment method")}</InputLabel>
            <Select
              variant="filled"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={t("Payment method")}
              value={payment.paymentMethod}
              onChange={(event) => onBlur("paymentMethod", event.target.value)}
            >
              <MenuItem value={0}>{t("Cash")}</MenuItem>
              <MenuItem value={1}>{t("Card")}</MenuItem>
              <MenuItem value={2}>{t("Transfer")}</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <FormControlLabel
          control={
            <Checkbox
              checked={payment.isDeposit}
              onChange={(e) => onBlur("isDeposit", e.target.checked)}
            />
          }
          label="Deposit"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(EditPaymentDialog);
