// useUpdateOrderStatusMutation.js
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { setRepairParent } from "api/repair-orders";

const useSetRepairParent = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(setRepairParent, {
    onMutate: async ({ repairOrderId, parentOrderId }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: ["repairOrder", repairOrderId],
      });

      // Snapshot the previous data
      const previousOrder = queryClient.getQueryData([
        "repairOrder",
        repairOrderId,
      ]);

      if (!previousOrder) {
        console.error("No previous order found");
        console.log("repairOrderId", repairOrderId);
        return;
      }

      return { previousOrder };
    },
    onError: (error, variables, context) => {
      if (context?.previousOrder) {
        queryClient.setQueryData(
          ["repairOrder", variables.repairOrderId],
          context.previousOrder
        );
      }
      console.log(error.response);
      enqueueSnackbar("Failed to update repair", { variant: "error" });
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar("Repair updated successfully", { variant: "success" });
      //set data
      queryClient.setQueryData(["repairOrder", variables.repairOrderId], data);
    },
    onSettled: (data, error, variables) => {
      //   queryClient.invalidateQueries(["repairOrder", variables.repairOrderId]);
    },
  });
};

export default useSetRepairParent;
