// useUpdateOrderStatusMutation.js
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { addCommentToRepairOrder } from "api/repair-orders";

const useAddComment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(addCommentToRepairOrder, {
    onMutate: async ({
      repairOrderId,
      inputValue,
      isPublicBool,
      fileIds,
      user,
    }) => {
      console.log("Mutation logic here");
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: ["repairOrder", repairOrderId],
      });

      // Snapshot the previous data
      const previousOrder = queryClient.getQueryData([
        "repairOrder",
        repairOrderId,
      ]);
      if (!previousOrder) {
        console.error("No previous order found");
        console.log("repairOrderId", repairOrderId);
        return;
      }
      // Create a new comment object with user data
      const tempId = `temp-${Date.now()}`;
      const newComment = {
        id: tempId,
        body: inputValue,
        isPublic: isPublicBool,
        fileIds: fileIds || [],
        createdAt: dayjs().toISOString(),
        user: {
          id: user.id,
          name: user.name,
          organization: user.organization,
        },
        commentFiles: [],
        isCreating: true,
        // ...other necessary fields
      };

      // Optimistically update the repair order with the new comment
      queryClient.setQueryData(["repairOrder", repairOrderId], (old) => ({
        ...old,
        updatedAt: dayjs().toISOString(),

        comments: [...(old.comments || []), newComment],
      }));

      // Return context with the previous order for rollback
      return { previousOrder, tempId };
    },
    onError: (error, variables, context) => {
      if (context?.previousOrder) {
        queryClient.setQueryData(
          ["repairOrder", variables.repairOrderId],
          context.previousOrder
        );
      }
      console.error(error?.response?.data?.message || "Error adding comment");
      enqueueSnackbar("Failed to add comment", { variant: "error" });
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar("Comment added successfully", { variant: "success" });
    },
    onSettled: (data, error, variables) => {
      console.log("onSettled");
      queryClient.invalidateQueries(["repairOrder", variables.repairOrderId]);
    },
  });
};

export default useAddComment;
