import * as React from "react";

import { Button } from "@mui/material";
import { useEffect, useState, memo } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import EditableText from "../EditableText";

const EditClientDialog = ({
  dialogOpen,
  handleDialogClose,
  customer,
  onEdit,
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});

  const onBlur = (key, newValue) => {
    const value = newValue;
    //name cannot be empty
    if (key === "name" && value.trim() === "") {
      // enqueueSnackbar("Name cannot be empty", { variant: "error" });
      setErrors({ name: "Name cannot be empty" });
      return;
    }
    setErrors({});
    onEdit({ customer, key, value });
  };

  if (!customer) return null;

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle variant="h4">{t("Customer Edit")}</DialogTitle>
      <DialogContent>
        <Stack direction={"column"} spacing={2}>
          <EditableText
            id="name"
            fullWidth
            value={customer.name}
            label={t("Name")}
            onUpdate={(newValue) => onBlur("name", newValue)}
            error={!!errors.name}
            helperText={errors.name || ""}
          />
          <EditableText
            fullWidth
            value={customer.phone}
            label={t("Phone")}
            onUpdate={(newValue) => onBlur("phone", newValue)}
          />
          <EditableText
            fullWidth
            value={customer.email}
            label={t("Email")}
            onUpdate={(newValue) => onBlur("email", newValue)}
          />
          <EditableText
            fullWidth
            value={customer.taxIdentifier}
            label={t("Tax identifier")}
            onUpdate={(newValue) => onBlur("taxIdentifier", newValue)}
          />
          <EditableText
            fullWidth
            value={customer.address}
            label={t("Address")}
            onUpdate={(newValue) => onBlur("address", newValue)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>{t("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(EditClientDialog);
