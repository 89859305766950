import React from "react";
import { styled } from "@mui/material/styles";
import { Snackbar, Box } from "@mui/material";

const SelectionSnackbarRoot = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.selectionSnackbar.bg,
  color: theme.palette.selectionSnackbar.text,
  padding: "10px 20px",
  borderRadius: 50,

  // Regular Button
  "& .MuiButton-root": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&.Mui-disabled": {
      backgroundColor: "#cccccc",
      color: "#999999",
    },
  },

  // IconButton
  "& .MuiIconButton-root": {
    color: theme.palette.selectionSnackbar.iconButton.color,
    "&:hover": {
      backgroundColor: theme.palette.selectionSnackbar.iconButton.bgHover, // Use a suitable hover background color
    },
    "&.Mui-disabled": {
      color: "#999999",
    },
  },
}));

export default function SelectionSnackbar({ open, children }) {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <SelectionSnackbarRoot>{children}</SelectionSnackbarRoot>
    </Snackbar>
  );
}
