import { Stack, Tooltip, Typography } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useTranslation } from "react-i18next";

const CreatedAtDate = ({ createdAt }) => {
  const { t } = useTranslation();
  let dayjs = require("dayjs");

  return (
    <Tooltip enterDelay={20} placement="top" title={t("Entry date")}>
      <Stack
        gap={"5px"}
        direction={"row"}
        border={"0px solid"}
        flexWrap={"nowrap"}
        borderRadius={"5px"}
        borderColor={"darkGrey.main"}
        alignItems={"center"}
        py={"2px"}
        fontSize="1rem"
        color={"darkGrey.main"}
      >
        {" "}
        <CalendarMonthOutlinedIcon sx={{ fontSize: "1rem" }} />
        <Typography fontSize={"0.75rem"} fontWeight={"600"}>
          {dayjs(createdAt).format("DD MMM. YYYY")}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default CreatedAtDate;
