import axios from "axios-config";
import ConsoleHelper from "utils/ConsoleHelper";

// ========= API FUNCTIONS RECOMMENDATION =========
// React Query and the nature of the API functions,
// destructured parameters are generally the better choice.

export const fetchRepairOrder = async ({ repairOrderId }) => {
  if (!Number.isInteger(repairOrderId)) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.get(`/repair-orders/${repairOrderId}`);

  return response.data;
};

export const updateOrderStatus = async ({ repairOrderId, status }) => {
  // console.log(`API: repairOrderId: ${repairOrderId}, statusId: ${statusId}`);
  if (!repairOrderId || !status || !status.id) {
    console.log("API: updateOrderStatus - Missing parameters:", {
      repairOrderId: repairOrderId || "Missing",
      status: status || "Missing",
      statusId: status?.id || "Missing",
    });
    throw new Error("Missing required parameters");
  }
  const response = await axios.patch(
    `/repair-orders/${repairOrderId}/status/${status.id}`
  );
  if (response.status !== 200) throw new Error("Failed to update status");
  return response.data;
};

export const updateOrderStatusWithResolution = async ({
  repairOrderId,
  status,
  resolution,
}) => {
  // const response = await axios.patch(`/repair-orders/${repairOrderId}/status/${statusIndexSelected}`, { resolutionIndex: resolutionId });
  if (!repairOrderId || !resolution || !status || !resolution.id || !status.id)
    throw new Error("Missing required parameters");
  //TODO: change resolutionIndex to resolutionId
  const response = await axios.patch(
    `/repair-orders/${repairOrderId}/status/${status.id}`,
    { resolutionId: resolution.id }
  );
  if (response.status !== 200) throw new Error("Failed to update status");
  return response.data;
};

export const acceptOrderRequest = async ({ repairOrderId }) => {
  //be sure repairOrderId is not null and is a number
  if (!repairOrderId || !Number.isInteger(repairOrderId)) {
    throw new Error("Missing required parameters");
  }
  const response = await axios.put(
    `repair-orders/outsource/${repairOrderId}/accept`
  );
  if (response.status !== 200) throw new Error("Failed to accept request");
  return response.data;
};

export const declineOrderRequest = async ({ repairOrderId }) => {
  //be sure repairOrderId is not null and is a number
  if (!repairOrderId || !Number.isInteger(repairOrderId)) {
    throw new Error("Missing required parameters");
  }
  const response = await axios.delete(
    `repair-orders/outsource/${repairOrderId}`
  );
  if (response.status !== 200) throw new Error("Failed to accept request");
  return response.data;
};

export const bulkUpdateStatus = async ({
  repairOrderIds,
  status,
  resolution,
}) => {
  if (!repairOrderIds || !status || !status.id) {
    //log which parameter is missing
    throw new Error("Missing required parameters");
  }
  let resolutionId = null;
  if (resolution) {
    resolutionId = resolution.id;
  }
  const response = await axios.patch("/repair-orders/bulk-update/status", {
    repairOrderIds,
    statusId: status.id,
    resolutionId: resolutionId,
  });
  // if (response.status !== 200) throw new Error('Failed to update status');
  return response.data;
};

export const bulkMarkAsPaid = async ({ repairOrderIds, paymentMethod }) => {
  if (
    !Array.isArray(repairOrderIds) ||
    repairOrderIds.length === 0 ||
    typeof paymentMethod !== "number"
  ) {
    ConsoleHelper(repairOrderIds, paymentMethod);
    throw new Error("Missing required parameters");
  }

  const response = await axios.post(`/repair-orders/bulk/mark-as-paid`, {
    repairOrderIds,
    paymentMethod: paymentMethod,
  });
  if (response.status !== 200) throw new Error("Failed to mark as paid");
  return response.data;
};

export const addCommentToRepairOrder = async ({
  repairOrderId,
  inputValue,
  isPublicBool,
  fileIds,
}) => {
  if (
    !repairOrderId ||
    (typeof inputValue !== "string" && !Array.isArray(fileIds)) ||
    typeof isPublicBool !== "boolean" ||
    (!inputValue && (!Array.isArray(fileIds) || fileIds.length === 0))
  ) {
    throw new Error("Missing required parameters");
  }
  const response = await axios.post(
    `/repair-order-comments/add-comment/${repairOrderId}`,
    {
      body: inputValue,
      isPublic: isPublicBool,
      fileIds: fileIds,
    }
  );
  if (response.status !== 200) throw new Error("Failed to add comment");
  return response.data;
};

export const patchRepairField = async ({ id, key, value }) => {
  if (!id || !key) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.patch(`repair-orders/${id}/${key}`, {
    [key]: value,
  });

  if (response.status !== 200) throw new Error("Failed to update field");

  return response.data;
};

export const addServiceToRepairOrder = async ({
  repairOrderId,
  name,
  quantity,
  unitPrice,
  discount,
  assignedTo,
  stock,
  isOutsource,
}) => {
  if (
    repairOrderId == null ||
    !name ||
    quantity == null ||
    unitPrice == null || // allows 0
    !assignedTo
  ) {
    console.log("API: addServiceToRepairOrder - Missing parameters:", {
      repairOrderId: repairOrderId ?? "Missing",
      name: name ?? "Missing",
      quantity: quantity ?? "Missing",
      unitPrice: unitPrice ?? "Missing",
      assignedTo: assignedTo ?? "Missing",
    });
    throw new Error("Missing required parameters");
  }

  const service = {
    name,
    quantity,
    unitPrice,
    discount,
    assignedToId: assignedTo.id,
    stock,
    isOutsource,
  };
  const response = await axios.post(
    `/repair-orders/${repairOrderId}/service`,
    service
  );
  if (response.status !== 200) throw new Error("Failed to add service");
  return response.data;
};

export const addPaymentToRepair = async ({
  repairOrderId,
  amount,
  paymentMethod,
  isDeposit,
  paymentType,
}) => {
  if (
    repairOrderId == null ||
    amount == null ||
    paymentMethod == null ||
    isDeposit == null ||
    paymentType == null
  ) {
    console.log("API: addPaymentToRepairOrder - Missing parameters:", {
      repairOrderId: repairOrderId ?? "Missing",
      amount: amount ?? "Missing",
      paymentMethod: paymentMethod ?? "Missing",
      isDeposit: isDeposit ?? "Missing",
      paymentType: paymentType ?? "Missing",
    });
    throw new Error("Missing required parameters");
  }
  const payment = {
    amount,
    paymentMethod,
    isDeposit,
    paymentType,
  };

  const response = await axios.post(
    `/repair-orders/${repairOrderId}/payment`,
    payment
  );
  if (response.status !== 200) throw new Error("Failed to add payment");
  return response.data;
};

export const updateLevelOfAttention = async ({
  repairOrderId,
  levelOfAttention,
}) => {
  if (!repairOrderId || levelOfAttention == null) {
    console.log("API: updateLevelOfAttention - Missing parameters:", {
      repairOrderId: repairOrderId || "Missing",
      levelOfAttention: levelOfAttention || "Missing",
    });

    throw new Error("Missing required parameters");
  }

  const response = await axios.patch(
    `/repair-orders/${repairOrderId}/level-of-attention`,
    { levelOfAttention }
  );

  if (response.status !== 200)
    throw new Error("Failed to update level of attention");

  return response.data;
};

export const deleteRepairService = async ({ repairOrderId, serviceId }) => {
  if (!serviceId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.delete(`/repair-orders/services/${serviceId}`);

  if (response.status !== 200) throw new Error("Failed to delete service");

  return response.data;
};

export const addPartToRepairOrder = async ({
  repairOrderId,
  productInventoryId,
  warehouseId,
}) => {
  if (!repairOrderId || !productInventoryId || !warehouseId) {
    throw new Error("Missing required parameters");
  }
  const part = {
    productInventoryId,
    warehouseId,
  };

  const response = await axios.post(
    `/repair-orders/${repairOrderId}/parts`,
    part
  );
  if (response.status !== 200) throw new Error("Failed to add part");
  return response.data;
};

export const deletePartFromRepairOrder = async ({ partId }) => {
  if (!partId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.delete(`/repair-orders/parts/${partId}`);

  if (![200, 202, 204].includes(response.status)) {
    throw new Error("Failed to delete part");
  }
  return response.data;
};

export const deletePaymentFromRepairOrder = async ({ paymentId }) => {
  if (!paymentId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.delete(`/repair-orders/payments/${paymentId}`);

  if (![200, 202, 204].includes(response.status)) {
    throw new Error("Failed to delete payment");
  }
  return response.data;
};

export const removeResolution = async ({ repairOrderId }) => {
  if (!repairOrderId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.delete(
    `/repair-orders/${repairOrderId}/resolution`
  );

  if (response.status !== 200) throw new Error("Failed to remove resolution");

  return response.data;
};

export const acceptProposedService = async ({ repairOrderId, serviceId }) => {
  if (!repairOrderId || !serviceId) {
    throw new Error("Missing required parameters");
  }
  // `/repair-orders/services/${service.id}/accept`

  const response = await axios.patch(
    `/repair-orders/services/${serviceId}/accept`
  );

  if (response.status !== 200) throw new Error("Failed to accept service");

  return response.data;
};

export const declineProposedService = async ({ repairOrderId, serviceId }) => {
  if (!repairOrderId || !serviceId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.patch(
    `/repair-orders/services/${serviceId}/decline`
  );

  if (response.status !== 200) throw new Error("Failed to decline service");

  return response.data;
};

export const removeAcceptService = async ({ repairOrderId, serviceId }) => {
  if (!repairOrderId || !serviceId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.patch(
    `/repair-orders/services/${serviceId}/remove-acceptance`
  );

  if (response.status !== 200) throw new Error("Failed to revert acceptance");

  return response.data;
};

export const pathServiceField = async ({ serviceId, key, value }) => {
  //only accepted keys
  const acceptedKeys = [
    "name",
    "unitPrice",
    "discount",
    "quantity",
    "stock",
    "assignedToId",
  ];
  if (!acceptedKeys.includes(key)) {
    throw new Error("Invalid key");
  }
  if (!serviceId || !key) {
    console.log("API: pathServiceField - Missing parameters:", {
      serviceId: serviceId || "Missing",
      key: key || "Missing",
    });
    throw new Error("Missing required parameters");
  }

  const response = await axios.patch(
    `/repair-orders/services/${serviceId}/${key}`,
    {
      [key]: value,
    }
  );

  if (response.status !== 200) throw new Error("Failed to update field");

  return response.data;
};

export const assignUserToService = async ({ serviceId, user }) => {
  const userId = user.id;
  if (!serviceId || !userId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.patch(
    `/repair-orders/services/${serviceId}/assign-to/${userId}`
  );

  if (response.status !== 200) throw new Error("Failed to assign service");

  return response.data;
};

export const assignUserToRepair = async ({ repairOrderId, assignedToId }) => {
  if (!repairOrderId) {
    throw new Error("Missing required parameters");
  }

  const userIdParam = assignedToId === null ? "null" : assignedToId;
  const response = await axios.patch(
    `/repair-orders/${repairOrderId}/assign/${userIdParam}`
  );

  if (response.status !== 200) throw new Error("Failed to assign service");

  return response.data;
};

export const patchPaymentField = async ({ paymentId, key, value }) => {
  if (!paymentId || !key) {
    throw new Error("Missing required parameters");
  }
  const response = await axios.patch(
    `repair-orders/payments/${paymentId}/${key}`,
    { value: value }
  );

  if (response.status !== 200) throw new Error("Failed to update field");

  return response.data;
};

export const deleteCommentFromRepairOrder = async ({ commentId }) => {
  if (!commentId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.delete(`/repair-orders/comment/${commentId}`);

  if (response.status !== 200) throw new Error("Failed to delete comment");

  return response.data;
};

export const patchDevice = async ({ repairOrderId, brand, model }) => {
  if (!repairOrderId || !brand || !model) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.patch(`repair-orders/${repairOrderId}/device`, {
    brand,
    model,
  });

  if (response.status !== 200) throw new Error("Failed to update device");

  return response.data;
};

export const patchRepairCustomer = async ({ repairOrderId, customer }) => {
  if (!repairOrderId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.patch(
    `repair-orders/${repairOrderId}/customer`,
    { customerId: customer ? customer.id : null }
  );

  if (response.status !== 200) throw new Error("Failed to update customer");

  return response.data;
};

export const patchCustomerData = async ({ customerId, key, value }) => {
  if (!customerId || !key) {
    console.log("API: patchCustomerData - Missing parameters:", {
      customerId: customerId || "Missing",
      key: key || "Missing",
    });
    throw new Error("Missing required parameters");
  }
  const response = await axios.patch(`customers/${customerId}/${key}`, {
    value,
  });

  if (response.status !== 200) throw new Error("Failed to update customer");

  return response.data;
};

export const patchRepairDeviceCategory = async ({
  repairOrderId,
  deviceCategoryId,
}) => {
  if (!repairOrderId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.patch(
    `repair-orders/${repairOrderId}/category`,
    { id: deviceCategoryId }
  );

  if (response.status !== 200) throw new Error("Failed to update category");

  return response.data;
};

export const removePasswordPattern = async ({ repairOrderId }) => {
  if (!repairOrderId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.patch(
    `/repair-orders/${repairOrderId}/password`,
    {
      password: "",
      isPasswordPattern: false,
    }
  );

  if (response.status !== 200) throw new Error("Failed to remove pattern");

  return response.data;
};

export const patchRepairPassword = async ({
  repairOrderId,
  password,
  isPasswordPattern,
}) => {
  if (!repairOrderId) {
    throw new Error("Missing required parameters");
  }
  const response = await axios.patch(
    `/repair-orders/${repairOrderId}/password`,
    {
      password,
      isPasswordPattern,
    }
  );

  if (response.status !== 200) throw new Error("Failed to update password");

  return response.data;
};

export const getRepairParentOptions = async ({ repairOrderId }) => {
  if (!repairOrderId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.get(
    `/repair-orders/${repairOrderId}/parent-options`
  );

  console.log("response", response);

  if (response.status !== 200) throw new Error("Failed to get parent options");

  return response.data;
};

export const setRepairParent = async ({ repairOrderId, parentOrderId }) => {
  if (!repairOrderId || !parentOrderId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.patch(`/repair-orders/${repairOrderId}/parent`, {
    parentOrderId,
  });

  if (response.status !== 200) throw new Error("Failed to update parent");

  return response.data;
};

export const removeRepairParent = async ({ repairOrderId }) => {
  if (!repairOrderId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.patch(
    `/repair-orders/${repairOrderId}/unparent`
  );

  if (response.status !== 200) throw new Error("Failed to remove parent");

  return response.data;
};

export const getRefundableServices = async ({ repairOrderId }) => {
  if (!repairOrderId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.get(
    `/repair-orders/${repairOrderId}/refundable-services`
  );

  if (response.status !== 200)
    throw new Error("Failed to get refundable services");

  return response.data;
};

export const addRefundToRepairOrder = async ({
  repairOrderId,
  serviceRefunds,
}) => {
  if (!repairOrderId || !serviceRefunds) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.post(`/repair-orders/${repairOrderId}/refund`, {
    serviceRefunds,
  });

  if (response.status !== 200) throw new Error("Failed to add refund");

  return response.data;
};

export const createFollowUpRepairOrder = async ({
  repairOrderId,
  followUpData,
}) => {
  if (!repairOrderId || !followUpData) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.post(
    `/repair-orders/${repairOrderId}/follow-up`,
    followUpData
  );

  if (response.status !== 200) throw new Error("Failed to create follow-up");

  return response.data;
};
