import { useState } from "react";
import {
  Box,
  Popover,
  Stack,
  Button,
  ButtonGroup,
  Divider,
  TextField,
  Dialog,
  DialogTitle,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContent,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import axios from "../../../axios-config";
import {
  Calendar,
  defaultInputRanges,
  DefinedRange,
  createStaticRanges,
} from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useTranslation } from "react-i18next";
import * as rdrLocales from "react-date-range/dist/locale";

//Icons
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

const ReminderDialog = ({
  open,
  anchorEl,
  handleClose,
  repairOrderId,
  organizationCounter,
}) => {
  let dayjs = require("dayjs");
  const { t, i18n } = useTranslation();

  const [creatingLoading, setCreatingLoading] = useState(false);

  //Calendar
  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);
  const [calendarDate, setCalendarDate] = useState(dayjs().toDate());
  const calendarOpen = Boolean(calendarAnchorEl);
  const calendarId = calendarOpen ? "calendar-popover" : undefined;
  const { enqueueSnackbar } = useSnackbar();

  const handleSetReminder = async () => {
    try {
      // console.log(calendarDate, selectedTime)
      setCreatingLoading(true);
      const timeResponse = await axios.get("utils/serverTime");
      if (timeResponse.status === 200) {
        const currentTime = dayjs();
        const serverTime = dayjs(timeResponse.data);
        const diff = currentTime.diff(serverTime);
        const hour = parseInt(selectedTime.split(":")[0]);
        const targetDateTime = dayjs(calendarDate)
          .set("hour", hour)
          .set("minute", 0)
          .set("second", 0)
          .subtract(diff);

        const data = {
          repairOrderId: repairOrderId,
          organizationCounter: organizationCounter,
          atDate: targetDateTime,
        };
        axios.post("users/reminder", data);
        enqueueSnackbar(t("Reminder set successfully"), { variant: "success" });
        handleClose();
      }
      setCreatingLoading(false);
    } catch (err) {
      setCreatingLoading(false);
      console.log(err);
    }
  };

  const handleCalendarOpen = (event) => {
    setCalendarAnchorEl(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setCalendarAnchorEl(null);
  };

  const [selectedTime, setSelectedTime] = useState("09:00");

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };
  const hanldeDateSelect = (v) => {
    setCalendarDate(dayjs(v).toDate());
    handleCalendarClose();
  };
  if (!repairOrderId) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 435 } }}
      maxWidth="xs"
      onClose={handleClose}
    >
      <DialogTitle>{t("New reminder")}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} pt={2}>
          <TextField
            variant="filled"
            label={t("Date")}
            onClick={handleCalendarOpen}
            fullWidth
            value={dayjs(calendarDate).format("DD MMM YYYY")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarMonthOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowDropDownRoundedIcon />{" "}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label={t("Hour")}
            fullWidth
            select
            onChange={handleTimeChange}
            value={selectedTime}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccessAlarmOutlinedIcon />
                </InputAdornment>
              ),
            }}
          >
            {Array.from({ length: 24 }).map((_, i) => {
              const hour = i.toString().padStart(2, "0");
              return (
                <MenuItem
                  key={`hour-${hour}`}
                  value={`${hour}:00`}
                >{`${hour}:00`}</MenuItem>
              );
            })}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: "24px", pb: "24px" }}>
        <Button variant={"text"} color={"red"} onClick={handleClose}>
          {t("Cancel")}
        </Button>
        <LoadingButton
          loading={creatingLoading}
          variant={"contained"}
          onClick={handleSetReminder}
        >
          {t("Create reminder")}
        </LoadingButton>
      </DialogActions>
      <Popover
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        anchorEl={calendarAnchorEl}
        open={calendarOpen}
        id={calendarId}
        onClose={handleCalendarClose}
      >
        <Calendar
          locale={rdrLocales[i18n.language]}
          minDate={dayjs().toDate()}
          // maxDate={dayjs().endOf('month').toDate()}
          // ranges={[selectionRange]}
          // staticRanges={predefinedRanges}
          onChange={hanldeDateSelect}
          inputRanges={[
            {
              ...defaultInputRanges[0],
              label: t("Days until now"),
            },
          ]}
        />
      </Popover>
    </Dialog>
  );
};

export default ReminderDialog;
