import { useState, useEffect } from "react";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  ToggleButtonGroup,
  FormControl,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { processService } from "../../../utils/service-helpers";
import axios from "../../../axios-config";
import { useSnackbar } from "notistack";

import ColoredToggleButton from "../../../common/ColoredToggleButton";
import { getCurrencySymbol } from "../../../utils/currency";
import { LoadingButton } from "@mui/lab";
import useAddPayment from "hooks/mutations/orders/useAddPayment";
//proptypes
import PropTypes from "prop-types";

const NewPaymentTab = ({
  repairOrderId,
  services,
  repairPayments,
  handleAddServiceClose,
  onPaymentAdded,
  isHandlingRequest,
  setIsHandlingRequest,
}) => {
  const initialPaymentState = {
    amount: "",
    method: 0,
    isDeposit: false,
    paymentType: "in",
  };
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const currency = useSelector((state) => state.auth.currency);
  const [amount, setAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [isDeposit, setIsDeposit] = useState(false);
  const [paymentType, setPaymentType] = useState("in");
  const addPayment = useAddPayment();
  useEffect(() => {
    const acceptedAndRefundedServices = services.filter(
      (service) => service.isAccepted || service.refundedServiceId
    );

    const acceptedAndRefundedServicesProcessed =
      acceptedAndRefundedServices.reduce((acc, service) => {
        const processed = processService(service);
        return (acc +=
          processed.unitPrice * processed.quantity - processed.discount);
      }, 0);

    let totalPayments = 0;
    if (repairPayments) {
      totalPayments = repairPayments.reduce((acc, current) => {
        if (!current.deletedAt) {
          return acc + parseFloat(current.amount);
        }
      }, 0);
    }
    const leftToBePaid = (
      acceptedAndRefundedServicesProcessed - totalPayments
    ).toFixed(2);
    let paymentType = "in";
    if (leftToBePaid >= 0) {
      paymentType = "in";
    } else {
      paymentType = "out";
    }

    setAmount(Math.abs(leftToBePaid));
    setPaymentType(paymentType);
  }, []);

  const handlePaymentTypeChange = (event, newPaymentType) => {
    setPaymentType(newPaymentType);
  };
  const handleDepositCheck = (e) => {
    setIsDeposit(e.target.checked);
  };
  const handlePaymentChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handlePaymentAmountChange = (e) => {
    const input = e.target.value;
    // Allow only digits and up to two decimal places
    const sanitized = input.replace(/[^0-9.]/g, "");
    // Optional: Further sanitize to allow only one decimal point
    const parts = sanitized.split(".");
    if (parts.length > 2) {
      setAmount(parts[0] + "." + parts[1]);
    } else {
      setAmount(sanitized);
    }
  };

  const handleAddNewPayment = () => {
    addPayment.mutate({
      repairOrderId,
      amount,
      paymentMethod,
      isDeposit,
      paymentType,
    });
    handleAddServiceClose();
  };

  return (
    <>
      <DialogContent>
        <DialogContentText>
          {t("Add a payment received for this order")}.
        </DialogContentText>
        <Stack direction={"row"} spacing={2} pt={3}>
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={paymentType}
            onChange={handlePaymentTypeChange}
          >
            <ColoredToggleButton color="green" value={"in"}>
              Pay in
            </ColoredToggleButton>
            <ColoredToggleButton color="red" value={"out"}>
              Pay out{" "}
            </ColoredToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Stack direction={"row"} spacing={2} pt={3}>
          <FormControl fullWidth>
            {/* <InputLabel id="Amount">Amount</InputLabel> */}
            <TextField
              id="payment-amount"
              label={t("Amount")}
              variant="filled"
              value={amount}
              onChange={handlePaymentAmountChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {getCurrencySymbol(currency)}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl fullWidth variant={"filled"}>
            <InputLabel>{t("Payment method")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              label="Payment method"
              value={paymentMethod}
              onChange={handlePaymentChange}
            >
              <MenuItem value={0}>{t("Cash")}</MenuItem>
              <MenuItem value={1}>{t("Card")}</MenuItem>
              <MenuItem value={2}>{t("Transfer")}</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        {paymentType == "in" ? (
          <FormControlLabel
            control={
              <Checkbox checked={isDeposit} onChange={handleDepositCheck} />
            }
            label={t("Deposit")}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddServiceClose}>{t("Cancel")}</Button>
        <LoadingButton
          loading={isHandlingRequest}
          onClick={handleAddNewPayment}
          disabled={amount == 0}
        >
          {t("Add")}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

NewPaymentTab.propTypes = {
  repairOrderId: PropTypes.number.isRequired,
  services: PropTypes.array,
  repairPayments: PropTypes.array,
  handleAddServiceClose: PropTypes.func,
  onPaymentAdded: PropTypes.func,
  isHandlingRequest: PropTypes.bool,
  setIsHandlingRequest: PropTypes.func,
};

export default NewPaymentTab;
