import * as React from "react";
import {
  Typography,
  Button,
  IconButton,
  Tab,
  Tabs,
  InputAdornment,
  Stack,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { toggleButtonClasses } from "@mui/material/ToggleButton";

import axios from "../../../axios-config";
import { useEffect, useState, memo } from "react";
import { useSnackbar } from "notistack";

import { useSelector } from "react-redux";

//ICONS
import HandymanIcon from "@mui/icons-material/Handyman";
import MemoryIcon from "@mui/icons-material/Memory";
import PaymentsIcon from "@mui/icons-material/Payments";

import { useTranslation } from "react-i18next";
import ConsoleHelper from "../../../utils/ConsoleHelper";

import NewPaymentTab from "./NewPaymentTab";
import { getCurrencySymbol } from "../../../utils/currency";
import NewServiceTab from "./NewServiceTab";
import NewPartTab from "./NewPartTab";
import { useUserPermissions } from "contexts/UserPermissions";

const NewServiceDialog = ({
  resolutionType,
  repairOrderId,
  services,
  repairPayments,
  addServiceOpen,
  handleAddServiceClose,
  onNewServiceAdded,
  onPaymentAdded,
  tabsValue,
  setTabsValue,
}) => {
  const { t } = useTranslation();

  const userPermissions = useUserPermissions();

  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const [isHandlingRequest, setIsHandlingRequest] = useState(false);

  const tabContent = () => {
    if (userPermissions?.softLockedUser) {
      return (
        <NewPaymentTab
          repairOrderId={repairOrderId}
          services={services}
          repairPayments={repairPayments}
          handleAddServiceClose={handleAddServiceClose}
          onPaymentAdded={onPaymentAdded}
        />
      );
    } else {
      switch (tabsValue) {
        case 0:
          return (
            <NewServiceTab
              repairOrderId={repairOrderId}
              resolutionType={resolutionType}
              services={services}
              repairPayments={repairPayments}
              handleAddServiceClose={handleAddServiceClose}
              onNewServiceAdded={onNewServiceAdded}
              isHandlingRequest={isHandlingRequest}
              setIsHandlingRequest={setIsHandlingRequest}
            />
          );
        case 1:
          return (
            <NewPartTab
              repairOrderId={repairOrderId}
              handleAddServiceClose={handleAddServiceClose}
            />
          );
        case 2:
          return (
            <NewPaymentTab
              repairOrderId={repairOrderId}
              services={services}
              repairPayments={repairPayments}
              handleAddServiceClose={handleAddServiceClose}
              onPaymentAdded={onPaymentAdded}
              isHandlingRequest={isHandlingRequest}
              setIsHandlingRequest={setIsHandlingRequest}
            />
          );
      }
    }
  };

  return (
    <Dialog
      open={addServiceOpen}
      onClose={handleAddServiceClose}
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle>
        <Tabs
          value={tabsValue}
          onChange={handleTabChange}
          aria-label="disabled tabs example"
        >
          {userPermissions?.softLockedUser ? null : (
            <Tab
              label={t("Services")}
              icon={<HandymanIcon />}
              iconPosition={"start"}
            />
          )}
          {userPermissions?.softLockedUser ? null : (
            <Tab
              label={t("Parts")}
              icon={<MemoryIcon />}
              iconPosition={"start"}
            />
          )}
          <Tab
            label={t("Payments")}
            icon={<PaymentsIcon />}
            iconPosition={"start"}
          />
        </Tabs>
      </DialogTitle>
      {tabContent()}
    </Dialog>
  );
};

export default memo(NewServiceDialog);
