import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { assignUserToRepair } from "api/repair-orders";

const useAssignUserToRepair = ({ cacheKeys }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    // Accept a user object, but if none is provided, use null
    ({ repairOrderId, user }) =>
      assignUserToRepair({
        repairOrderId,
        assignedToId: user ? user.id : null,
      }),
    {
      onMutate: async ({ repairOrderId, user }) => {
        // If no cache keys, do nothing
        if (!cacheKeys || cacheKeys.length === 0) return;
        console.log("user", user);
        const assignedToId = user ? user.id : null;
        const assignedTo = user ? user : null;
        console.log("cacheKeys", cacheKeys);
        // Cancel any outgoing refetches (so they don’t overwrite our optimistic updates)
        await Promise.all(
          cacheKeys.map((cacheKey) =>
            queryClient.cancelQueries({ queryKey: cacheKey })
          )
        );

        // Snapshot the previous data for *each* cacheKey
        const previousData = {};
        for (const key of cacheKeys) {
          previousData[key] = queryClient.getQueryData(key);
        }

        // If there’s no data in at least one of them, just store that as null
        // (but don’t return, because we might still be able to update the others)
        // Now update each of them optimistically
        cacheKeys.forEach((key) => {
          queryClient.setQueryData(key, (oldData) => {
            console.log("oldData", oldData);
            if (!oldData) return oldData;

            // ---- SINGLE ITEM CASE (["repairOrder", id]) ----
            if (!Array.isArray(oldData)) {
              // oldData is the single repairOrder object
              // Return an updated version
              return {
                ...oldData,
                updatedAt: dayjs().toISOString(),
                // Example: “assigning a user” or some data
                assignedToId,
                assignedTo,
                // or if you want to set more data:
                // e.g. "customer" properties, "services", etc
                // services: oldData.services.map( service => ... )
              };
            }

            // ---- ARRAY CASE (["repairOrders", filters]) ----
            // oldData is an array of orders
            return oldData.map((order) => {
              if (order.id === repairOrderId) {
                console.log("order", order);

                return {
                  ...order,
                  updatedAt: dayjs().toISOString(),
                  assignedToId,
                  assignedTo,
                };
              }
              return order;
            });
          });
        });

        // Return context for possible rollback
        return { previousData };
      },
      onError: (error, variables, context) => {
        enqueueSnackbar("Failed to update repair", { variant: "error" });

        // If we had previous data, rollback for each cache key
        if (context?.previousData) {
          for (const [key, oldData] of Object.entries(context.previousData)) {
            queryClient.setQueryData(key, oldData);
          }
        }

        console.error(
          error?.response?.data?.message || "Error updating repair"
        );
      },
      onSuccess: (data, variables) => {
        enqueueSnackbar("Repair updated successfully", { variant: "success" });
      },
      onSettled: (data, error, variables) => {
        // Whichever keys you want to refetch. Typically:
        if (!cacheKeys || cacheKeys.length === 0) return;
        cacheKeys.forEach((key) => {
          queryClient.invalidateQueries({ queryKey: key });
        });
      },
    }
  );
};

export default useAssignUserToRepair;
