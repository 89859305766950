import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Popover,
  InputAdornment,
  Divider,
  Box,
  Grid,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Calendar } from "react-date-range";
import { NavigateNext } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import axios from "axios-config";
import StyledToggleButtonGroup from "components/StyledToggleButtonGroup/StyledToggleButtonGroup";
import ImageUploadButton from "common/ImageUploadButton";

import { formatCurrency } from "utils/currency"; // your existing currency util
import { getCurrencySymbol } from "utils/currency"; // optional if needed

/**
 * A single row in the quote creator table.
 * Renders service information and calculates discount/tax totals based on the selected settings.
 */
const QuoteItemRow = ({
  service,
  taxIncluded,
  taxPercentage,
  onToggleAccept,
}) => {
  // Calculate discount, taking into account whether tax is included
  let discount = service.discount ? parseFloat(service.discount) : 0;
  if (taxIncluded) {
    discount = discount / (1 + taxPercentage / 100);
  }

  // Calculate unitPrice, adjusting if tax is included
  let unitPrice = service.unitPrice ? parseFloat(service.unitPrice) : 0;
  if (taxIncluded) {
    unitPrice = unitPrice / (1 + taxPercentage / 100);
  }

  const quantity = service.quantity || 1;
  const subTotal = unitPrice * quantity - discount;
  const taxAmount = (subTotal * taxPercentage) / 100;
  const total = subTotal + taxAmount;

  // Convert discount to display a negative if it’s truly discount.
  if (discount > 0) discount = -discount;

  return (
    <TableRow hover key={service.id}>
      <TableCell>
        <Checkbox
          checked={!!service.isAccepted}
          onChange={() => onToggleAccept(service)}
        />
      </TableCell>
      <TableCell>{service.name}</TableCell>
      <TableCell>{unitPrice.toFixed(2)}</TableCell>
      <TableCell>x{quantity}</TableCell>
      <TableCell>{discount.toFixed(2)}</TableCell>
      <TableCell>{taxAmount.toFixed(2)}</TableCell>
      <TableCell>{total.toFixed(2)}</TableCell>
    </TableRow>
  );
};

QuoteItemRow.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    discount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    unitPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    quantity: PropTypes.number,
    isAccepted: PropTypes.bool,
  }).isRequired,
  taxIncluded: PropTypes.bool.isRequired,
  taxPercentage: PropTypes.number.isRequired,
  onToggleAccept: PropTypes.func.isRequired,
};

/**
 * Main Quote Creator Dialog component.
 * Gathers all information (billing, logo, services, etc.) to create a new quote.
 */
const QuoteCreatorDialog = ({ open, onClose, services = [], customer }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // Redux state
  const currency = useSelector((state) => state.auth.currency);

  // Basic form states
  const [refId, setRefId] = useState("");
  const [billingFrom, setBillingFrom] = useState("");
  const [billingTo, setBillingTo] = useState("");
  const [note, setNote] = useState("");
  const [terms, setTerms] = useState("");

  // Date & Calendar states
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Services & Totals
  const [filteredServices, setFilteredServices] = useState([]);
  const [taxIncluded, setTaxIncluded] = useState(true);
  const [taxPercentage, setTaxPercentage] = useState(21);
  const [invoiceGrandTotal, setInvoiceGrandTotal] = useState(0);
  const [invoiceTotalTax, setInvoiceTotalTax] = useState(0);

  // Logo image states
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  // Validation & submission
  const [isFormValid, setIsFormValid] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  /**
   * Handle popover for date selection
   */
  const handleCalendarOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setCalendarOpen(true);
  };

  const handleCalendarClose = () => {
    setAnchorEl(null);
    setCalendarOpen(false);
  };

  const handleCalendarSelect = (date) => {
    setCalendarDate(date);
    setCalendarOpen(false);
  };

  /**
   * When dialog opens or services change, set up our local state
   */
  useEffect(() => {
    setFilteredServices(services);
    if (customer?.name) {
      setBillingTo(customer.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, open]);

  /**
   * Recalculate totals whenever services/tax settings change
   */
  useEffect(() => {
    if (!filteredServices || !filteredServices.length) {
      setInvoiceGrandTotal(0);
      setInvoiceTotalTax(0);
      return;
    }

    let totalTaxAcc = 0.0;
    const newGrandTotal = filteredServices.reduce((acc, service) => {
      if (!service.isAccepted) return acc;

      let discount = parseFloat(service.discount) || 0;
      let unitPrice = parseFloat(service.unitPrice) || 0;
      const qty = service.quantity || 1;

      if (taxIncluded) {
        discount /= 1 + taxPercentage / 100;
        unitPrice /= 1 + taxPercentage / 100;
      }

      const subTotal = unitPrice * qty - discount;
      const taxAmount = (subTotal * taxPercentage) / 100;
      totalTaxAcc += taxAmount;

      return acc + subTotal + taxAmount;
    }, 0);

    setInvoiceGrandTotal(newGrandTotal);
    setInvoiceTotalTax(totalTaxAcc);
  }, [filteredServices, taxIncluded, taxPercentage]);

  /**
   * Validate form whenever relevant fields change
   */
  useEffect(() => {
    if (!refId || !billingFrom || !billingTo) {
      setIsFormValid(false);
      return;
    }

    // Check if refId is all digits
    const refIdRegEx = /^[0-9]+$/;
    if (!refId.match(refIdRegEx)) {
      setIsFormValid(false);
      return;
    }

    // At least one service must be accepted
    const atLeastOneAccepted = filteredServices.some((s) => s.isAccepted);
    setIsFormValid(atLeastOneAccepted);
  }, [refId, billingFrom, billingTo, filteredServices]);

  /**
   * Toggle acceptance of a service item
   */
  const handleServiceToggle = (service) => {
    setFilteredServices((prev) =>
      prev.map((item) =>
        item.id === service.id
          ? { ...item, isAccepted: !item.isAccepted }
          : item
      )
    );
  };

  /**
   * Handle image selection/removal
   */
  const handleImageSelect = (image) => {
    setSelectedImageURL(URL.createObjectURL(image));
    setSelectedImageFile(image);
  };

  const handleImageRemove = () => {
    setSelectedImageURL(null);
    setSelectedImageFile(null);
  };

  /**
   * Main quote generation (submitting form data to the server)
   */
  const handleGenerateQuote = async () => {
    try {
      setIsCreating(true);

      const acceptedServices = filteredServices.filter((s) => s.isAccepted);
      const formData = new FormData();

      formData.append("refId", refId);
      formData.append("billingFrom", billingFrom);
      formData.append("billingTo", billingTo);
      formData.append("taxIncluded", taxIncluded);
      formData.append("taxAmount", taxPercentage);
      formData.append("currency", currency);
      formData.append("date", calendarDate.toISOString());
      formData.append("quoteItems", JSON.stringify(acceptedServices));
      formData.append("invoiceGranTotal", invoiceGrandTotal);
      formData.append("invoiceTotalTax", invoiceTotalTax);
      formData.append("customerId", customer.id);
      formData.append("logo", selectedImageFile);
      formData.append("note", note);
      formData.append("terms", terms);

      const response = await axios.post("/quotes", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200 || response.status === 201) {
        enqueueSnackbar(t("Quote created successfully"), {
          variant: "success",
        });
        onClose();
      }
    } catch (err) {
      enqueueSnackbar(
        err?.response?.data?.message || t("Something went wrong!"),
        { variant: "error" }
      );
    } finally {
      setIsCreating(false);
    }
  };

  // Safety check if customer object is missing
  if (!customer) {
    console.error("Customer is required for QuoteCreatorDialog");
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle variant="h4">{t("Quote Creator")}</DialogTitle>

      <DialogContent>
        {/** Top row: Logo and Reference + Date fields */}
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "25px 0",
          }}
        >
          {/* Logo upload */}
          <Stack direction="row" alignItems="center" spacing={2}>
            <ImageUploadButton
              onImageSelected={handleImageSelect}
              imageUrl={selectedImageURL}
              onImageRemoved={handleImageRemove}
              imageStyle={{
                maxWidth: "190px",
                maxHeight: "80px",
                width: "auto",
                height: "auto",
                border: "0px solid #e0e0e0",
              }}
              noImageStyle={{
                width: "190px",
                height: "80px",
                border: "1px solid #e0e0e0",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              noImageText={t("Logo")}
            />
          </Stack>

          {/* Reference Number and Date */}
          <Stack spacing={2} alignItems="flex-end">
            <Stack direction="row" spacing={2}>
              <Typography variant="h5">{t("Quote")}</Typography>
              <TextField
                variant="outlined"
                size="small"
                value={refId}
                onChange={(e) => setRefId(e.target.value)}
                sx={{
                  backgroundColor: theme.palette.lightBlue?.main || "#f0f6fa",
                  width: "150px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">#</InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Popover
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                anchorEl={anchorEl}
                open={calendarOpen}
                onClose={handleCalendarClose}
              >
                <Calendar date={calendarDate} onChange={handleCalendarSelect} />
              </Popover>
              <Stack
                direction="row"
                px="5px"
                className="editableRow clickable"
                onClick={handleCalendarOpen}
              >
                <Typography>
                  {t("Date")}: {dayjs(calendarDate).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Divider />

        {/** Billing info */}
        <Stack
          direction="row"
          spacing={5}
          padding="30px 0"
          divider={
            <Divider
              sx={{ borderColor: "#8B98AB" }}
              orientation="vertical"
              flexItem
            />
          }
        >
          {/* Quote from */}
          <Stack flex={1}>
            <Typography gutterBottom variant="h5">
              {t("Quote from")}:
            </Typography>
            <TextField
              multiline
              maxRows={10}
              minRows={5}
              variant="filled"
              value={billingFrom}
              onChange={(e) => setBillingFrom(e.target.value)}
              hiddenLabel
              fullWidth
            />
          </Stack>

          {/* Quote to */}
          <Stack flex={1}>
            <Typography gutterBottom variant="h5">
              {t("Quote to")}:
            </Typography>
            <TextField
              multiline
              maxRows={10}
              minRows={5}
              variant="filled"
              value={billingTo}
              onChange={(e) => setBillingTo(e.target.value)}
              hiddenLabel
              fullWidth
            />
          </Stack>
        </Stack>

        {/** Services Table */}
        {filteredServices && filteredServices.length > 0 ? (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>{t("Item")}</TableCell>
                  <TableCell>{t("Unit Price")}</TableCell>
                  <TableCell>{t("Quantity")}</TableCell>
                  <TableCell>{t("Discount")}</TableCell>
                  <TableCell>{t("Tax")}</TableCell>
                  <TableCell>{t("Total")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredServices.map((service) => (
                  <QuoteItemRow
                    key={service.id}
                    service={service}
                    taxIncluded={taxIncluded}
                    taxPercentage={taxPercentage}
                    onToggleAccept={handleServiceToggle}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" color="textSecondary">
            {t("No services available")}
          </Typography>
        )}

        {/** Totals and Note / Terms */}
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          padding="30px 0 0"
        >
          <Grid item xs={12} md={4}>
            <TextField
              multiline
              minRows={2}
              placeholder={t("Note")}
              variant="outlined"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              sx={{
                backgroundColor: theme.palette.lightBlue?.main || "#f0f6fa",
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography>{t("Base")}:</Typography>
                <Typography>
                  {formatCurrency(
                    invoiceGrandTotal - invoiceTotalTax,
                    currency
                  )}
                </Typography>
              </Stack>

              {/* Tax toggle: included or excluded */}
              <Stack alignItems="center">
                <Box width="350px" display="flex" justifyContent="center">
                  <StyledToggleButtonGroup
                    size="small"
                    value={taxIncluded}
                    exclusive
                    onChange={() => setTaxIncluded((prev) => !prev)}
                    aria-label="tax setting"
                    sx={{ backgroundColor: "#F1F9FD" }}
                  >
                    <ToggleButton value={true}>
                      {t("Tax Included")}
                    </ToggleButton>
                    <ToggleButton value={false}>
                      {t("Tax Excluded")}
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                </Box>
              </Stack>

              {/* Tax percentage */}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>{t("Tax")}:</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Box width="80px">
                    <TextField
                      size="small"
                      value={taxPercentage}
                      onChange={(e) =>
                        setTaxPercentage(Number(e.target.value) || 0)
                      }
                    />
                  </Box>
                  <Typography>%</Typography>
                </Stack>
              </Stack>

              <Divider />

              {/* Grand total */}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                pt={1}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("Total tax included")}:
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {formatCurrency(invoiceGrandTotal, currency)}
                </Typography>
              </Stack>
            </Stack>
          </Grid>

          {/* Terms */}
          <Grid item xs={12}>
            <Box pt={2}>
              <TextField
                multiline
                fullWidth
                value={terms}
                onChange={(e) => setTerms(e.target.value)}
                variant="outlined"
                placeholder={`${t("Add terms or note")}...`}
                InputProps={{
                  style: { fontSize: "0.95rem" },
                }}
                sx={{
                  backgroundColor: theme.palette.lightBlue?.main || "#f0f6fa",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="inherit">
          {t("Cancel")}
        </Button>
        <LoadingButton
          loading={isCreating}
          disabled={!isFormValid}
          onClick={handleGenerateQuote}
          loadingPosition="end"
          variant="contained"
          endIcon={<NavigateNext />}
        >
          {t("Create")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

QuoteCreatorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  customer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
  }).isRequired,
};

export default React.memo(QuoteCreatorDialog);
