// useUpdateOrderStatusMutation.js
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { addPartToRepairOrder } from "api/repair-orders";

const useAddPart = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(addPartToRepairOrder, {
    onMutate: async ({
      repairOrderId,
      productInventoryId,
      warehouseId,
      name,
    }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: ["repairOrder", repairOrderId],
      });

      // Snapshot the previous data
      const previousOrder = queryClient.getQueryData([
        "repairOrder",
        repairOrderId,
      ]);

      if (!previousOrder) {
        console.error("No previous order found");
        console.log("repairOrderId", repairOrderId);
        return;
      }

      const newPart = {
        id: `temp-${Date.now()}`,
        createdAt: dayjs().toISOString(),
        productInventoryId,
        repairOrderId,
        name,
        quantity: 1,
      };

      //   Optimistically update the repair order with the new comment
      queryClient.setQueryData(["repairOrder", repairOrderId], (old) => ({
        ...old,
        updatedAt: dayjs().toISOString(),
        repairOrderParts: [...(old.repairOrderParts || []), newPart],
      }));

      // Return context with the previous order for rollback
      return { previousOrder };
    },
    onError: (error, variables, context) => {
      if (context?.previousOrder) {
        queryClient.setQueryData(
          ["repairOrder", variables.repairOrderId],
          context.previousOrder
        );
      }
      console.log(error.response);
      console.error(error?.response?.data?.message || "Error adding part");
      enqueueSnackbar("Failed to add part", { variant: "error" });
    },
    onSuccess: (data, variables) => {
      enqueueSnackbar("Part added successfully", { variant: "success" });
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(["repairOrder", variables.repairOrderId]);
    },
  });
};

export default useAddPart;
