// // Allow only digits and optionally a single decimal point
// export const onlyDigitsAndDecimal = (value) => {
//   return value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
// };

// // Allow only positive numbers (integers and floats)
// export const onlyPositive = (value) => {
//   const sanitized = onlyDigitsAndDecimal(value);
//   return sanitized.startsWith("-") ? sanitized.slice(1) : sanitized;
// };

// // Allow only integers
// export const onlyInteger = (value) => {
//   return value.replace(/[^0-9]/g, "");
// };

// // Allow only floats
// export const onlyFloat = (value) => {
//   return onlyDigitsAndDecimal(value);
// };

// // Allow only positive integers
// export const onlyPositiveInteger = (value) => {
//   const sanitized = onlyInteger(value);
//   return sanitized.startsWith("-") ? sanitized.slice(1) : sanitized;
// };

// // Allow only negative numbers (integers and floats)
// export const onlyNegative = (value) => {
//   const sanitized = onlyDigitsAndDecimal(value);
//   return sanitized.startsWith("-") ? sanitized : `-${sanitized}`;
// };

// // Allow only numbers greater than a specified value
// export const greaterThan = (value, min) => {
//   const sanitized = onlyDigitsAndDecimal(value);
//   return parseFloat(sanitized) > min ? sanitized : min.toString();
// };

// // Allow only numbers less than a specified value
// export const lessThan = (value, max) => {
//   const sanitized = onlyDigitsAndDecimal(value);
//   return parseFloat(sanitized) < max ? sanitized : max.toString();
// };

// // Allow only numbers within a specified range
// export const withinRange = (value, min, max) => {
//   const sanitized = onlyDigitsAndDecimal(value);
//   const num = parseFloat(sanitized);
//   if (num < min) return min.toString();
//   if (num > max) return max.toString();
//   return sanitized;
// };

const sanitizations = {
  // Allow only digits and optionally a single decimal point
  onlyDigitsAndDecimal: (value) => {
    return value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
  },

  // Allow only positive numbers (integers and floats)
  onlyPositive: (value) => {
    const sanitized = sanitizations.onlyDigitsAndDecimal(value);
    return sanitized.startsWith("-") ? sanitized.slice(1) : sanitized;
  },

  // Allow only integers
  onlyInteger: (value) => {
    return value.replace(/[^0-9]/g, "");
  },

  // Allow only floats
  onlyFloat: (value) => {
    return sanitizations.onlyDigitsAndDecimal(value);
  },

  // Allow only positive integers
  onlyPositiveInteger: (value) => {
    const sanitized = sanitizations.onlyInteger(value);
    return sanitized.startsWith("-") ? sanitized.slice(1) : sanitized;
  },

  // Allow only negative numbers (integers and floats)
  onlyNegative: (value) => {
    const sanitized = sanitizations.onlyDigitsAndDecimal(value);
    return sanitized.startsWith("-") ? sanitized : `-${sanitized}`;
  },

  // Allow only numbers greater than a specified value
  greaterThan: (value, min) => {
    const sanitized = sanitizations.onlyDigitsAndDecimal(value);
    return parseFloat(sanitized) > min ? sanitized : min.toString();
  },

  // Allow only numbers less than a specified value
  lessThan: (value, max) => {
    const sanitized = sanitizations.onlyDigitsAndDecimal(value);
    return parseFloat(sanitized) < max ? sanitized : max.toString();
  },

  // Allow only numbers within a specified range
  withinRange: (value, min, max) => {
    const sanitized = sanitizations.onlyDigitsAndDecimal(value);
    const num = parseFloat(sanitized);
    if (num < min) return min.toString();
    if (num > max) return max.toString();
    return sanitized;
  },
};

export default sanitizations;
