import { useState, useEffect } from "react";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  ToggleButtonGroup,
  FormControl,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  ToggleButton,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { processService } from "../../../utils/service-helpers";
import axios from "../../../axios-config";
import { useSnackbar } from "notistack";

import { getCurrencySymbol } from "../../../utils/currency";
import ProductSearch from "containers/Inventory/Transfer/ProductSearch";
import { LoadingButton } from "@mui/lab";
import { fetchDefaultWarehouse } from "api/inventory";
import { useQuery } from "react-query";
import useAddPart from "hooks/mutations/orders/useAddPart";

const NewPartTab = ({ repairOrderId, handleAddServiceClose }) => {
  const initialServiceState = {
    name: "",
    quantity: 1,
    unitPrice: "",
    discount: "",
  };

  const addPart = useAddPart();
  const initialPartState = { name: "", quantity: 1, unitPrice: "" };
  const [part, setPart] = useState({ ...initialPartState });
  const [warehouses, setWarehouses] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleNewPartState = (prop) => (event) => {
    setPart((prevState) => {
      return { ...prevState, [prop]: event.target.value };
    });
  };

  const handleAddProduct = async (product) => {
    // Attach to repair, send to backend that, decrease from inventory
    if (product.quantityInStock <= 0) {
      // TODO: show a dialog if they want to add to stock before proceeding
      enqueueSnackbar("There is not enough stock.", { variant: "error" });
      return;
    }
    addPart.mutate({
      repairOrderId: repairOrderId,
      productInventoryId: product.id,
      warehouseId: selectedWarehouse.id,
      //   for optimistic update
      name: product.name,
    });
    handleAddServiceClose();
  };

  const { data, error, isLoading, isFetching } = useQuery(
    "defaultWarehouse",
    fetchDefaultWarehouse,
    {
      onSuccess: (data) => {
        setWarehouses([data]);
        setSelectedWarehouse(data);
      },
      onError: (error) => {
        console.error(error);
      },
      //   staleTime: 1000 * 60,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (data) {
      setWarehouses([data]);
      setSelectedWarehouse(data);
    }
  }, []);

  if (isLoading || !data) {
    return (
      <>
        <DialogContent>
          <DialogContentText>
            {t(
              "Parts used on this order, will be calculated as an expense on charts"
            )}
            .
          </DialogContentText>
          <Stack spacing={2}>
            <Stack spacing={2}>
              <Skeleton variant="rectangular" height={55} />
              <Skeleton variant="rectangular" height={55} />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton disabled>{t("Add")}</LoadingButton>
        </DialogActions>
      </>
    );
  }

  return (
    <>
      <DialogContent>
        <DialogContentText>
          {t(
            "Parts used on this order, will be calculated as an expense on charts"
          )}
          .
        </DialogContentText>
        <Stack spacing={2}>
          <FormControl variant="filled">
            <InputLabel id="demo-simple-select-label" variant="filled">
              {t("Warehouse")}
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedWarehouse ? selectedWarehouse.name : ""}
              label={t("Warehouse")}
              variant="filled"
              // onChange={handleChange}
            >
              <MenuItem value={selectedWarehouse?.name}>
                {selectedWarehouse?.name}
              </MenuItem>
            </Select>
          </FormControl>

          <ProductSearch
            onOptionSelect={handleAddProduct}
            warehouse={selectedWarehouse}
            newProductInitialQuantity={1}
          />
        </Stack>
        {/* <Stack direction={'row'} spacing={2} pt={3}>
                <TextField
                    variant="filled"
                    error={formErrors.name != ''}
                    id="name"
                    label={t("Part name")}
                    type='text'
                    fullWidth
                    value={part.name}
                    onChange={handleNewPartState('name')}
                />
            </Stack>
            <Stack direction={'row'} spacing={2} pt={3}>
                <TextField
                    variant="filled"
                    id="unit-price"
                    label={t("Unit price")}
                    type='number'
                    value={part.unitPrice}
                    onChange={handleNewPartState('unitPrice')}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                    }}
                />
                <TextField
                    variant="filled"
                    id="quantity"
                    label={t("Quantity")}
                    type='number'
                    value={part.quantity}
                    onChange={handleNewPartState('quantity')}
                    sx={{ width: '150px' }}
                />
            </Stack> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddServiceClose}>{t("Cancel")}</Button>
        {/* <LoadingButton onClick={handleNewPart} loading={isHandlingRequest}>{t('Add')}</LoadingButton> */}
      </DialogActions>
    </>
  );
};
export default NewPartTab;
