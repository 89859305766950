import React, { useState, useEffect, useRef, useCallback } from "react";
import { Popover } from "@mui/material";
import AnimatedPatternLock from "components/Password/AnimatedPatternLock";

const PatternPopover = ({ value, anchorEl }) => {
  const popoverOpen = Boolean(anchorEl);

  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        // onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <AnimatedPatternLock value={value} shouldAnimate={popoverOpen} />
        {/* <div style={{ padding: 10 }}>{renderPath()}</div> */}
      </Popover>
    </>
  );
};

export default PatternPopover;
