import React from "react";
import {
  Box,
  Stack,
  Typography,
  Tooltip,
  Button,
  useTheme,
  IconButton,
} from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { grey } from "@mui/material/colors";
import { formatCurrency } from "utils/currency";
import BaseItemList from "components/ItemList/BaseItemList";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  CheckCircleOutline,
  HighlightOff,
  SettingsBackupRestore,
} from "@mui/icons-material";
import dayjs from "dayjs";

const ServiceAccepted = ({ service, onDelete, onClick, onRevert }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const currency = useSelector((state) => state.auth.currency);

  const onDeleteButtonClick = (e) => {
    e.stopPropagation();
    if (onDelete !== undefined) onDelete(service);
  };

  const InStock = () => {
    if (service.stock === true) return <Box>👍 IN STOCK</Box>;
    if (service.stock === false) return <Box>❌ OUT OF STOCK</Box>;
    return null;
  };

  const handleRevertClick = (e) => {
    e.stopPropagation();
    onRevert(service);
  };

  const handleItemClick = () => {
    onClick(service);
  };

  return (
    <>
      <BaseItemList
        sx={{
          //   border: "solid 1px",
          //   borderColor: theme.palette.red.main,
          backgroundColor: "lightGreen.main",
        }}
        onClick={
          onClick !== undefined && !service.isCreating ? handleItemClick : null
        }
        itemIcon={<CheckCircleOutline sx={{ color: "#00a63c" }} />}
        mainStack={
          <>
            <Stack flexGrow={1} overflow={"auto"}>
              {/* <Tooltip enterDelay={20} title={service.name}> */}
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  //   fontSize: "0.75rem",
                  fontWeight: 500,
                }}
              >
                {service.name}
              </Typography>
              {/* </Tooltip> */}
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography variant="caption" color={grey[600]} noWrap>
                ({formatCurrency(service.unitPrice, currency)} x
                {service.quantity})
              </Typography>
              <Typography variant="caption" color={grey[600]} noWrap>
                {service.discount <= 0 ? null : <>-</>}
              </Typography>
              <Typography variant="caption" color={grey[600]} noWrap>
                {service.discount <= 0 ? null : (
                  <>
                    {formatCurrency(service.discount, currency)} {t("Discount")}
                  </>
                )}
              </Typography>
              <Typography variant="body" fontWeight={500} noWrap>
                {formatCurrency(
                  service.unitPrice * service.quantity - service.discount,
                  currency
                )}
              </Typography>
            </Stack>
          </>
        }
        footerStack={
          <>
            <Stack direction={"row"} alignItems={"center"} spacing={"5px"}>
              <Typography variant="caption" py={"4px"}>
                {t("services.acceptedOn")}{" "}
                {dayjs(service.decisionAt).format("DD.MMM.YYYY HH:mm")}
              </Typography>
              <InStock />
              <Button
                onClick={handleRevertClick}
                size="small"
                color="black"
                aria-label="increase quanitity"
                component="span"
                sx={{ fontSize: "12px", m: 0 }}
                startIcon={
                  <SettingsBackupRestore
                    sx={{ height: "15px", width: "15px", fontSize: "10px" }}
                  />
                }
                // variant="outlined"
              >
                {t("services.undo")}
              </Button>
              {/* <Button size="small" color="green" variant="outlined">
                {t("Accept")}
              </Button> */}
            </Stack>
          </>
        }
        onDeleteButtonClick={
          onDelete !== undefined ? onDeleteButtonClick : null
        }
      />
    </>
  );
};

export default ServiceAccepted;
