import * as React from "react";
import { Typography, Box, Divider, Stack } from "@mui/material";

import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import { WarningAmberRounded } from "@mui/icons-material";

const MalfunctionCell = ({ rowData }) => {
  return (
    <Box className="" width={"100%"} height={"100%"}>
      <Box justifyContent={"center"} height={"100%"} sx={{ overflow: "auto" }}>
        {rowData.malfunction ? (
          <Stack direction={"row"}>
            <WarningAmberRounded
              color="red"
              sx={{ fontSize: "1.2rem", mr: "5px", mt: "3px" }}
            />
            <Typography variant="body2"> {rowData.malfunction}</Typography>
          </Stack>
        ) : (
          ""
        )}
        {rowData.malfunction ? <Divider sx={{ my: "5px" }} /> : ""}
        {rowData.note ? (
          <Stack direction={"row"}>
            <StickyNote2OutlinedIcon
              color="darkGrey"
              sx={{ fontSize: "1.2rem", mr: "5px", mt: "2px" }}
            />
            <Typography variant="body2" whiteSpace={"pre-line"}>
              {" "}
              {rowData.note}
            </Typography>
          </Stack>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default MalfunctionCell;
