import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowLeftRoundedIcon from "@mui/icons-material/ArrowLeftRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import WhatshotRounded from "@mui/icons-material/WhatshotRounded";
import EmergencyIconFilled from "components/Icons/EmergencyIconFilled";
import useLevelOfAttention from "hooks/mutations/orders/useLevelOfAttention";
import useStatusUpdateLogic from "hooks/useStatusUpdateLogic";
import StatusChip from "components/StatusChip/StatusChip";
import OrderParentingDialog from "../Dialogs/OrderParentingDialog";
import { blueGrey } from "@mui/material/colors";

const PanelHeader = React.memo(
  ({
    repairOrderId,
    parent,
    children,
    outsourcedFrom,
    outsourcedTo,
    deliveredAt,
    organizationId,
    organizationCounter,
    levelOfAttention,
    repairOrderStatus,
    resolutionType,
    services,
  }) => {
    const navigate = useNavigate();
    const [parentDialogOpen, setParentDialogOpen] = React.useState(false);
    const updateLevelOfAttention = useLevelOfAttention();
    const handleOrderIdClick = () => {
      if (deliveredAt) {
        return;
      }
      setParentDialogOpen(true);
    };

    const { onStatusSelect, ResolutionDialogs } = useStatusUpdateLogic(
      repairOrderId,
      resolutionType,
      ["repairOrder", repairOrderId]
    );

    const handleUrgencyCheck = async (event) => {
      updateLevelOfAttention.mutate({
        repairOrderId: repairOrderId,
        levelOfAttention: event.target.checked ? 1 : 0,
      });
    };

    const handleParentDialogClose = () => {
      setParentDialogOpen(false);
    };

    return (
      <>
        <Box height={"75px"} display={"flex"} alignItems={"center"}>
          <Stack
            flex={1}
            direction={"row"}
            spacing={2}
            sx={{ px: "25px", mb: "2px" }}
            alignItems={"center"}
          >
            <Box flexGrow={1}>
              <ButtonGroup
                variant="outlined"
                color="darkGrey"
                aria-label="outlined button group"
                sx={{ borderRadius: 1 }}
              >
                {parent?.id || children?.id ? (
                  <Button
                    sx={{ borderRadius: 1, p: "0px" }}
                    disabled={!parent?.id}
                    onClick={() => navigate(`/editEntry/${parent?.id}`)}
                  >
                    <ArrowLeftRoundedIcon />
                  </Button>
                ) : null}
                <Button sx={{ borderRadius: 1 }} onClick={handleOrderIdClick}>
                  {outsourcedFrom
                    ? outsourcedFrom.organization.id +
                      "#" +
                      outsourcedFrom.organizationCounter
                    : organizationId + "#" + organizationCounter}
                </Button>
                {parent?.id || children?.id ? (
                  <Button
                    sx={{ borderRadius: 1, p: "0px" }}
                    disabled={!children?.id}
                    onClick={() => navigate(`/editEntry/${children?.id}`)}
                  >
                    <ArrowRightRoundedIcon />
                  </Button>
                ) : null}
              </ButtonGroup>
            </Box>
            <Checkbox
              onChange={handleUrgencyCheck}
              checked={levelOfAttention == 1 ? true : false}
              icon={
                <WhatshotRounded
                  sx={{
                    color: blueGrey[800],
                  }}
                />
              }
              checkedIcon={<EmergencyIconFilled />}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Box>
              <StatusChip
                outsourcedTo={outsourcedTo}
                outsourcedFrom={outsourcedFrom}
                organizationId={organizationId}
                deliveredAt={deliveredAt}
                isChangingStatus={false}
                repairOrderStatus={repairOrderStatus}
                onStatusSelect={onStatusSelect}
              />
            </Box>
          </Stack>
        </Box>
        <Divider sx={{ mb: "15px" }} />
        {ResolutionDialogs}
        <OrderParentingDialog
          repairOrderId={repairOrderId}
          open={parentDialogOpen}
          parentOrderId={parent?.id}
          onClose={handleParentDialogClose}
          outsourcedFrom={outsourcedFrom}
          services={services}
          // repairOrder={repairOrder}
          // handleParentChange={handleParentChange}
          // handleParentDialogClose={handleParentDialogClose}
          // handleParentDialogSave={handleParentDialogSave}
        />
      </>
    );
  }
);

PanelHeader.propTypes = {
  repairOrderId: PropTypes.number.isRequired,
  parent: PropTypes.object,
  children: PropTypes.object,
  outsourcedFrom: PropTypes.object,
  outsourcedTo: PropTypes.object,
  deliveredAt: PropTypes.string,
  organizationId: PropTypes.number.isRequired,
  organizationCounter: PropTypes.number.isRequired,
  levelOfAttention: PropTypes.number,
  repairOrderStatus: PropTypes.object.isRequired,
  resolutionType: PropTypes.object,
  services: PropTypes.array,
};

export default React.memo(PanelHeader);
