import { formatCurrency } from "utils/currency";
import { processService } from "utils/service-helpers";

const { Stack, Typography, Divider } = require("@mui/material");

const { useTranslation } = require("react-i18next");
const { useSelector } = require("react-redux");

const Totals = ({ services, repairOrderParts, repairPayments }) => {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.auth.currency);

  const acceptedAndRefundedServices = services.filter(
    (service) => service.isAccepted || service.refundedServiceId
  );

  const acceptedAndRefundedServicesProcessed =
    acceptedAndRefundedServices.reduce((acc, service) => {
      const processed = processService(service);
      return (acc +=
        processed.unitPrice * processed.quantity - processed.discount);
    }, 0);

  const totalParts =
    0 ||
    repairOrderParts?.reduce((acc, part) => {
      return acc + parseFloat(part.unitPrice) * parseInt(part.quantity);
    }, 0);
  let expense = 0;
  expense += totalParts;

  // if (processedPartnerServices) {
  //     expense += processedPartnerServices.totalAccepted;
  // }
  const totalPayments =
    0 ||
    repairPayments?.reduce(
      (acc, payment) => acc + parseFloat(payment.amount),
      0
    );
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      px={{ xs: "10px", sm: "25px" }}
    >
      <Stack direction={"row"} gap={3} pt={"4px"}>
        <Stack>
          <Typography
            color={"darkGrey.main"}
            textTransform={"uppercase"}
            variant={"overline"}
          >
            {t("Payments")}
          </Typography>
          <Typography fontWeight={500} variant="body1">
            {formatCurrency(totalPayments, currency)} /{" "}
            {formatCurrency(acceptedAndRefundedServicesProcessed, currency)}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction={"row"}
        gap={1}
        divider={<Divider orientation="vertical" flexItem />}
        pt={"4px"}
      >
        <Stack alignItems={"flex-end"}>
          <Typography
            color={"darkGrey.main"}
            textTransform={"uppercase"}
            variant={"overline"}
          >
            {t("Expenses")}
          </Typography>
          <Typography color={"red.main"} noWrap fontWeight={500}>
            -{formatCurrency(expense, currency)}
          </Typography>
        </Stack>
        <Stack alignItems={"flex-end"}>
          <Typography
            color={"darkGrey.main"}
            textTransform={"uppercase"}
            variant={"overline"}
            noWrap
          >
            {t("Services")}
          </Typography>
          <Typography fontWeight={500}>
            {formatCurrency(acceptedAndRefundedServicesProcessed, currency)}
          </Typography>
        </Stack>
        <Stack alignItems={"flex-end"}>
          <Typography
            color={"darkGrey.main"}
            textTransform={"uppercase"}
            variant={"overline"}
          >
            {t("Profit")}
          </Typography>
          <Typography
            color={
              acceptedAndRefundedServicesProcessed - expense > 0
                ? "accepted.main"
                : "red.main"
            }
            fontWeight={500}
          >
            {formatCurrency(
              acceptedAndRefundedServicesProcessed - expense,
              currency
            )}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Totals;
