import { useState, useEffect } from "react";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Stack,
  Chip,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Autocomplete,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tab,
  TableBody,
  TextField,
  LinearProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "../../../axios-config";
import { processService } from "../../../utils/service-helpers";
import { useSelector } from "react-redux";
import { tr } from "date-fns/locale";
import { useSnackbar } from "notistack";
import { formatCurrency } from "../../../utils/currency";
import { useQuery } from "react-query";
import { getRefundableServices } from "api/repair-orders";
import useAddRefund from "hooks/mutations/orders/useAddRefund";
import { LoadingButton } from "@mui/lab";
import sanitizations from "utils/sanitizations";

const RefundDialog = ({ open, onClose, repairOrderId, services }) => {
  const [refundAmounts, setRefundAmounts] = useState({});
  const currency = useSelector((state) => state.auth.currency);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const addRefund = useAddRefund();

  const {
    data: refundableServices = [],
    isLoading,
    isError,
  } = useQuery(
    ["refundableServices", repairOrderId],
    () => getRefundableServices({ repairOrderId }),
    {
      enabled: open, // Only fetch when the dialog is open
    }
  );

  useEffect(() => {
    if (open) setRefundAmounts({});
  }, [open]);

  const handleAddRefund = async () => {
    const refundData = Object.entries(refundAmounts)
      .map(([serviceId, amount]) => ({
        serviceId: parseInt(serviceId),
        amount: parseFloat(amount),
      }))
      .filter((refund) => refund.amount > 0); // Filter out refunds with amount 0

    if (refundData.length === 0) {
      enqueueSnackbar(`Please enter refund amount!`, { variant: "error" });
      return;
    }

    addRefund.mutate({
      repairOrderId,
      serviceRefunds: refundData,
    });
    onClose();

    // try {

    //   const response = await axios.post(
    //     `/repair-orders/${repairOrderId}/refund`,
    //     { serviceRefunds: refundData }
    //   );
    //   if (response.status !== 200) {
    //     throw response.status;
    //   }
    //   onRefund(response.data);
    //   enqueueSnackbar(`Refund added succesfully!`, { variant: "success" });
    //   onClose();
    // } catch (error) {
    //   console.error("Failed to add refund:", error);
    // }
  };

  const handleFullRefundButton = (service) => {
    const processedService = processService(service);
    handleRefundAmountChange(
      service,
      processedService.unitPrice * processedService.quantity - service.discount
    );
  };

  const handleRefundAmountChange = (service, amount) => {
    amount = sanitizations.onlyFloat(String(amount));
    amount = parseFloat(amount);
    const processedService = processService(service);
    if (
      amount >
      processedService.unitPrice * processedService.quantity -
        processedService.discount
    ) {
      amount =
        processedService.unitPrice * processedService.quantity -
        processedService.discount;
    }
    setRefundAmounts((prevRefundAmounts) => ({
      ...prevRefundAmounts,
      [service.id]: amount,
    }));
  };

  //   filter refundable services if any it's id is found in services.refundedServiceId
  let refundableServicesFilter = refundableServices.filter(
    (rService) => !services.find((s) => s.refundedServiceId === rService.id)
  );

  //TODO: Show alredy refunded services

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
      <DialogTitle>{t("dialogs.refund.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* {t('Are you sure you want to refund this order?')} */}
        </DialogContentText>
        {/* <Stack>
                    <Stack direction={'row'} spacing={2} pt={3}>
                        <Typography variant={'h6'}>Service name</Typography>
                        <Typography variant={'h6'}>{t('Refund amount')}</Typography>
                        <Typography variant={'h6'}>{repairOrder.totalPrice}</Typography>

                </Stack> */}
        {isLoading && <LinearProgress />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("dialogs.refund.tableServiceName")}</TableCell>
              <TableCell>{t("dialogs.refund.serviceTotal")}</TableCell>
              <TableCell>{t("dialogs.refund.refundAmount")}</TableCell>

              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {refundableServicesFilter.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography>
                    {t("dialogs.refund.noServicesToRefund")}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {refundableServicesFilter?.map((service) => {
              const processedService = processService(service);
              return (
                <TableRow key={service.id}>
                  <TableCell>
                    <Typography variant="body2" maxWidth={"350px"}>
                      {service.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {formatCurrency(
                      processedService.unitPrice * processedService.quantity -
                        processedService.discount,
                      currency
                    )}
                  </TableCell>
                  <TableCell>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box width={"100px"}>
                        <TextField
                          size="small"
                          variant="filled"
                          placeholder="0.00"
                          hiddenLabel
                          value={refundAmounts[service.id] || ""}
                          onChange={(e) =>
                            handleRefundAmountChange(service, e.target.value)
                          }
                        ></TextField>
                      </Box>
                      {/* <Typography>/{service.quantity}</Typography> */}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant={"outlined"}
                      onClick={(e) => handleFullRefundButton(service)}
                    >
                      {t("dialogs.refund.fullRefund")}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <LoadingButton loading={addRefund.isLoading} onClick={handleAddRefund}>
          {t("Refund")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RefundDialog;
