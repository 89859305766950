import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import PasswordTextField from "components/NewEntryStepper/PasswordTextField";
import useCreateFollowUp from "hooks/mutations/orders/useCreateFollowUp";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const FollowUpDialog = ({ repairOrderId, open, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [malfunction, setMalfunction] = useState("");
  const [note, setNote] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordPattern, setIsPasswordPattern] = useState(false);
  // navigate(`/editEntry/${items[index].id}`);

  const createFollowUp = useCreateFollowUp();
  const handleFollowUpOrderCreation = () => {
    // Pantalla no va
    // Test continue for iphone xs max (isam 1#2218)
    // create follow up order then navigate to id
    createFollowUp.mutate(
      {
        repairOrderId,
        followUpData: { malfunction, note, password, isPasswordPattern },
      },
      {
        // navigate to id on success
        onSuccess: (data, variables, context) => {
          onClose();
          navigate(`/editEntry/${data.id}`);
        },
      }
    );
  };
  if (!repairOrderId) return null;

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth={"sm"} fullWidth>
      <DialogTitle>{t("dialogs.followUp.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("dialogs.followUp.message")}</DialogContentText>
        <Typography variant="caption">{t("dialogs.followUp.note")}</Typography>

        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            label={t("Malfunction")}
            maxRows={3}
            multiline
            fullWidth
            variant="filled"
            onChange={(e) => setMalfunction(e.target.value)}
            value={malfunction}
          />
          <TextField
            label={t("Nota")}
            maxRows={3}
            multiline
            fullWidth
            variant="filled"
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
          <PasswordTextField
            value={password}
            setValue={setPassword}
            isPasswordPattern={isPasswordPattern}
            setIsPasswordPattern={setIsPasswordPattern}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onclose()}>{t("Close")}</Button>
        <LoadingButton
          loading={createFollowUp.isLoading}
          onClick={handleFollowUpOrderCreation}
        >
          {t("Create")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

FollowUpDialog.propTypes = {
  repairOrderId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FollowUpDialog;
