import React, { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
  DialogContentText,
  List,
  ListItemButton,
  ListItemText,
  createFilterOptions,
  Typography,
  Alert,
} from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import axios from "../../../axios-config";
import { tr } from "react-date-range/dist/locale";
import { set } from "date-fns";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getRepairParentOptions } from "api/repair-orders";
import useSetRepairParent from "hooks/mutations/orders/useParentRepair";
import { LoadingButton } from "@mui/lab";
import useRemoveRepairParent from "hooks/mutations/orders/useRemoveRepairParent";

const OrderParentingDialog = ({
  repairOrderId,
  deliveredAt,
  parentOrderId,
  open,
  onClose,
  handleParentChange,
  outsourcedFrom,
  services,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const { t } = useTranslation();
  const parentRepair = useSetRepairParent();
  const removeRepairParent = useRemoveRepairParent();

  useEffect(() => {
    if (parentOrderId && !deliveredAt) {
      setShowRemoveDialog(true);
    } else {
      setShowRemoveDialog(false);
    }
  }, [parentOrderId, deliveredAt]);

  const handleOptionChange = (option) => {
    console.log(option);
    setSelectedOption(option);
  };

  const handleDialogClose = () => {
    setSelectedOption(null);
    onClose();
  };

  const handleSave = () => {
    parentRepair.mutate(
      { repairOrderId, parentOrderId: selectedOption.id },
      {
        onSuccess: () => {
          handleDialogClose();
        },
      }
    );
  };

  const handleRemoveParent = () => {
    removeRepairParent.mutate(
      { repairOrderId },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <>
      <Dialog open={open && showRemoveDialog} onClose={handleDialogClose}>
        <DialogTitle>{t("dialogs.removeRepairParent.title")}</DialogTitle>
        <DialogContent>
          <Typography>{t("dialogs.removeRepairParent.message")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>
            {t("dialogs.removeRepairParent.cancel")}
          </Button>
          <LoadingButton
            onClick={handleRemoveParent}
            loading={removeRepairParent.isLoading}
          >
            {t("dialogs.removeRepairParent.confirm")}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open && !showRemoveDialog && !removeRepairParent.isLoading}
        onClose={handleDialogClose}
        maxWidth={"sm"}
      >
        <DialogTitle>{t("dialogs.setRepairParent.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans
              i18nKey="dialogs.setRepairParent.message"
              components={[<strong />, <br />]}
            >
              Link this repair with a prior one to form a{" "}
              <strong>Follow-Up Repair</strong>. You'd usually do this from the
              original repair's page. <br />
              Just a heads-up: You can only issue refunds through a follow-up
              repair.
            </Trans>
          </DialogContentText>
          {services?.length > 0 && (
            <>
              <Alert severity="warning" sx={{ mt: 2 }}>
                {t("dialogs.setRepairParent.servicesWarning")}
              </Alert>
            </>
          )}
          <Box sx={{ py: 3 }}>
            <MyAutocomplete
              onOptionSelect={handleOptionChange}
              repairOrderId={repairOrderId}
              outsourcedFrom={outsourcedFrom}
              disabled={services?.length > 0}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>
            {t("dialogs.setRepairParent.cancel")}
          </Button>
          <LoadingButton onClick={handleSave} loading={parentRepair.isLoading}>
            {t("dialogs.setRepairParent.confirm")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const truncate = (text, maxLength = 20) => {
  if (!text) return "";
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

const getRefId = (option) => {
  const organizationId =
    option.outsourcedFrom?.organization?.id || option.organizationId;
  const organizationCounter =
    option.outsourcedFrom?.organizationCounter || option.organizationCounter;
  return `${organizationId}#${organizationCounter} - ${option.brand} ${option.model}`;
};

//////////////////////////

const MyAutocomplete = ({
  onOptionSelect,
  repairOrderId,
  outsourcedFrom,
  disabled,
}) => {
  // State to control the open/closed status of the Autocomplete dropdown.
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  // Function to fetch the parent options.
  const fetchParentOptions = async () => {
    const data = await getRepairParentOptions({ repairOrderId });
    return data;
  };

  // Function to process and filter the fetched options.
  const processParentOptions = (data) => {
    if (outsourcedFrom) {
      // Only return items matching the provided outsourced organization.
      return data.filter(
        (item) =>
          item.outsourcedFrom?.organization?.id ===
            outsourcedFrom.organization.id && item.id !== repairOrderId
      );
    }
    return data;
  };

  // Use React Query to fetch data only when the dropdown is open.
  const { data, isLoading, refetch } = useQuery(
    ["parentOptions", repairOrderId, outsourcedFrom],
    fetchParentOptions,
    { enabled: open }
  );

  // Process the fetched data if available.
  const options = data ? processParentOptions(data) : [];

  // Handlers for opening and closing the dropdown.
  const handleOpen = () => {
    setOpen(true);
    refetch(); // Refresh the data when opened.
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Handler for when the user selects an option from the Autocomplete.
  const handleOnChange = (event, value) => {
    onOptionSelect(value);
  };

  // Custom Listbox component that forwards the ref.
  const ForwardedList = forwardRef((props, ref) => (
    <List ref={ref} sx={{ overflow: "auto", maxHeight: "500px" }} {...props} />
  ));

  // Filter options by concatenating several fields into a search string.
  const filter = createFilterOptions({
    stringify: (option) => {
      let searchString = `${option.brand} ${option.model} ${option.imei}`;
      if (option.outsourcedFrom) {
        searchString += ` ${option.outsourcedFrom.organization.id}#${option.outsourcedFrom.organizationCounter}`;
      } else {
        searchString += ` ${option.organizationId}#${option.organizationCounter}`;
      }
      return searchString;
    },
  });

  return (
    <Autocomplete
      disabled={disabled}
      variant="filled"
      open={open}
      options={options}
      loading={options.length === 0}
      filterOptions={filter}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleOnChange}
      ListboxComponent={ForwardedList}
      getOptionLabel={getRefId}
      renderOption={(props, option) => {
        // Only render the first 10 options.
        if (props["data-option-index"] < 10) {
          return (
            <OptionList
              {...props}
              key={option.id}
              option={option}
              handleOptionSelect={onOptionSelect}
            />
          );
        }
        return null;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("dialogs.setRepairParent.inputLabel")}
          variant="filled"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {options.length === 0 && isLoading && (
                  <span>{t("general.loading")}...</span>
                )}
              </>
            ),
          }}
        />
      )}
    />
  );
};

const OptionList = ({ option, handleOptionSelect, ...props }) => {
  // When the option is clicked, notify the parent.
  const handleOnClick = () => {
    handleOptionSelect(option);
  };

  // Determine organization details.
  const organizationId =
    option.outsourcedFrom?.organization?.id || option.organizationId;
  const organizationCounter =
    option.outsourcedFrom?.organizationCounter || option.organizationCounter;

  // Truncate long text fields for display.
  const brand = truncate(option.brand);
  const model = truncate(option.model);
  const imeiLabel = truncate(option.imei);

  // Combine details into a label.
  const refIdLabel = `${organizationId}#${organizationCounter} - ${brand} ${model}`;

  return (
    <ListItemButton
      onClick={handleOnClick}
      sx={{ minHeight: "72px" }}
      {...props}
    >
      <ListItemText
        primary={refIdLabel}
        secondary={imeiLabel ? `IMEI: ${imeiLabel}` : ""}
        primaryTypographyProps={{ component: "div" }}
        secondaryTypographyProps={{ component: "div" }}
      />
    </ListItemButton>
  );
};

export default OrderParentingDialog;
