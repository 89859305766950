import { Box } from "@mui/system";
import React from "react";

import UpdatedAtDate from "components/UpdatedAtDate/UpdatedAtDate";
import CreatedAtDate from "components/CreatedAtDate/CreatedAtDate";
import DeliveredAtDate from "components/DeliveredAtDate/DeliveredAtDate";

const Dates = React.memo(({ createdAt, updatedAt, deliveredAt }) => {
  const [currentTime, setCurrentTime] = React.useState(Date.now());

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, 30 * 1000); // 30 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return (
    <>
      <Box>
        <CreatedAtDate createdAt={createdAt} />
      </Box>
      <Box>
        <UpdatedAtDate updatedAt={updatedAt} />
      </Box>
      <Box>
        <DeliveredAtDate deliveredAt={deliveredAt} />
      </Box>
    </>
  );
});

export default Dates;
