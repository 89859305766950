import React, { useState, useEffect, useRef, useCallback } from "react";

// Define coordinates for a 3x3 grid (adjust as needed)
const GRID_COORDS = [
  { x: 10, y: 10 }, // point 1
  { x: 50, y: 10 }, // point 2
  { x: 90, y: 10 }, // point 3
  { x: 10, y: 50 }, // point 4
  { x: 50, y: 50 }, // point 5
  { x: 90, y: 50 }, // point 6
  { x: 10, y: 90 }, // point 7
  { x: 50, y: 90 }, // point 8
  { x: 90, y: 90 }, // point 9
];

const ANIMATION_DURATION = 250; // Duration in ms for each segment

const AnimatedPatternLock = ({ value, shouldAnimate }) => {
  const [drawnPoints, setDrawnPoints] = useState([]); // fully drawn points (each as a coordinate)
  const [currentPoint, setCurrentPoint] = useState(null); // current interpolated point
  const animationFrameRef = useRef(null);
  const timeoutRef = useRef(null);

  // Clear any pending animations/timeouts
  const clearAnimations = () => {
    if (animationFrameRef.current)
      cancelAnimationFrame(animationFrameRef.current);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  };

  // Convert the passed-in value (a string of digits 1-9) to an array of coordinates.
  const getCoordinatesFromValue = useCallback(() => {
    if (!value) return [];
    return value.split("").map((digit) => {
      const index = Math.max(0, Math.min(8, parseInt(digit, 10) - 1));
      return GRID_COORDS[index];
    });
  }, [value]);

  // Linear interpolation between two points.
  const interpolate = (start, end, progress) => ({
    x: start.x + (end.x - start.x) * progress,
    y: start.y + (end.y - start.y) * progress,
  });

  // Animate a segment from start to end over ANIMATION_DURATION.
  // When done, call onComplete.
  const animateSegment = (start, end, onComplete) => {
    let startTime = null;
    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / ANIMATION_DURATION, 1);
      const interpolatedPoint = interpolate(start, end, progress);
      setCurrentPoint(interpolatedPoint);
      if (progress < 1) {
        animationFrameRef.current = requestAnimationFrame(step);
      } else {
        setCurrentPoint(null);
        onComplete();
      }
    };
    animationFrameRef.current = requestAnimationFrame(step);
  };

  // Animate the entire path sequentially.
  const animatePath = useCallback(() => {
    const coords = getCoordinatesFromValue();
    if (coords.length === 0) return;
    // Start with the first point activated.
    setDrawnPoints([coords[0]]);
    let segmentIndex = 0;

    const animateNextSegment = () => {
      if (segmentIndex < coords.length - 1) {
        const start = coords[segmentIndex];
        const end = coords[segmentIndex + 1];
        animateSegment(start, end, () => {
          // When segment completes, mark the endpoint as drawn.
          setDrawnPoints((prev) => [...prev, end]);
          segmentIndex++;
          animateNextSegment();
        });
      } else {
        // When done, restart the animation after a delay.
        timeoutRef.current = setTimeout(() => {
          setDrawnPoints([]);
          animatePath();
        }, 1000);
      }
    };

    if (coords.length > 1) {
      animateNextSegment();
    }
  }, [getCoordinatesFromValue]);

  useEffect(() => {
    if (shouldAnimate) {
      clearAnimations();
      setDrawnPoints([]);
      setCurrentPoint(null);
      animatePath();
    } else {
      // Show the completed pattern immediately.
      clearAnimations();
      const coords = getCoordinatesFromValue();
      setDrawnPoints(coords);
      setCurrentPoint(null);
    }
    return () => clearAnimations();
  }, [shouldAnimate, animatePath, getCoordinatesFromValue]);

  // Render the SVG overlay.
  const renderPath = () => {
    const polylinePoints = drawnPoints.map((pt) => `${pt.x},${pt.y}`).join(" ");
    let connectorLine = null;
    if (currentPoint && drawnPoints.length > 0) {
      const last = drawnPoints[drawnPoints.length - 1];
      connectorLine = {
        x1: last.x,
        y1: last.y,
        x2: currentPoint.x,
        y2: currentPoint.y,
      };
    }

    return (
      <svg width={100} height={100}>
        {GRID_COORDS.map((pt, idx) => (
          <circle key={`grid-${idx}`} cx={pt.x} cy={pt.y} r={3} fill="#ccc" />
        ))}

        {drawnPoints.length > 1 && (
          <polyline
            points={polylinePoints}
            fill="none"
            stroke="blue"
            strokeWidth={2}
          />
        )}

        {connectorLine && (
          <line
            x1={connectorLine.x1}
            y1={connectorLine.y1}
            x2={connectorLine.x2}
            y2={connectorLine.y2}
            stroke="blue"
            strokeWidth={2}
          />
        )}

        {drawnPoints.map((pt, idx) => (
          <circle key={`active-${idx}`} cx={pt.x} cy={pt.y} r={3} fill="blue" />
        ))}

        {currentPoint && (
          <circle cx={currentPoint.x} cy={currentPoint.y} r={3} fill="blue" />
        )}
      </svg>
    );
  };

  return renderPath();
};

export default AnimatedPatternLock;
