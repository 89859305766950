import * as React from "react";
import axios from "../../axios-config";
import { useSnackbar } from "notistack";

import {
  Box,
  Divider,
  IconButton,
  Stack,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Table,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import NewServiceDialog from "./Dialogs/NewServiceDialog";
import ServiceRow from "./ServiceRow";
import ServicesEmptyPlaceholder from "./ServicesEmptyPlaceholder";
import ServicesList from "./ServicesList";
import ServiceItemList from "./ServiceItemList";

// ICONS
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import PaymentItemList from "./PaymentItemList";
import PartItemList from "./PartItemList";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import ServiceItemListPartner from "./ServiceItemListPartner";
import { useTranslation } from "react-i18next";
import {
  getAcceptedAndRefundedServices,
  getAcceptedServices,
  getDeclinedAndProposedServices,
  getDeclinedServices,
  getProposedServices,
  getRefundedServices,
  processService,
  servicesCalculation,
} from "utils/service-helpers";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../utils/currency";
import ServiceProposed from "./LeftPanel/ServiceProposed";
import ServiceDeclined from "./LeftPanel/ServiceDeclined";
import ServiceAccepted from "./LeftPanel/ServiceAccepted";
import OutsourcePartnerSelect from "components/OutsourcePartnerSelect";
import useAcceptOutsourceService from "hooks/mutations/orders/outsource/useAcceptOutsourceService";
import useRemoveAcceptOutsourceService from "hooks/mutations/orders/outsource/useRemoveAcceptOutsourceService";
import useDeclineOutsourceService from "hooks/mutations/orders/outsource/useDeclineOutsourceService";
import ServiceRefunded from "./LeftPanel/ServiceRefunded";
import { CheckCircle, HighlightOff } from "@mui/icons-material";

const ExternalServiceContent = ({
  repairOrderId,
  outsourcedTo,
  outsourcedFrom,
  deliveredAt,
  repairOrderOutsourceRequest,
  resolutionType,
}) => {
  const { t } = useTranslation();
  const [externalizedInfoDialogOpen, setExternalizedInfoDialogOpen] =
    React.useState(false);

  const handleExternalizedInfoDialogClose = () => {
    setExternalizedInfoDialogOpen(false);
  };
  const handleExternalizedInfoDialogOpen = () => {
    setExternalizedInfoDialogOpen(true);
  };

  const partnerSelect = () => {
    if (deliveredAt || outsourcedFrom || outsourcedTo || resolutionType)
      return null;
    return (
      <>
        <OutsourcePartnerSelect
          repairOrderId={repairOrderId}
          repairOrderOutsourceRequest={repairOrderOutsourceRequest}
        />
      </>
    );
  };

  return (
    <>
      <Box px={"25px"}>
        <Button
          size="small"
          color="warning"
          onClick={handleExternalizedInfoDialogOpen}
        >
          {t("What will the partner access and view?")}
        </Button>
        {partnerSelect()}
        <PartnerServices
          repairOrderId={repairOrderId}
          services={outsourcedTo?.services}
          payments={outsourcedTo?.repairPayments}
          outsourcedTo={outsourcedTo}
          outsourcedFrom={outsourcedFrom}
        />
      </Box>

      <WhatWillPartnerAccessDialog
        open={externalizedInfoDialogOpen}
        onClose={handleExternalizedInfoDialogClose}
      />
    </>
  );
};

const PartnerServices = ({
  repairOrderId,
  services,
  payments,
  outsourcedTo,
  outsourcedFrom,
}) => {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.auth.currency);
  const acceptOutsourceService = useAcceptOutsourceService();
  const removeOutsourceAcceptService = useRemoveAcceptOutsourceService();
  const declineOutsourceService = useDeclineOutsourceService();

  const onServiceAccept = (service) => {
    acceptOutsourceService.mutate({
      repairOrderId: repairOrderId,
      serviceId: service.id,
    });
  };

  const onRemoveAccept = (service) => {
    removeOutsourceAcceptService.mutate({
      repairOrderId: repairOrderId,
      serviceId: service.id,
    });
  };

  const onDecline = (service) => {
    declineOutsourceService.mutate({
      repairOrderId: repairOrderId,
      serviceId: service.id,
    });
  };

  if (outsourcedTo === undefined || outsourcedTo === null) return null;
  const declinedServices = getDeclinedServices(outsourcedTo.services);
  const acceptedServices = getAcceptedServices(outsourcedTo.services);
  const proposedServices = getProposedServices(outsourcedTo.services);
  const refundedServices = getRefundedServices(outsourcedTo.services);

  const declinedAndProposedServices = getDeclinedAndProposedServices(
    outsourcedTo.services
  );

  const declinedAndProposedServicesProcessed =
    declinedAndProposedServices.reduce((acc, service) => {
      const processed = processService(service);
      return (acc +=
        processed.unitPrice * processed.quantity - processed.discount);
    }, 0);

  const acceptedAndRefundedServices = getAcceptedAndRefundedServices(services);
  const acceptedAndRefundedServicesProcessed =
    acceptedAndRefundedServices.reduce((acc, service) => {
      const processed = processService(service);
      return (acc +=
        processed.unitPrice * processed.quantity - processed.discount);
    }, 0);

  return (
    <>
      {!outsourcedTo.services?.length ? (
        <>
          <ServicesEmptyPlaceholder />
        </>
      ) : (
        <Box>
          <Box>
            {proposedServices.length === 0 &&
            declinedServices.length === 0 ? null : (
              <ServicesList
                title={t("Partner proposed services")}
                additionalInfo={`${formatCurrency(
                  declinedAndProposedServicesProcessed,
                  currency
                )}`}
                servicesListItems={[
                  ...proposedServices.map((service) => (
                    <ServiceProposed
                      key={service.id}
                      service={service}
                      handleAcceptService={onServiceAccept}
                      handleDeclineService={onDecline}
                    />
                  )),
                  ...declinedServices.map((service) => (
                    <ServiceDeclined
                      key={service.id}
                      service={service}
                      onRevert={onRemoveAccept}
                    />
                  )),
                ]}
                sx={{ mb: "5px" }}
              />
            )}

            {acceptedServices.length === 0 &&
            refundedServices.length === 0 ? null : (
              <ServicesList
                title={t("Partner confirmed services")}
                additionalInfo={`${formatCurrency(
                  acceptedAndRefundedServicesProcessed,
                  currency
                )}`}
                servicesListItems={[
                  ...acceptedServices.map((service) => (
                    <ServiceAccepted
                      key={service.id}
                      service={service}
                      onRevert={onRemoveAccept}
                    />
                  )),
                  ...refundedServices.map((service) => (
                    <ServiceRefunded key={service.id} service={service} />
                  )),
                ]}
                sx={{ mb: "5px" }}
              />
            )}
          </Box>
        </Box>
      )}
      {outsourcedTo?.repairPayments?.length > 0 ? (
        <ServicesList
          title={t("Payments")}
          servicesListItems={
            <>
              {outsourcedTo.repairPayments.map((payment) => (
                <PaymentItemList key={payment.id} item={payment} />
              ))}
            </>
          }
          sx={{ mb: "5px" }}
        />
      ) : null}
    </>
  );
};

const WhatWillPartnerAccessDialog = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
      <DialogTitle>{t("Partner Access During Outsourcing")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            'When you outsource a repair to a partner organization, they gain temporary access to certain details. This access lasts until the order is marked as "Delivered" by your partner. Below is a detailed list of what your partner can do:'
          )}
        </DialogContentText>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t("View")}</TableCell>
                <TableCell>{t("Edit")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="body2">{t("Statuses")}</Typography>
                </TableCell>
                <TableCell>
                  <CheckCircle sx={{ color: "accepted.main" }} />
                </TableCell>
                <TableCell>
                  <CheckCircle sx={{ color: "accepted.main" }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body2">
                    {t("Brand")} & {t("Model")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <CheckCircle sx={{ color: "accepted.main" }} />
                </TableCell>
                <TableCell>
                  <CheckCircle sx={{ color: "accepted.main" }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body2">
                    {t("Estimate services")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <CheckCircle sx={{ color: "accepted.main" }} />
                </TableCell>
                <TableCell>
                  <CheckCircle sx={{ color: "accepted.main" }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body2">
                    {t("Emergency level")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <CheckCircle sx={{ color: "accepted.main" }} />
                </TableCell>
                <TableCell>
                  <CheckCircle sx={{ color: "accepted.main" }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body2">{t("Malfunction")}</Typography>
                </TableCell>
                <TableCell>
                  <CheckCircle sx={{ color: "accepted.main" }} />
                </TableCell>
                <TableCell>
                  <HighlightOff sx={{ color: "red.main" }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body2">{t("Note")}</Typography>
                </TableCell>
                <TableCell>
                  <CheckCircle sx={{ color: "accepted.main" }} />
                </TableCell>
                <TableCell>
                  <HighlightOff sx={{ color: "red.main" }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body2">{t("Category")}</Typography>
                </TableCell>
                <TableCell>
                  <HighlightOff sx={{ color: "red.main" }} />
                </TableCell>
                <TableCell>
                  <HighlightOff sx={{ color: "red.main" }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body2">
                    {t("My customer or any data of this")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <HighlightOff sx={{ color: "red.main" }} />
                </TableCell>
                <TableCell>
                  <HighlightOff sx={{ color: "red.main" }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body2">
                    {t(
                      "Services, Parts and Payment between me and my customer"
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <HighlightOff sx={{ color: "red.main" }} />
                </TableCell>
                <TableCell>
                  <HighlightOff sx={{ color: "red.main" }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body2">
                    {t("My private comments")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <HighlightOff sx={{ color: "red.main" }} />
                </TableCell>
                <TableCell>
                  <HighlightOff sx={{ color: "red.main" }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body2">
                    {t("Logs before the repair being outsourced")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <HighlightOff sx={{ color: "red.main" }} />
                </TableCell>
                <TableCell>
                  <HighlightOff sx={{ color: "red.main" }} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography pt={2}>
          {t(
            "Please note, your partner's access to these details terminates once the order is delivered. The only ability left is communication through comments and processing payments between you and your partner."
          )}
        </Typography>
        <Typography pt={2}>
          {t(
            "In the event of additional follow-up repairs (e.g. a warranty claim) or subsequent discussions, your partner will see the order as it was prior to delivery. Be sure to notify them of any changes that occur after delivery."
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExternalServiceContent;
