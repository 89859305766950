import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios-config";

export const fetchStatuses = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/repair-orders/statuses");
      const fetchedStatuses = response.data.map((status) => ({
        value: status.name,
        id: status.id.toString(),
        isHidden: false,
      }));

      // Get the statuses stored in local storage
      let storedStatuses = JSON.parse(localStorage.getItem("statuses")) || [];

      // Create a new array combining the stored statuses and fetched statuses
      // If a status with the same id already exists in storedStatuses, it will not be added
      if (!Array.isArray(storedStatuses)) {
        storedStatuses = [];
      }

      const updatedStatuses = [...storedStatuses];
      for (const fetchedStatus of fetchedStatuses) {
        if (!storedStatuses.some((status) => status.id === fetchedStatus.id)) {
          updatedStatuses.push(fetchedStatus);
        }
      }

      // Save the updated statuses to local storage and state
      localStorage.setItem("statuses", JSON.stringify(updatedStatuses));
      dispatch(dataTableSlice.actions.setStatuses(updatedStatuses));
    } catch (err) {
      console.log(err);
      // handle error here
    }
  };
};

const initialState = {
  statuses: [
    { value: "Request", id: "11", isHidden: false },
    { value: "Diagnose", id: "2", isHidden: false },
    { value: "In Queue", id: "1", isHidden: false },
    { value: "Notify Customer", id: "3", isHidden: false },
    { value: "On Hold", id: "4", isHidden: false },
    { value: "In Repair", id: "5", isHidden: false },
    { value: "Waiting For Parts", id: "6", isHidden: false },
    { value: "In Delivery", id: "7", isHidden: false },
    { value: "Quality Assurance", id: "8", isHidden: false },
    { value: "Ready For Pick Up", id: "9", isHidden: false },
    { value: "Delivered To Customer", id: "10", isHidden: true },
    { value: "Order Parts", id: "13", isHidden: false },
    { value: "Delivered", id: "12", isHidden: true },
    { value: "Received From Partner", id: "14", isHidden: true },
  ],
  statusesLoaded: false,
  repairOrders: [],
  partnersFilter: [],
  search: "",
  unpaidCheck: false,
  dueToPartnerCheck: false,
  unpaidIds: [],
  dueTopartnerIds: [],
  urgenciesToggle: false,
  location: 0,
  assigneeFilter: [],
};

const dataTableSlice = createSlice({
  name: "dataTable",
  initialState: initialState,
  reducers: {
    setDataTable(state, action) {
      state.repairOrders = action.payload;
    },
    setStatuses(state, action) {
      state.statuses = action.payload;
      localStorage.setItem("statuses", JSON.stringify(state.statuses));
    },
    setAssigneeFilter(state, action) {
      state.assigneeFilter = action.payload;
    },
    setSearch(state, action) {
      state.search = action.payload;
    },

    setPartnersFiltered(state, action) {
      state.partnersFilter = action.payload;
    },
    toggleDueFromServices(state) {
      state.unpaidCheck = !state.unpaidCheck;
      if (state.dueToPartnerCheck) {
        state.dueToPartnerCheck = false;
      }
    },
    toggleDueToPartner(state) {
      state.dueToPartnerCheck = !state.dueToPartnerCheck;
      if (state.unpaidCheck) {
        state.unpaidCheck = false;
      }
    },
    setUnpaidIds(state, action) {
      state.unpaidIds = action.payload;
    },
    setDueToPartnerIds(state, action) {
      state.dueTopartnerIds = action.payload;
    },
    toggleUrgency(state, action) {
      state.urgenciesToggle = !state.urgenciesToggle;
    },
    setTableLocation(state, action) {
      state.location = action.payload;
    },
    setStatusesLoaded(state) {
      state.statusesLoaded = true;
    },
    logout(state, action) {
      state.repairOrders = [];
      state.repairOrders = [];
      state.partnersFilter = [];
      state.search = "";
      state.unpaidCheck = false;
      state.dueToPartnerCheck = false;
      state.unpaidIds = [];
      state.dueTopartnerIds = [];
    },
  },
});

export const dataTableActions = dataTableSlice.actions;
export default dataTableSlice.reducer;
