import * as React from "react";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import CommentTextShort from "../../containers/EditOrder/Comments/CommentsShort";
const PublicNoteCell = ({
  rowData,
  minWidthLastCommentColumn,
  maxWidthLastCommentColumn,
}) => {
  const auth = useSelector((state) => state.auth);

  if (rowData.isRequest) {
    return null;
  }
  //Get all comments from rowData.comments and rowData.outsourcedTo.comments
  let allComments = [];
  if (rowData.comments) {
    allComments = [...rowData.comments];
  }
  if (rowData.outsourcedTo?.comments) {
    allComments = [...allComments, ...rowData.outsourcedTo.comments];
  }
  if (rowData.outsourcedFrom?.comments) {
    allComments = [...allComments, ...rowData.outsourcedFrom.comments];
  }
  //Sort comments by date
  allComments.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  //Get last comment
  const lastComment = allComments[0];

  return (
    <Box maxHeight={"148px"}>
      <CommentTextShort data={lastComment} auth={auth} minimal />
      {/* <Box>{rowData.comments[0].body}</Box> */}
    </Box>
  );
};

export default PublicNoteCell;
