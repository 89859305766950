// useUpdateOrderStatusMutation.js
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { addServiceToRepairOrder } from "api/repair-orders";

const useAddService = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(addServiceToRepairOrder, {
    onMutate: async ({
      repairOrderId,
      name,
      quantity,
      unitPrice,
      discount,
      assignedTo,
      stock,
      isOutsource,
    }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: ["repairOrder", repairOrderId],
      });

      // Snapshot the previous data
      const previousOrder = queryClient.getQueryData([
        "repairOrder",
        repairOrderId,
      ]);

      if (!previousOrder) {
        console.error("No previous order found");
        console.log("repairOrderId", repairOrderId);
        return;
      }
      const tempId = `temp-${Date.now()}`;
      const newService = {
        id: tempId,
        name,
        quantity,
        unitPrice,
        discount,
        assignedTo,
        refundedServiceId: null,
        isAccepted: null,
        decisionAt: null,
        deletedAt: null,
        stock: stock === null ? null : stock === "1" ? true : false,
        isOutsource,
        createdAt: dayjs().toISOString(),
        isCreating: true,
      };
      console.log("newService", newService);
      // Optimistically update the repair order with the new comment
      queryClient.setQueryData(["repairOrder", repairOrderId], (old) => ({
        ...old,
        updatedAt: dayjs().toISOString(),
        services: [...(old.services || []), newService],
      }));

      //check new repairOrder
      console.log(
        "new repairOrder",
        queryClient.getQueryData(["repairOrder", repairOrderId])
      );

      // Return context with the previous order for rollback
      return { previousOrder, tempId };
    },
    onError: (error, variables, context) => {
      if (context?.previousOrder) {
        queryClient.setQueryData(
          ["repairOrder", variables.repairOrderId],
          context.previousOrder
        );
      }
      console.log(error.response);
      console.error(error?.response?.data?.message || "Error adding service");
      enqueueSnackbar("Failed to add service", { variant: "error" });
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar("Service added successfully", { variant: "success" });
      queryClient.setQueryData(
        ["repairOrder", variables.repairOrderId],
        (old) => ({
          ...old,
          services: old.services.map((service) =>
            service.id === context.tempId
              ? { ...service, ...data, isCreating: false }
              : service
          ),
        })
      );
    },
    onSettled: (data, error, variables) => {
      // queryClient.invalidateQueries(["repairOrder", variables.repairOrderId]);
    },
  });
};

export default useAddService;
