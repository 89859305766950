import { Box } from "@mui/material";

const BoxContainer = (props) => {
  return (
    <Box
      backgroundColor={"background.paper"}
      border={"1px solid"}
      borderColor={"lightGrey.main"}
      borderRadius={"10px"}
      p={2}
      sx={{
        ...props.sx,
        boxShadow:
          "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
      }}
    >
      {props.children}
    </Box>
  );
};
export default BoxContainer;
