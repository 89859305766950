import React, { useEffect, useRef, useState } from "react";
import { TextField, Typography } from "@mui/material";
import SoftTextField from "common/SoftTextField";

function EditableTextSoft({
  label,
  value = "", // Default value to an empty string
  onUpdate,
  validate,
  onSetTemp,
  fullWidth = true,
  ...props
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const [error, setError] = useState("");

  const saveTriggeredRef = useRef(false); // Prevent double save on enter key

  // Start editing when field is focused
  const handleFocus = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
    setTempValue(value);
  };

  const preventDoubleSaveOnEnterKey = () => {
    if (saveTriggeredRef.current) {
      saveTriggeredRef.current = false; // Reset the flag
      return true;
    }
    return false;
  };

  // Save changes
  const handleSave = () => {
    if (preventDoubleSaveOnEnterKey()) return;
    if (tempValue !== value) {
      onUpdate(tempValue);
    }
    setIsEditing(false);
    setError("");
  };

  // Cancel editing
  const handleCancel = () => {
    setIsEditing(false);
    setTempValue(value);
    setError("");
  };

  const handleSetTempValue = (e) => {
    setTempValue(e.target.value);
    if (onSetTemp) {
      onSetTemp(e.target.value);
    }
  };

  useEffect(() => {
    if (isEditing) {
      setTempValue(value);
    }
  }, [value, isEditing]);

  return (
    <SoftTextField
      fullWidth={fullWidth}
      value={isEditing ? tempValue : value || ""} // Ensure value is not null
      onChange={handleSetTempValue}
      onFocus={handleFocus}
      onBlur={() => {
        if (isEditing) handleSave();
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          saveTriggeredRef.current = true;
          handleSave();
          e.target.blur();
        }
        if (e.key === "Escape") {
          handleCancel();
        }
      }}
      {...props}
    />
  );
}

export default EditableTextSoft;
