import * as React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import axios from "../../axios-config";
import { useState, useEffect } from "react";

const DeviceImage = ({
  hideIfNoImage,
  brand,
  px,
  py,
  minWidth,
  maxWidth,
  width,
  height,
}) => {
  const [isMissing, setIsMissing] = useState(false);

  const onImageError = () => {
    // console.log("MISSING")
    setIsMissing(true);
  };
  useEffect(() => {
    setIsMissing(false);
  }, [brand]);

  const toPascalCase = (string) => {
    return `${string}`
      .toLowerCase()
      .replace(new RegExp(/[-_]+/, "g"), " ")
      .replace(new RegExp(/[^\w\s]/, "g"), "")
      .replace(
        new RegExp(/\s+(.)(\w*)/, "g"),
        ($1, $2, $3) => `${$2.toUpperCase() + $3}`
      )
      .replace(new RegExp(/\w/), (s) => s.toUpperCase());
  };

  return (
    // FIX alternative image
    <>
      {isMissing ? (
        hideIfNoImage ? null : (
          <Box
            sx={{
              backgroundColor: "white.main",
              px: px ? px : "5px",
              py: py ? py : "7px",
              minWidth: minWidth ? minWidth : "100px",
              maxWidth: maxWidth ? maxWidth : "100px",
              height: height ? height : "60px",
              border: "2px solid",
              borderColor: "rgba(187,194,205,0.5)",
              borderRadius: "10px",
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              justifyContent: "center",
              boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px;",
            }}
          >
            <Typography fontWeight={"600"} noWrap>
              {brand}
            </Typography>
          </Box>
        )
      ) : (
        <Box
          component="img"
          sx={{
            backgroundColor: "white.main",
            px: px ? px : "5px",
            py: py ? py : "7px",
            width: width ? width : "100px",
            height: height ? height : "60px",
            border: "2px solid",
            borderColor: "rgba(187,194,205,0.5)",
            borderRadius: "10px",
            objectFit: "contain",
            boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px;",
          }}
          alt={`${brand}`}
          src={`/static/images/brands/${toPascalCase(brand)}.png`}
          onError={onImageError}
        />
      )}
    </>
  );
};

export default React.memo(DeviceImage);
