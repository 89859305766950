import {
  AddOutlined,
  ArrowForwardIosOutlined,
  ArrowForwardOutlined,
  Close,
  DeleteOutline,
  EditOutlined,
  Image,
  LocationOnOutlined,
  MoreHoriz,
  MoreVert,
  RemoveOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  Select,
  Popover,
  Grid,
  Box,
  TablePagination,
  IconButton,
  MenuList,
  MenuItem,
  LinearProgress,
} from "@mui/material";
import axios from "axios-config";

import { t } from "i18next";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { string, z } from "zod";
import { set } from "lodash";
import LocationsDialog from "./LocationsDialog";
import ConsoleHelper from "utils/ConsoleHelper";
import AddNewProductDialog from "./AddNewProductDialog";
import EditProductDialog from "./EditProductDialog";
import { styled, alpha } from "@mui/material/styles";
import DeleteDialogWarningGeneric from "common/DeleteDialogWarningGeneric";
import { useSnackbar } from "notistack";
import Fuse from "fuse.js";
import { th } from "react-date-range/dist/locale";

const AllProducts = ({ warehouse }) => {
  const [products, setProducts] = useState([]);
  const [newProductDialogOpen, setNewProductDialogOpen] = useState(false);
  const [locationsDialogOpen, setLocationsDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productToDelete, setProductToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [isFetching, setIsFetching] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const fetchProducts = async () => {
    try {
      setIsFetching(true);
      // Make API call or database query to fetch inventory data

      const response = await axios.get(
        `/inventory/warehouse/${warehouse.id}/products`
      );
      if (response.status === 200) {
        const data = await response.data;
        // ConsoleHelper(data);
        setProducts(data);
      }
    } catch (error) {
      console.error("Error fetching inventory data:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    // Fetch inventory data from API
    fetchProducts();
  }, [warehouse]);

  useEffect(() => {
    if (!selectedProduct) {
      fetchProducts();
    }
  }, [selectedProduct]);

  const handleNewProductDialogClose = () => {
    setNewProductDialogOpen(false);
  };
  const handleLocationsDialogClose = () => {
    setLocationsDialogOpen(false);
  };

  const handleLocationsDialogOpen = () => {
    setLocationsDialogOpen(true);
  };

  const handleNewProductDialogOpen = () => {
    setNewProductDialogOpen(true);
  };

  const openProductEditDialog = (product) => {
    setSelectedProduct(product);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    //set page to 0
    setPage(0);
  };

const options = {
  shouldSort: true,
  useExtendedSearch: true,
  tokenize: true,
  matchAllTokens: true,
  ignoreLocation: true,  // allow matching out of order
  keys: [
    { name: "name", weight: 0.8 },
    { name: "sku", weight: 0.1 },
    { name: "barcode", weight: 0.1 }
  ],
  threshold: 0.2,
  distance: 50,
  minMatchCharLength: 1
};
  const fuse = new Fuse(products, options);

  const filteredProducts =
    searchTerm.length > 0
      ? fuse.search(searchTerm).map((result) => result.item)
      : products;

  // Filter products based on search term
  // const filteredProducts = products.filter((item) => {
  //   const terms = searchTerm.toLowerCase().split(" ");
  //   return terms.every(
  //     (term) =>
  //       item.name.toLowerCase().includes(term) ||
  //       item.sku.toLowerCase().includes(term) ||
  //       (item.barcode && item.barcode.toLowerCase().includes(term))
  //   );
  // });

  // // Pagination logic
  // const startIndex = page * rowsPerPage;
  // const endIndex = startIndex + rowsPerPage;
  // const paginatedProducts = filteredProducts.slice(startIndex, endIndex);

  const handleDeleteProduct = async () => {
    try {
      const response = await axios.delete(
        `/inventory/products/${productToDelete.id}`
      );
      if (response.status === 200) {
        enqueueSnackbar(t("Product deleted successfully!"), {
          variant: "success",
        });
        fetchProducts();
        setProductToDelete(null);
      }
    } catch (error) {
      setIsDeleting(false);
      ConsoleHelper(error);
      enqueueSnackbar(t("Something went wrong!"), { variant: "error" });
    } finally {
      setIsDeleting(false);
    }
  };

  const selectForDelete = (product) => {
    setIsDeleting(false);
    setProductToDelete(product);
  };

  const truncateProductName = (name) => {
    if (!name) return "";
    return name.length > 50 ? name.substring(0, 50) + "..." : name;
  };

  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="body">{t("Overview of all products")}</Typography>
        {/* <Select>
                <option value="all">All</option>
                <option value="active">Active</option>
            </Select> */}
        <Stack direction={"row"} spacing={1}>
          <TextField
            // label="Search"
            hiddenLabel
            variant="filled"
            placeholder={t("Search")}
            value={searchTerm}
            onChange={handleSearchChange}
            size="small"
            InputProps={{
              startAdornment: <SearchOutlined />,
              endAdornment:
                searchTerm.length > 0 ? (
                  <IconButton onClick={() => setSearchTerm("")}>
                    <Close />
                  </IconButton>
                ) : (
                  <IconButton disabled style={{ color: "transparent" }}>
                    <Close />
                  </IconButton>
                ),
            }}
          />

          {/* <Button variant='outlined' color='primary' startIcon={<LocationOnOutlined />} onClick={handleLocationsDialogOpen} >Manage locations</Button> */}
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddOutlined />}
            onClick={handleNewProductDialogOpen}
          >
            {t("New product")}
          </Button>
        </Stack>
      </Stack>

      <TablePagination
        rowsPerPageOptions={[15, 30, 60]}
        component="div"
        count={filteredProducts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("Rows per page")}
      />
      {isFetching ? (
        <LinearProgress sx={{ width: "100%", zIndex: 1000 }} />
      ) : (
        <LinearProgress sx={{ width: "0%" }} />
      )}
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ border: "1px solid lightGrey", my: "15px" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell style={{ width: '1%' }}></TableCell> */}
              <TableCell style={{ width: "5%" }}>{t("Photo")}</TableCell>
              <TableCell style={{ width: "33%" }} align="left">
                {t("Name")}
              </TableCell>
              <TableCell style={{ width: "20%" }} align="left">
                SKU
              </TableCell>
              <TableCell style={{ width: "20%" }} align="left">
                {t("Barcode")}
              </TableCell>
              <TableCell style={{ width: "10%" }} align="right">
                {t("Stock")}
              </TableCell>
              <TableCell style={{ width: "1%" }} align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isFetching && products.length == 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="body2">No products found</Typography>
                </TableCell>
              </TableRow>
            )}

            {filteredProducts
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <ProductTableRow
                  key={item.id}
                  index={index}
                  item={item}
                  openProductEditDialog={openProductEditDialog}
                  warehouse={warehouse}
                  selectForDelete={selectForDelete}
                  striped={index % 2 !== 0}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 60]}
        component="div"
        count={filteredProducts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("Rows per page")}
      />

      <AddNewProductDialog
        open={newProductDialogOpen}
        onClose={handleNewProductDialogClose}
        onNewProductAdded={fetchProducts}
        warehouse={warehouse}
      />
      <LocationsDialog
        open={locationsDialogOpen}
        onClose={handleLocationsDialogClose}
        warehouse={warehouse}
      />
      <EditProductDialog
        open={!!selectedProduct}
        onClose={() => setSelectedProduct(null)}
        onProductUpdated={() => console.log("")}
        product={selectedProduct}
        setProduct={setSelectedProduct}
        warehouse={warehouse}
      />
      <DeleteDialogWarningGeneric
        dialogOpen={!!productToDelete}
        handleDialogClose={() => setProductToDelete(null)}
        onDeleteAccept={handleDeleteProduct}
        isAccepting={isDeleting}
        title={t("Delete product")}
        warningText={
          <>
            {t("Are you sure you want to delete this product?")}
            <br />
            <strong>{truncateProductName(productToDelete?.name)}</strong>{" "}
          </>
        }
      />
    </>
  );
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
const ProductTableRow = ({
  item,
  openProductEditDialog,
  selectForDelete,
  index,
  striped,
}) => {
  const [hover, setHover] = useState(false);
  const [imageAnchorEl, setImageAnchorEl] = useState(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const imagePopover = Boolean(imageAnchorEl);
  const morePopover = Boolean(moreAnchorEl);
  const { enqueueSnackbar } = useSnackbar();

  const handleImagePopoverOpen = (event) => {
    setImageAnchorEl(event.currentTarget);
  };

  const handleImagePopoverClose = () => {
    setImageAnchorEl(null);
  };

  const handleMorePopoverOpen = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleMorePopoverClose = () => {
    setMoreAnchorEl(null);
  };

  const handleDelete = () => {
    if (item.quantityInStock > 0) {
      enqueueSnackbar(t("Empty stock before deleting the product"), {
        variant: "error",
      });
      return;
    }
    setMoreAnchorEl(null);
    selectForDelete(item);
  };

  const firstImage = item.productImages[0];
  const imageUrl = `${process.env.REACT_APP_API_URL}${firstImage?.imageUrl}`;
  return (
    <>
      <TableRow
        key={item.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          backgroundColor: striped ? "action.hover" : "inherit",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {/* <TableCell component="th" scope="row">
                    {index + 1}
                </TableCell> */}
        <TableCell align="left">
          {/* On hover show a popup */}

          {firstImage?.imageUrl && (
            <>
              <Box
                aria-owns={imagePopover ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handleImagePopoverOpen}
                onMouseLeave={handleImagePopoverClose}
              >
                <img
                  src={`${process.env.REACT_APP_API_URL}${firstImage?.imageUrl}`}
                  alt="Product image"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "5px",
                    border: "1px solid",
                    borderColor: "gray",
                  }}
                />
              </Box>
            </>
          )}
          {!firstImage?.imageUrl && (
            <Box
              sx={{
                height: "50px",
                width: "50px",
                borderRadius: "5px",
                border: "1px solid",
                borderColor: "lightGray",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image sx={{ color: "lightGray", fontSize: "35px" }} />
            </Box>
          )}
        </TableCell>
        <TableCell align="left">
          <Stack alignItems={"flex-start"}>
            <Typography
              variant="body2"
              sx={{
                cursor: "pointer",
                color: "primary.main",
                "&:hover": {
                  textDecoration: "underline",
                  color: "primary.dark",
                },
              }}
              onClick={() => openProductEditDialog(item)}
            >
              {item.name.length == 0 ? "_________" : item.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">{item.sku}</TableCell>
        <TableCell align="left">{item.barcode}</TableCell>

        {/* <TableCell align="left">Magazin / Atelier / Billy 1 / A1</TableCell> */}

        <TableCell align="right">{item.quantityInStock}</TableCell>
        <TableCell align="right">
          <IconButton onClick={handleMorePopoverOpen}>
            <MoreVert
              sx={{ color: hover || morePopover ? "inherit" : "transparent" }}
            />
          </IconButton>
          {/* // Popover for MoreVert */}
        </TableCell>
      </TableRow>
      <Popover
        id="mouse-over-popover"
        elevation={1}
        open={morePopover}
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleMorePopoverClose}
        role={undefined}
        transition
        disablePortal
      >
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={moreAnchorEl}
          open={morePopover}
          onClose={handleMorePopoverClose}
        >
          <MenuItem
            onClick={() => {
              handleMorePopoverClose();
              openProductEditDialog(item);
            }}
            disableRipple
          >
            <EditOutlined />
            {t("Edit")}
          </MenuItem>
          <MenuItem onClick={handleDelete} disableRipple>
            <DeleteOutline />
            {t("Delete")}
          </MenuItem>
        </StyledMenu>
      </Popover>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={imagePopover}
        anchorEl={imageAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleImagePopoverClose}
        disableRestoreFocus
      >
        <Box
          sx={{
            width: "250px",
            height: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <img
            src={imageUrl}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default AllProducts;
