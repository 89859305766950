// src/store/index.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import newEntryModalReducer from "./new-entry-modal";
import searchDialogReducers from "./search";
import dataTableReducers from "./data-table";
import newCustomerModalReducer from "./new-customer-modal";
import appReducer from "./reducers/appReducer";
import generalReducer from "./general";

const rootReducer = combineReducers({
  newEntryModal: newEntryModalReducer,
  auth: authReducer,
  searchDialog: searchDialogReducers,
  dataTable: dataTableReducers,
  newClientModal: newCustomerModalReducer,
  app: appReducer,
  general: generalReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable the SerializableStateInvariantMiddleware
    }),
  devTools: {
    serialize: {
      options: {
        depth: 2, // Limit the depth of the state tree
      },
    },
    stateSanitizer: (state) => ({
      ...state,
      dataTable: {
        ...state.dataTable,
        repairOrders:
          state.dataTable.repairOrders.length > 0
            ? "<<LONG ARRAY>>"
            : state.dataTable.repairOrders,
      },
    }),
    actionsDenylist: ["ACTION_TYPE_TO_IGNORE"], // Add action types to ignore
  },
});

export { rootReducer };
export default store;
