import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const SoftTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    fontSize: "inherit",
    fontFamily: "inherit",
    padding: 2,
    margin: 0,
    background: "transparent",
    border: "1px solid transparent",
    boxShadow: "none", // Remove any box shadow
    transition: "none", // Remove transition
  },
  "& .MuiInputBase-input": {
    padding: 0,
  },
  "& .MuiInput-underline:before, & .MuiInput-underline:after": {
    display: "none", // Remove default underline
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Remove outline border
  },
  // Remove hover effect
  "&:hover .MuiInputBase-root": {
    // borderBottom: "none",
    border: "1px solid black",
    backgroundColor: "#f5f5f5", // fade to a darker background
  },
  // Remove focus effect
  "& .MuiInputBase-root.Mui-focused": {
    // borderBottom: "none",
  },
  // Disabled state styling
  "& .MuiInputBase-root.Mui-disabled": {
    opacity: 0.5, // Faded text
    cursor: "not-allowed", // Disabled cursor
    // borderBottom: "none",
  },
}));

export default SoftTextField;
