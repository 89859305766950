import * as React from "react";
import {
  Stack,
  Box,
  TextField,
  Divider,
  Typography,
  Button,
  IconButton,
  Popover,
  InputAdornment,
  Grid,
  Skeleton,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import DeviceImage from "../../components/DeviceImage/DeviceImage";
import Section from "../../components/Section/Section";

//ICONS
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import {
  FaceOutlined,
  GroupOutlined,
  LocationCityOutlined,
  CloseRounded,
  Pattern,
  HighlightOffOutlined,
  BorderStyle,
} from "@mui/icons-material";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import CustomersSearch from "../../components/NewEntryStepper/CustomersSearch";
import { getCurrencySymbol } from "../../utils/currency";

import useUpdateRepairField from "hooks/mutations/orders/useUpdateRepairField";
import EditableText from "./EditableText";
import EditableNumber from "./EditableNumber";
import usePatchDeviceName from "hooks/mutations/orders/usePatchDeviceName";
import usePatchRepairCustomer from "hooks/mutations/orders/usePatchCustomer";
import DeleteDialogWarningGeneric from "common/DeleteDialogWarningGeneric";
import EditCustomerDialog from "./Dialogs/EditCustomerDialog";
import usePatchCustomerData from "hooks/mutations/orders/usePatchCustomerData";
import usePatchRepairDeviceCategory from "hooks/mutations/orders/usePatchRepairCategory";
import PatternPopover from "components/NewEntryStepper/PatternPopover";
import PatternLockDialog from "components/PatternDialog/PatternLockDialog";
import useRemovePattern from "hooks/mutations/orders/useRemovePattern";
import usePatchRepairPassword from "hooks/mutations/orders/usePatchRepairPassword";
import CategoryButton from "./CategoryButton";

const OrderEditGeneral = ({
  // repairOrder,
  repairOrderId,
  brand,
  model,
  malfunction,
  note,
  imei,
  estimatedServices,
  password,
  isPasswordPattern,
  deviceCategory,
  setRepairOrder,
  deviceType,
  deviceCategoryId,
  outsourcedFrom,
  customer,
  parent,
}) => {
  const currency = useSelector((state) => state.auth.currency);

  const { t } = useTranslation();

  const [editDevice, setEditDevice] = useState(false);
  const [editDeviceErrors, setEditDeviceErrors] = useState({});
  const [editDeviceData, setEditDeviceData] = useState({});

  const patchDeviceName = usePatchDeviceName();

  const updateRepairField = useUpdateRepairField();
  const patchCustomer = usePatchRepairCustomer({
    cacheKeys: [["repairOrder", repairOrderId]],
  });
  const patchRepairDeviceCategory = usePatchRepairDeviceCategory();

  const openDeviceEdit = () => {
    setEditDeviceData({ brand: brand, model: model });
    setEditDevice(true);
  };
  const handleDeviceInput = (key) => (event) => {
    setEditDeviceData((prevState) => {
      return { ...prevState, [key]: event.target.value };
    });
  };

  const validateNewDeviceInput = () => {
    let errors = {};
    if (!editDeviceData.brand) {
      errors.brand = "Brand is required";
    }
    if (!editDeviceData.model) {
      errors.model = "Model is required";
    }
    setEditDeviceErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleDeviceEdit = async () => {
    if (!validateNewDeviceInput()) {
      return;
    }
    if (brand === editDeviceData.brand && model === editDeviceData.model) {
      setEditDevice(false);
      return;
    }
    patchDeviceName.mutate({
      repairOrderId: repairOrderId,
      brand: editDeviceData.brand,
      model: editDeviceData.model,
    });

    setEditDevice(false);
  };

  const handleDeviceCategoryUpdate = (selectedCategory) => {
    patchRepairDeviceCategory.mutate({
      repairOrderId,
      deviceCategory: selectedCategory,
    });
  };

  const device = () => {
    if (editDevice)
      return (
        <Stack width={"50%"} spacing={2}>
          <TextField
            error={editDeviceErrors.brand ? true : false}
            helperText={editDeviceErrors.brand}
            sx={{ backgroundColor: "#F1F9FD" }}
            InputLabelProps={{ style: { fontWeight: 700 } }}
            fullWidth
            label="Brand"
            value={
              editDevice === 0
                ? brand
                  ? brand
                  : ""
                : editDeviceData.brand
                ? editDeviceData.brand
                : ""
            }
            onChange={handleDeviceInput("brand")}
          />
          <TextField
            error={editDeviceErrors.model ? true : false}
            helperText={editDeviceErrors.model}
            sx={{ backgroundColor: "#F1F9FD" }}
            InputLabelProps={{ style: { fontWeight: 700 } }}
            fullWidth
            label="Model"
            value={
              editDevice === 0
                ? model
                  ? model
                  : ""
                : editDeviceData.model
                ? editDeviceData.model
                : ""
            }
            onChange={handleDeviceInput("model")}
          />
          <Button variant={"outlined"} onClick={handleDeviceEdit}>
            Ok
          </Button>
        </Stack>
      );

    return (
      <>
        <Stack
          direction={{ xs: "row", sm: "row" }}
          spacing={1}
          sx={{ mb: "25px" }}
        >
          <DeviceImage brand={brand} />
          <Box>
            <Box display="flex">
              <Typography
                className="clickable"
                sx={{
                  py: "2px",
                  px: "5px",
                  border: "1px solid transparent",
                  ":hover": {
                    border: "1px solid",
                    borderColor: "darkGrey.main",
                    borderRadius: "5px",
                    py: "2px",
                    px: "5px",
                  },
                }}
                fontSize="1.3125rem"
                fontWeight={900}
                onClick={openDeviceEdit}
              >
                {brand} {model}
              </Typography>
            </Box>
            <CategoryButton
              deviceCategory={deviceCategory}
              onCategoryUpdate={handleDeviceCategoryUpdate}
            />
          </Box>
        </Stack>
      </>
    );
  };
  // /:repairOrderId/customer
  const onCustomerSelect = async (customer) => {
    patchCustomer.mutate({ repairOrderId, customer });
  };

  const outsourcedFromSection = () => {
    if (outsourcedFrom) {
      const partner = outsourcedFrom.organization;
      return (
        <>
          <Stack direction={"row"} alignItems={"center"}>
            <GroupOutlined color={"black"} sx={{ mr: "5px" }} />
            <Typography
              // mb='15px'
              fontWeight={600}
              fontSize="1"
              alignItems={"center"}
              display={"inline"}
            >
              {partner.name}
            </Typography>
          </Stack>
          {partner?.city?.length > 0 ? (
            <Stack direction={"row"} alignItems={"center"}>
              <LocationCityOutlined
                color={"black"}
                sx={{ fontSize: "1.2rem", mr: "5px" }}
              />
              <Typography mr={0}>{partner.city}</Typography>
            </Stack>
          ) : null}
        </>
      );
    }
  };

  const handleFieldUpdate = (key, newValue) => {
    updateRepairField.mutate({ id: repairOrderId, key, value: newValue });
  };
  const patchRepairPassword = usePatchRepairPassword();
  const handleRemovePattern = () => {
    patchRepairPassword.mutate({
      repairOrderId,
      password: "",
      isPasswordPattern: false,
    });
  };

  const updatePassword = ({ password, isPasswordPattern }) => {
    patchRepairPassword.mutate({
      repairOrderId,
      password,
      isPasswordPattern: isPasswordPattern,
    });
  };

  return (
    <>
      <Box backgroundColor={"white"} py={"15px"}>
        <Box className="sectionGroup">
          <Section variant="sm">
            <Grid container>
              <Grid item xs={12} sm={6}>
                {device()}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack
                  direction={"column"}
                  spacing={1}
                  justifyContent={"flex-start"}
                  alignItems={{ xs: "start", sm: "end" }}
                >
                  {outsourcedFrom ? null : (
                    <ClientInput
                      parent={parent}
                      repairOrderId={repairOrderId}
                      customer={customer}
                      onCustomerSelect={onCustomerSelect}
                      outsourcedFrom={outsourcedFrom}
                    />
                  )}
                  {outsourcedFromSection()}
                </Stack>
              </Grid>
            </Grid>
          </Section>
          <Section variant="sm">
            <Stack direction={"row"} spacing={2}>
              <EditableText
                label={t("Malfunction")}
                value={malfunction}
                onUpdate={(value) => handleFieldUpdate("malfunction", value)}
                multiline
                maxRows={4}
                minRows={4}
                disabled={outsourcedFrom !== null}
              />
              <EditableText
                label={t("Note")}
                value={note}
                onUpdate={(value) => handleFieldUpdate("note", value)}
                multiline
                maxRows={4}
                minRows={4}
                disabled={outsourcedFrom !== null}
              />
            </Stack>
          </Section>

          <Section variant="sm">
            <Stack direction="row" spacing={2}>
              <EditableText
                label={t("Imei")}
                value={imei}
                onUpdate={(value) => handleFieldUpdate("imei", value)}
              />
              <EditablePassword
                password={password}
                isPasswordPattern={isPasswordPattern}
                onChange={updatePassword}
                removePattern={handleRemovePattern}
                // setValue={setLocalPassword}
                // isPasswordPattern={isPasswordPattern}
                // setIsPasswordPattern={updatePasswordIsPasswordPattern}
                // onRemovePattern={handleRemovePattern}
                // size="medium"
              />
            </Stack>
          </Section>
          <Section variant="sm">
            <Stack direction="row" spacing={2}>
              <EditableNumber
                fullWidth
                label={t("Estimated services")}
                value={estimatedServices ? estimatedServices : ""}
                onUpdate={(value) =>
                  handleFieldUpdate("estimatedServices", value)
                }
                placeholder={"0.00"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencySymbol(currency)}
                    </InputAdornment>
                  ),
                }}
                onlyFloat
                onlyPositive
                withinRange={[0, 999999]}
              />
            </Stack>
          </Section>
        </Box>
      </Box>
    </>
  );
};

const ClientInput = ({
  repairOrderId,
  customer,
  onCustomerSelect,
  outsourcedFrom,
  parent,
}) => {
  const { t } = useTranslation();
  const patchCustomerData = usePatchCustomerData({
    cacheKeys: [["repairOrder", repairOrderId]],
  });

  const [openDeleteCustomerDialog, setOpenDeleteCustomerDialog] =
    useState(false);
  const [editCustomer, setEditCustomer] = useState(false);
  const onOpenDeleteCustomerDialog = () => {
    setOpenDeleteCustomerDialog(true);
  };
  const handleNewCustomerData = ({ customer, key, value }) => {
    console.log("patch", customer, key, value);
    patchCustomerData.mutate({ customerId: customer.id, key, value });
  };

  if (!customer) return <CustomersSearch onOptionSelect={onCustomerSelect} />;
  return (
    <>
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <Button
          color="black"
          variant="text"
          startIcon={<FaceOutlined />}
          onClick={() => setEditCustomer(true)}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "230px",
            }}
          >
            {customer?.name}
          </Typography>
        </Button>
        {parent ? null : (
          <IconButton size="small" onClick={onOpenDeleteCustomerDialog}>
            <CloseRounded />
          </IconButton>
        )}
      </Stack>
      {customer?.phone?.length > 0 ? (
        <Stack direction={"row"} alignItems={"center"}>
          <PhoneInTalkOutlinedIcon
            color={"black"}
            sx={{ fontSize: "1.2rem", mr: "5px" }}
          />
          <Typography mr={0}>{customer.phone}</Typography>
        </Stack>
      ) : null}
      {customer?.email?.length > 0 ? (
        <Stack direction={"row"} alignItems={"center"}>
          <EmailOutlinedIcon
            color={"black"}
            sx={{ fontSize: "1.2rem", mr: "5px" }}
          />
          <Typography mr={0}>{customer.email}</Typography>
        </Stack>
      ) : null}
      {outsourcedFrom == null ? (
        <EditCustomerDialog
          dialogOpen={editCustomer}
          customer={customer}
          handleDialogClose={() => setEditCustomer(false)}
          onEdit={handleNewCustomerData}
        />
      ) : null}
      <DeleteDialogWarningGeneric
        dialogOpen={openDeleteCustomerDialog}
        handleDialogClose={() => setOpenDeleteCustomerDialog(false)}
        onDeleteAccept={() => {
          setOpenDeleteCustomerDialog(false);
          onCustomerSelect(null);
        }}
        title={t("dialogs.removeCustomer.title")}
        warningText={t("dialogs.removeCustomer.message")}
        confirmText={t("dialogs.removeCustomer.confirm")}
      />
    </>
  );
};

const EditablePassword = ({
  password,
  isPasswordPattern,
  removePattern,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = (value) => {
    setOpen(false);
    if (value.length < 3) {
      return;
    }
    onChange({
      password: value,
      isPasswordPattern: true,
    });
  };

  const handleRemovePattern = () => {
    removePattern();
  };

  const handleChange = (value) => {
    onChange({ password: value, isPasswordPattern: false });
  };

  return (
    <>
      <EditableText
        value={isPasswordPattern ? "" : password}
        onUpdate={handleChange}
        label={` ${isPasswordPattern ? t("Pattern") : t("Password")}`}
        disabled={isPasswordPattern}
        sx={{
          "& .MuiInputBase-root.Mui-disabled": {
            color: "inherit", // Override the disabled text color
            backgroundColor: theme.palette.lightBlue.main,
          },
          "& .MuiInputBase-input.Mui-disabled": {
            color: "inherit", // Override the disabled input text color
            WebkitTextFillColor: "inherit", // Override the disabled input text color for Safari
          },

          "& .MuiFilledInput-root.Mui-disabled:before": {
            borderBottomStyle: "none", // Override the border-bottom-style for disabled state
          },
        }}
        InputProps={{
          startAdornment: (
            <>
              {isPasswordPattern && (
                <InputAdornment
                  position="start"
                  onMouseEnter={(e) => {
                    if (isPasswordPattern) {
                      setAnchorEl(e.currentTarget);
                    }
                  }}
                  onMouseLeave={() => {
                    setAnchorEl(null);
                  }}
                >
                  <Pattern color="primary" />
                </InputAdornment>
              )}
              {isPasswordPattern && (
                <InputAdornment position="start">
                  {isPasswordPattern ? password : ""}
                </InputAdornment>
              )}
            </>
          ),
          endAdornment: (
            <>
              {!isPasswordPattern && (
                <InputAdornment position="end">
                  <IconButton
                    color={isPasswordPattern ? "primary" : "inherit"}
                    onClick={handleClickOpen}
                  >
                    <Pattern />
                  </IconButton>
                </InputAdornment>
              )}
              {isPasswordPattern && (
                <InputAdornment position="end">
                  <IconButton onClick={handleRemovePattern}>
                    <HighlightOffOutlined />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
      <PatternPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        value={password}
        valueSetUsingPattern={isPasswordPattern}
      />

      <PatternLockDialog
        open={open}
        onClose={handleClose}
        onAction={handleAction}
      />
    </>
  );
};

export default React.memo(OrderEditGeneral);
