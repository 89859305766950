import * as React from "react";
import {
  Box,
  Divider,
  Typography,
  Stack,
  Chip,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import { repairResolutions } from "../../../utils/repair-statuses-helpers";
//OUR COMP
import ServicesContent from "../ServicesContent";

// import "../service-panel.css";
//ICONS

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useTranslation } from "react-i18next";

import { useState } from "react";
import NewServiceDialog from "../Dialogs/NewServiceDialog";
import PanelHeader from "./PanelHeader";
import Totals from "./Totals";
import Dates from "./Dates";
import ResolutionsSection from "./ResolutionsSection";
import OrderParentingDialog from "../Dialogs/OrderParentingDialog";
import RefundDialog from "../Dialogs/RefundDialog";
import {
  HandymanOutlined,
  Inventory2Outlined,
  MemoryOutlined,
  PaymentsOutlined,
  Receipt,
} from "@mui/icons-material";
import { blueGrey } from "@mui/material/colors";
import ExternalServiceContent from "../ExternalServiceContent";
import FollowUpDialog from "../Dialogs/FollowUpDialog";

const LeftPanel = ({
  repairOrderId,
  services,
  repairOrderParts,
  repairPayments,
  parent,
  outsourcedTo,
  outsourcedFrom,
  children,
  organizationId,
  organizationCounter,
  resolutionAt,
  resolutionType,
  deliveredAt,
  createdAt,
  updatedAt,
  levelOfAttention,
  repairOrderStatus,
  repairOrderOutsourceRequest,
  invoice,
}) => {
  const { t } = useTranslation();
  const [tabsValue, setTabsValue] = React.useState(0);
  const [refundDialogOpen, setRefundDialogOpen] = React.useState(false);
  const [addServiceOpen, setAddServiceOpen] = React.useState(false);
  const hanldeServiceDialogOpen = (tabValue) => {
    setAddServiceOpen(true);
    setTabsValue(tabValue);
  };
  const handleServiceDialogClose = React.useCallback(() => {
    setAddServiceOpen(false);
  }, []);

  const handleRefundClick = () => {
    setRefundDialogOpen(true);
  };

  const handleRefundDialogClose = () => {
    setRefundDialogOpen(false);
  };

  return (
    <>
      <Stack className="servicePanel" sx={{ borderColor: "lightGrey.main" }}>
        <PanelHeader
          repairOrderId={repairOrderId}
          parent={parent}
          children={children}
          outsourcedFrom={outsourcedFrom}
          outsourcedTo={outsourcedTo}
          deliveredAt={deliveredAt}
          organizationId={organizationId}
          organizationCounter={organizationCounter}
          levelOfAttention={levelOfAttention}
          repairOrderStatus={repairOrderStatus}
          resolutionType={resolutionType}
          services={services}
        />
        {outsourcedTo ? (
          <Box px={"25px"}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Tooltip
                enterDelay={20}
                title={`${t(
                  "Here you manage the services offered by you to your customer. Your partner is not able to see or interact with these."
                )}`}
              >
                <InfoOutlinedIcon color={"darkGrey"} />
              </Tooltip>
              <Typography variant={"h6"}>
                {t("Services offered to customer")}
              </Typography>
            </Stack>
          </Box>
        ) : null}
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          flexWrap={"wrap"}
          mb={"15px"}
          px="25px"
        >
          <Dates
            createdAt={createdAt}
            updatedAt={updatedAt}
            deliveredAt={deliveredAt}
          />
          <Box flexGrow={1}></Box>
          <Stack direction={"row"} spacing={"5px"}>
            {parent?.id ? (
              <>
                <Tooltip title={t("editRepair.refund")}>
                  <IconButton
                    onClick={handleRefundClick}
                    sx={{ color: blueGrey[800] }}
                  >
                    <UTurnLeftIcon sx={{ transform: "rotate(-90deg)" }} />
                  </IconButton>
                </Tooltip>
                {/* Vertical divider */}
                <Divider orientation="vertical" flexItem />
              </>
            ) : null}
            <Tooltip title={t("editRepair.service")}>
              <IconButton
                onClick={() => hanldeServiceDialogOpen(0)}
                sx={{ color: blueGrey[800] }}
              >
                <HandymanOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("editRepair.part")}>
              <IconButton
                onClick={() => hanldeServiceDialogOpen(1)}
                sx={{ color: blueGrey[800] }}
              >
                <MemoryOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("editRepair.payment")}>
              <IconButton
                onClick={() => hanldeServiceDialogOpen(2)}
                sx={{ color: blueGrey[800] }}
              >
                <PaymentsOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack direction={"row"} spacing={2} px={"25px"} mb={"15px"}>
          {invoice ? (
            <Chip
              icon={<Receipt />}
              label={`${t("leftPanel.invoiceReference")}: #${invoice.refId}`}
            />
          ) : null}
        </Stack>

        <Box px={"25px"} flex={1} mb={"15px"}>
          <ServicesContent
            repairOrderId={repairOrderId}
            services={services}
            repairOrderParts={repairOrderParts}
            repairPayments={repairPayments}
          />
        </Box>
      </Stack>
      <Divider />
      <Totals
        services={services}
        repairOrderParts={repairOrderParts}
        repairPayments={repairPayments}
      />
      <Divider />
      <ResolutionsSection
        repairOrderId={repairOrderId}
        outsourcedTo={outsourcedTo}
        outsourcedFrom={outsourcedFrom}
        deliveredAt={deliveredAt}
        resolutionType={resolutionType}
        children={children}
        resolutionAt={resolutionAt}
      />

      <Divider sx={{ mb: "15px" }} />
      <Box>
        <ExternalServiceContent
          repairOrderOutsourceRequest={repairOrderOutsourceRequest}
          repairOrderId={repairOrderId}
          outsourcedTo={outsourcedTo}
          outsourcedFrom={outsourcedFrom}
          deliveredAt={deliveredAt}
          resolutionType={resolutionType}
        />
      </Box>

      <NewServiceDialog
        tabsValue={tabsValue}
        setTabsValue={setTabsValue}
        repairOrderId={repairOrderId}
        resolutionType={resolutionType}
        services={services}
        repairPayments={repairPayments}
        addServiceOpen={addServiceOpen}
        handleAddServiceClose={handleServiceDialogClose}
      />

      <RefundDialog
        services={services}
        open={refundDialogOpen}
        onClose={handleRefundDialogClose}
        repairOrderId={repairOrderId}
        handleRefundDialogClose={handleRefundDialogClose}
      />
    </>
  );
};

export default React.memo(LeftPanel);
