import React, { memo } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";

dayjs.extend(relativeTime);

const UpdatedAtDate = ({ updatedAt }) => {
  const { t } = useTranslation();

  if (!updatedAt) return null;

  const formattedUpdate = dayjs(updatedAt).format("DD MMM. YYYY HH:mm");
  const relativeUpdate = dayjs(updatedAt).fromNow(true);

  return (
    <Tooltip
      enterDelay={20}
      placement="top"
      title={`${t("Last update")}: ${formattedUpdate}`}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={0.5}
        fontSize="0.75rem"
        color="darkGrey.main"
      >
        <UpdateOutlinedIcon sx={{ fontSize: "1rem" }} />
        <Typography fontSize="0.75rem" fontWeight="600">
          {relativeUpdate}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default memo(UpdatedAtDate);
