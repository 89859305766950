import * as React from "react";
import { TextField } from "@mui/material";
import { useState } from "react";

const ReactiveTextField = ({
  initialState = "",
  onBlurCallback,
  variant = "filled",
  type = "text",
  numberType, // "integer" | "float"
  ...other
}) => {
  const onInputFocus = () => {
    // if (editMode === 1 || editMode === 2) {
    //   setEditMode(1);
    //   setValue(value);
    //   return;
    // }
    setEditMode(true);
    setValue(initialState);
  };

  const handleChange = (event) => {
    let inputValue = event.target.value;
    console.log("Input value: ", inputValue);
    console.log("Type: ", type);

    if (type === "number") {
      if (numberType === "float") {
        console.log("IS FLOAT");
        console.log("editMode: ", editMode);
        if (inputValue === "") {
          setValue("");
        } else {
          // Extract the valid float portion from the input
          const floatMatch = inputValue.match(/^-?\d+(\.\d+)?/);
          if (floatMatch) {
            setValue(floatMatch[0]);
          } else {
            setValue("");
          }
        }
      } else if (numberType === "integer") {
        // Allow only digits and an optional negative sign
        const intMatch = inputValue.match(/^-?\d+$/);
        if (intMatch) {
          setValue(intMatch[0]);
        } else {
          setValue("");
        }
      } else {
        // General number (both int and float)
        if (inputValue === "") {
          setValue("");
        } else {
          const generalMatch = inputValue.match(/^-?\d*\.?\d*/);
          if (generalMatch) {
            setValue(generalMatch[0]);
          } else {
            setValue("");
          }
        }
      }
    } else {
      // For text or other types, allow all input
      setValue(inputValue);
    }
  };

  const onBlur = () => {
    if (initialState != null && initialState.trim() == value?.trim()) {
      setEditMode(false);
      return;
    }

    if (typeof value !== "number") {
      onBlurCallback(value?.trim());
    }
    setEditMode(false);
  };

  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(initialState);

  React.useEffect(() => {
    console.log("value: ", value);
    console.log("editMode: ", editMode);
  }, [value, editMode]);

  const mergedValue = editMode ? value : initialState;

  return (
    <TextField
      type={type || "text"}
      onFocus={onInputFocus}
      onBlur={onBlur}
      value={mergedValue}
      onChange={handleChange}
      variant={variant || "filled"}
      {...other}
    />
  );
};

export default ReactiveTextField;
