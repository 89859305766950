import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { patchCustomerData } from "api/repair-orders";

const usePatchCustomerData = ({ cacheKeys }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(patchCustomerData, {
    onMutate: async ({ customerId, key, value }) => {
      if (cacheKeys.length === 0) return; // Skip React Query updates if no cache keys exist

      await Promise.all(
        cacheKeys.map((cacheKey) =>
          queryClient.cancelQueries({ queryKey: cacheKey })
        )
      );

      // Store snapshots of previous data
      const previousData = cacheKeys.reduce((acc, cacheKey) => {
        acc[cacheKey] = queryClient.getQueryData(cacheKey);
        return acc;
      }, {});

      // Optimistically update each relevant cache entry
      cacheKeys.forEach((cacheKey) => {
        queryClient.setQueryData(cacheKey, (old) => {
          if (!old) return old;
          return {
            ...old,
            customer: {
              ...old.customer,
              [key]: value, // 🔹 Correctly updating the right customer field
            },
          };
        });
      });

      return { previousData };
    },
    onError: (error, variables, context) => {
      // Rollback to previous data on error
      if (context?.previousData) {
        Object.entries(context.previousData).forEach(([cacheKey, data]) => {
          queryClient.setQueryData(cacheKey, data);
        });
      }
      enqueueSnackbar("Failed to update customer", { variant: "error" });
    },
    onSuccess: () => {
      enqueueSnackbar("Customer updated successfully", {
        variant: "success",
      });
    },
    onSettled: (data, error, variables) => {
      cacheKeys.forEach((cacheKey) => queryClient.invalidateQueries(cacheKey));
    },
  });
};

export default usePatchCustomerData;
