import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Chip,
  Box,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import axios from "axios-config";
import ConsoleHelper from "utils/ConsoleHelper";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const UserFilter = ({ selectedUsers, onFilterChange }) => {
  const { t } = useTranslation();
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  // get auth user
  const auth = useSelector((state) => state.auth);

  // set current user
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    if (auth) {
      const user = {
        id: auth.userId,
        name: auth.userName,
        email: auth.email,
      };
      setCurrentUser(user);
    }
  }, [auth]);

  // Fetch users when Autocomplete opens and no users have been loaded yet
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/organizations/users");
      if (response.status === 200 && Array.isArray(response.data)) {
        setAllUsers(response.data);
      } else {
        throw new Error("Unexpected API response");
      }
    } catch (err) {
      ConsoleHelper(err, "error");
      setError("Failed to load users");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && allUsers.length === 0 && !error) {
      fetchUsers();
    }
  }, [open, allUsers.length, error]);

  const filteredUsers = allUsers.filter(
    (user) => !selectedUsers.some((selected) => selected.id === user.id)
  );

  const handleChange = (event, value) => {
    if (onFilterChange) {
      onFilterChange(value);
    }
  };

  const handleAssignToMe = () => {
    if (onFilterChange && currentUser) {
      onFilterChange([currentUser]);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Button
        variant="outlined"
        onClick={handleAssignToMe}
        disabled={!currentUser}
      >
        {t("filters.assignedToMe")}
      </Button>
      <Autocomplete
        variant="filled"
        // hiddenLabel
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        loading={loading}
        multiple
        options={filteredUsers}
        value={selectedUsers}
        onChange={handleChange}
        getOptionLabel={(option) => option.name || ""}
        loadingText={t("general.loading")}
        noOptionsText={error ? error : "No users found"}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option.id}
              label={option.name}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            hiddenLabel
            placeholder={t("filters.filterAssigneesPlaceholder")}
            error={Boolean(error)}
            helperText={error || ""}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

UserFilter.propTypes = {
  selectedUsers: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default UserFilter;
