import * as React from "react";
import { Box, Stack } from "@mui/material";

const ToolBarHelper = (props) => {
  return (
    <>
      <div className="headerPush"></div>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        py={"10px"}
        style={{ backgroundColor: "white", zIndex: 250, minHeight: "60px" }}
        borderBottom={"1px solid darkGrey"}
        px={"10px"}
      >
        {props.children}
      </Stack>
    </>
  );
};

export default ToolBarHelper;
