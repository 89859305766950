import * as React from "react";

import { Box } from "@mui/material";
import ServicesEmptyPlaceholder from "./ServicesEmptyPlaceholder";
import ServicesList from "./ServicesList";

// ICONS
import PaymentItemList from "./PaymentItemList";
import PartItemList from "./PartItemList";
import { useTranslation } from "react-i18next";
import {
  getAcceptedAndRefundedServices,
  getAcceptedServices,
  getDeclinedAndProposedServices,
  getDeclinedServices,
  getProposedServices,
  getRefundedServices,
  processService,
} from "utils/service-helpers";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../utils/currency";
import { useUserPermissions } from "contexts/UserPermissions";

import useDeleteService from "hooks/mutations/orders/useDeleteService";
import useDeletePart from "hooks/mutations/orders/useDeletePart";
import useDeletePayment from "hooks/mutations/orders/useDeletePayment";
import useAcceptService from "hooks/mutations/orders/useAcceptService";
import ServiceProposed from "./LeftPanel/ServiceProposed";
import useDeclineService from "hooks/mutations/orders/useDeclineService";
import ServiceDeclined from "./LeftPanel/ServiceDeclined";
import useRemoveAcceptService from "hooks/mutations/orders/useRemoveAcceptService";
import ServiceAccepted from "./LeftPanel/ServiceAccepted";
import EditServiceDialog from "./Dialogs/EditServiceDialog";
import ServiceRefunded from "./LeftPanel/ServiceRefunded";

const ServicesContent = React.forwardRef(
  (
    {
      repairOrderId,
      services,
      repairPayments,
      repairOrderParts,
      //   outsourceServices,
      //   setOutsourceServices,
      //   repairOrderParts,
      //   addServiceButton,
      //   setServices,
      //   setRepairOrder,
      //   setRepairPayments,
      //   setRepairOrderParts,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const currency = useSelector((state) => state.auth.currency);
    const deletePartFromRepairOrder = useDeletePart();
    const [selectedServiceId, setSelectedServiceId] = React.useState(null);

    const userPermissions = useUserPermissions();
    const deleteService = useDeleteService();
    const deletePayment = useDeletePayment();
    const acceptService = useAcceptService();
    const declineService = useDeclineService();
    const removeAcceptance = useRemoveAcceptService();

    // React.useEffect(() => {
    //   if (userPermissions?.softLockedUser) {
    //     console.log("setRepairOrderParts not implemented");
    //     // setRepairOrderParts([]);
    //   }
    // }, [userPermissions]);

    const onServiceDelete = (removedService) => {
      deleteService.mutate({
        repairOrderId: repairOrderId,
        serviceId: removedService.id,
      });
    };
    const onServiceAccept = (service) => {
      acceptService.mutate({
        repairOrderId: repairOrderId,
        serviceId: service.id,
      });
    };

    const onServiceDecline = (service) => {
      declineService.mutate({
        repairOrderId: repairOrderId,
        serviceId: service.id,
      });
    };

    const acceptedServices = getAcceptedServices(services);
    const refundedServices = getRefundedServices(services);

    const acceptedAndRefundedServices =
      getAcceptedAndRefundedServices(services);
    const acceptedAndRefundedServicesProcessed =
      acceptedAndRefundedServices.reduce((acc, service) => {
        const processed = processService(service);
        return (acc +=
          processed.unitPrice * processed.quantity - processed.discount);
      }, 0);

    const declinedServicesFiltered = getDeclinedServices(services);
    const proposedServices = getProposedServices(services);

    const declinedAndProposedServices =
      getDeclinedAndProposedServices(services);
    const declinedAndProposedServicesProcessed =
      declinedAndProposedServices.reduce((acc, service) => {
        const processed = processService(service);
        return (acc +=
          processed.unitPrice * processed.quantity - processed.discount);
      }, 0);

    // const repairPaymentsProcessed = repairPayments.reduce((acc, payment) => {
    //   return (acc += parseFloat(payment.amount));
    // }, 0);
    const onPartDelete = (removedPart) => {
      deletePartFromRepairOrder.mutate({
        repairOrderId: repairOrderId,
        partId: removedPart.id,
      });
    };

    const onPaymentDelete = (removedPayment) => {
      deletePayment.mutate({
        repairOrderId: repairOrderId,
        paymentId: removedPayment.id,
      });
    };

    const handleServiceDialogOpen = (targetService) => {
      // console.log("service", service);
      // setAddServiceOpen(true);
      setSelectedServiceId(targetService.id);
    };

    const handleRemoveAccept = (service) => {
      removeAcceptance.mutate({
        repairOrderId: repairOrderId,
        serviceId: service.id,
      });
    };
    const handleEditServiceDialogClose = React.useCallback(() => {
      setSelectedServiceId(null);
    }, []);

    return (
      <>
        {!services?.length &&
        !repairPayments?.length &&
        !repairOrderParts?.length ? (
          <>
            <ServicesEmptyPlaceholder />
          </>
        ) : (
          <Box>
            <Box>
              {repairPayments?.length > 0 ? (
                <ServicesList
                  title={t("Payments")}
                  servicesListItems={
                    <>
                      {repairPayments?.map((payment) => (
                        <PaymentItemList
                          key={payment.id}
                          item={payment}
                          onDelete={onPaymentDelete}
                          // setPayments={setRepairPayments}
                        />
                      ))}
                    </>
                  }
                  sx={{ mb: "5px" }}
                />
              ) : null}

              {declinedAndProposedServices.length > 0 ? (
                <ServicesList
                  title={`${t("Proposed services")}`}
                  additionalInfo={`${formatCurrency(
                    declinedAndProposedServicesProcessed,
                    currency
                  )}`}
                  servicesListItems={[
                    ...proposedServices.map((service) => (
                      <ServiceProposed
                        key={service.id}
                        service={service}
                        onDelete={onServiceDelete}
                        onClick={handleServiceDialogOpen}
                        handleAcceptService={onServiceAccept}
                        handleDeclineService={onServiceDecline}
                      />
                    )),
                    ...declinedServicesFiltered.map((service) => (
                      <ServiceDeclined
                        key={service.id}
                        service={service}
                        onDelete={onServiceDelete}
                        onClick={handleServiceDialogOpen}
                        onRevert={handleRemoveAccept}
                      />
                    )),
                  ]}
                  sx={{ mb: "5px" }}
                />
              ) : null}

              {acceptedServices.length > 0 || refundedServices.length > 0 ? (
                <ServicesList
                  title={`${t("Confirmed services")}`}
                  additionalInfo={`${formatCurrency(
                    acceptedAndRefundedServicesProcessed,
                    currency
                  )}`}
                  servicesListItems={[
                    ...acceptedServices.map((service) => (
                      // <></>
                      <ServiceAccepted
                        key={service.id}
                        service={service}
                        onServiceDelete={onServiceDelete}
                        onRevert={handleRemoveAccept}
                        onDelete={onServiceDelete}
                        onClick={handleServiceDialogOpen}
                      />
                    )),
                    ...refundedServices.map((service) => (
                      <ServiceRefunded
                        key={service.id}
                        service={service}
                        onServiceDelete={onServiceDelete}
                        onRevert={handleRemoveAccept}
                        onDelete={onServiceDelete}
                      />
                    )),
                  ]}
                  sx={{ mb: "5px" }}
                />
              ) : null}

              {repairOrderParts?.length > 0 ? (
                <ServicesList
                  title={t("Parts")}
                  servicesListItems={repairOrderParts?.map((part) => (
                    <PartItemList
                      key={part.id}
                      item={part}
                      onServiceDelete={onPartDelete}
                    />
                  ))}
                  sx={{ mb: "5px" }}
                />
              ) : null}
            </Box>
          </Box>
        )}
        <EditServiceDialog
          services={services}
          selectedServiceId={selectedServiceId}
          open={Boolean(selectedServiceId)}
          onClose={handleEditServiceDialogClose}
        />
      </>
    );
  }
);

export default React.memo(ServicesContent);
