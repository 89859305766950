import React from "react";
import {
  Stack,
  IconButton,
  TextField,
  Avatar,
  CircularProgress,
  Badge,
  Box,
} from "@mui/material";
import { Send as SendIcon, ImageOutlined, Close } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import NewFeatureTooltip from "components/NewFeatureTooltip/NewFeatureTooltip";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
  color: "black",
});

const CommentForm = ({
  inputValue,
  setInputValue,
  handleComment,
  handleFileUpload,
  uploadedImages,
  handleFileDelete,
  filesToUpload,
  isUploading,
  uploadProgress,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <form>
      <Stack
        sx={{
          border: "1px solid #ccc",
          borderRadius: "5px",
          padding: "8px",
          width: "100%",
          backgroundColor: theme.palette.lightBlue.main,
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            px: "50px",
            overflow: "auto",
            py:
              uploadedImages.length > 0 || filesToUpload.length > 0
                ? "15px"
                : "0px",
          }}
          gap={"10px"}
        >
          {uploadedImages.map((file, index) => (
            <Box
              key={index}
              sx={{ position: "relative", width: 50, height: 50 }}
            >
              <Avatar
                variant="rounded"
                sx={{ width: 50, height: 50 }}
                src={`${process.env.REACT_APP_IMAGES_URL}${file.filePath}`}
              />
              <Badge
                overlap="circular"
                badgeContent={
                  <IconButton
                    size="small"
                    sx={{
                      color: "white",
                      backgroundColor: "rgba(0, 0, 0, 0.7) !important",
                    }}
                    onClick={() => handleFileDelete(file.id)}
                  >
                    <Close sx={{ fontSize: "18px" }} />
                  </IconButton>
                }
                size="30px"
                sx={{ position: "absolute", top: 0, left: "100%", zIndex: 1 }}
              />
            </Box>
          ))}
          {filesToUpload.map((file, index) => (
            <Box
              key={index}
              sx={{ position: "relative", width: 50, height: 50 }}
            >
              <Avatar
                variant="rounded"
                sx={{ width: 50, height: 50 }}
                src={URL.createObjectURL(file)}
              />
              <CircularProgress
                size="30px"
                sx={{
                  position: "absolute",
                  top: "20%",
                  left: "20%",
                  zIndex: 1,
                  color: "rgba(255, 255, 255, 0.7)",
                }}
              />
            </Box>
          ))}
        </Stack>
        <Stack direction={"row"} spacing={2} alignItems="flex-end">
          <NewFeatureTooltip
            hintName="attach-image-to-comment"
            title={t("newFeatureTooltip.attachImageToComment.title")}
            description={t(
              "newFeatureTooltip.attachImageToComment.description"
            )}
            badge={t("newFeatureTooltip.newBadge")}
          >
            <IconButton
              component="label"
              role={undefined}
              tabIndex={-1}
              size="small"
            >
              <ImageOutlined fontSize="small" />
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => {
                  handleFileUpload(e);
                  e.target.value = null;
                }}
                multiple={true}
              />
            </IconButton>
          </NewFeatureTooltip>
          <TextField
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            autoComplete="off"
            multiline
            variant="standard"
            placeholder={t("Leave a comment here...")}
            fullWidth
            InputProps={{ disableUnderline: true }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleComment(e);
              }
            }}
            sx={{
              "& .MuiInputBase-root": {
                padding: 0,
                paddingLeft: "0px",
                paddingBottom: "4px",
                color: theme.palette.black.main,
              },
            }}
          />
          <IconButton
            color="primary"
            aria-label="add"
            onClick={handleComment}
            sx={{
              boxShadow: "none",
              backgroundColor: "primary.main",
              color: "white.main",
              "&:hover": { backgroundColor: "primary.dark" },
            }}
            variant="contained"
          >
            <SendIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        </Stack>
      </Stack>
    </form>
  );
};

export default React.memo(CommentForm);
