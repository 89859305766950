import * as React from "react";
import { Box, IconButton, Stack } from "@mui/material";

import Sorts from "./Sorts";
import "./data-table.css";
import { useTheme } from "@mui/material/styles";

import { useState, useEffect } from "react";
import ToolBarHelper from "../ToolBarHelper/ToolBarHelper";

import WindowTable from "./WindowTable";

//ICONS
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";

const DataTable = ({
  searchFieldsBucket,
  searchInFields,
  setSearchInFields,
}) => {
  const [ableToSelect, setAbleToSelect] = useState(false);
  const theme = useTheme();

  const handleEnableSelection = () => {
    setAbleToSelect(!ableToSelect);
  };

  return (
    <>
      <Box sx={{ flex: 1, maxWidth: "100%", height: "100%" }}>
        <ToolBarHelper>
          <Stack direction="row" alignItems="center" gap={1} flexWrap={"wrap"}>
            <IconButton
              color="black"
              aria-label="enable-selection"
              onClick={handleEnableSelection}
            >
              {ableToSelect ? <LibraryAddCheckIcon /> : <FilterNoneIcon />}
            </IconButton>
            <Sorts
              searchInFields={searchInFields}
              setSearchInFields={setSearchInFields}
              searchFieldsBucket={searchFieldsBucket}
            />
          </Stack>
        </ToolBarHelper>

        <Box px={"10px"}>
          <WindowTable
            ableToSelect={ableToSelect}
            searchInFields={searchInFields}
            setAbleToSelect={setAbleToSelect}
          />
        </Box>
      </Box>
    </>
  );
};

export default DataTable;
