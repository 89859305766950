import React from "react";
import { ListItem, Box } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { formatDistance } from "date-fns";
import StyledAvatar from "../../../components/StyledAvatar/StyledAvatar";
import { Stack } from "@mui/system";
import { ImageOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function CommentTextShort({ data, auth, minimal }) {
  let { t } = useTranslation();

  //TODO: centralize this function to be available globally for Avatars
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const minimalStyle = minimal
    ? {
        avatar: { width: "28px", height: "28px", fontSize: "15px" },
        listItem: { margin: "0px" },
        listItemAvatar: { minWidth: "38px" },
      }
    : {
        listItem: { margin: "10px" },
      };
  return (
    <>
      {data && auth ? (
        <ListItem
          alignItems="flex-start"
          sx={{
            ...minimalStyle.listItem,
            backgroundColor: "lightBlue.main",
            marginLeft: data.user.id == auth.userId ? "auto" : "0",
            width: "fit-content",
            paddingRight: "10px",
            borderRadius: "5px",
            maxWidth: "100%",
          }}
          key={data.id}
        >
          <ListItemText
            align={data.user.id === auth.userId ? "right" : "left"}
            primary={
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {data.user.name}
              </Typography>
            }
            secondaryTypographyProps={{ component: "span" }}
            secondary={
              data?.body?.length > 0 ? (
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                    paddingX: "5px",
                  }}
                  component="span"
                  variant="body2"
                  color="darkBlue.main"
                >
                  {data.body}
                </Typography>
              ) : (
                <Stack direction="row" alignItems={"center"} spacing={1}>
                  <ImageOutlined />
                  <Typography variant="caption" color="darkBlue.main">
                    {t("comments.attachment")}
                  </Typography>
                </Stack>
              )
            }
          />
        </ListItem>
      ) : null}
    </>
  );
}

export default CommentTextShort;
