import * as React from "react";
import { Box, IconButton, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";

//Icons
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";

const BaseItemList = (props) => {
  const {
    mainStack,
    footerStack,
    itemIcon,
    backgroundColor,
    onDeleteButtonClick,
    onClick,
    sx,
    ...other
  } = props;

  return (
    <li>
      <Box
        backgroundColor={backgroundColor ? backgroundColor : "lightBlue.main"}
        mb={"10px"}
        py={2}
        px={1.5}
        borderRadius={"8px"}
        border={"solid 1px"}
        borderColor={grey[500]}
        width={"100%"}
        sx={{
          ...sx,
          "&:hover": {
            border: "solid 1px",
            borderColor: onClick ? "black" : grey[500],
            cursor: onClick ? "pointer" : "default",
          },
        }}
        onClick={onClick ? onClick : null}
        {...other}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          {itemIcon}
          {mainStack}
          {onDeleteButtonClick ? (
            <IconButton
              onClick={onDeleteButtonClick}
              aria-label="delete"
              size="small"
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          ) : null}
        </Stack>
        <Stack
          pl={4}
          pr={4}
          direction={"row"}
          spacing={3}
          //   color={grey[500]}
          fontSize={"0.7rem"}
          //   fontWeight={500}
          width={"100%"}
          alignItems={"center"}
        >
          {footerStack}
        </Stack>
      </Box>
    </li>
  );
};

export default BaseItemList;
