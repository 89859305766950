import React, { memo } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingOutlined";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";

dayjs.extend(relativeTime);

const DeliveredAtDate = ({ deliveredAt }) => {
  const { t } = useTranslation();

  if (!deliveredAt) return null;

  const formattedDelivery = dayjs(deliveredAt).format("DD MMM. YYYY HH:mm");
  const relativeDelivery = dayjs(deliveredAt).fromNow(true);

  return (
    <Tooltip
      enterDelay={20}
      title={`${t("Delivery date")}: ${formattedDelivery}`}
      placement="top"
    >
      <Box
        display="flex"
        alignItems="center"
        gap={0.5}
        fontSize="0.75rem"
        color="darkGrey.main"
      >
        <LocalShippingTwoToneIcon sx={{ fontSize: "1rem" }} />
        <Typography fontSize="0.75rem" fontWeight="600">
          {relativeDelivery}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default memo(DeliveredAtDate);
