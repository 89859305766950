import { useRef, useState, useEffect, memo, useCallback } from "react";
import {
  Box,
  Tabs,
  Tab,
  Button,
  Stack,
  Dialog,
  DialogActions,
  useMediaQuery,
  Typography,
  IconButton,
} from "@mui/material";
import { useSnackbar } from "notistack";
import OrderEditGeneral from "../EditOrder/OrderEditGeneral";
import Comments from "../EditOrder/Comments/Comments";
import { useTranslation } from "react-i18next";

import {
  Close,
  LockOutlined,
  PersonOutline,
  RotateLeft,
  RotateRight,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import axios from "axios-config";

import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import CommentForm from "./CommentForm";
import useAddComment from "hooks/mutations/orders/useAddComment";

import ImageViewer from "components/ImageViewer/ImageViewer";
import OrderEditGeneralV2 from "containers/EditOrder/OrderEditGeneralV2";

const RightPanel = ({
  repairOrderId,
  outsourcedFromId,
  outsourcedToId,
  outsourcedFrom,
  outsourcedTo,
  customer,
  setRepairOrder,
  comments,
  brand,
  model,
  malfunction,
  note,
  imei,
  estimatedServices,
  password,
  isPasswordPattern,
  deviceCategory,
  deviceType,
  deviceCategoryId,
  parent,
  rightPanelBeta = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // const [comments, setComments] = useState(null);
  const [isDoingAction, setIsDoingAction] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [commentTabIndex, setCommentTabIndex] = useState(0);

  const [editClient, setEditClient] = useState(false);

  const [uploadedImages, setUploadedImg] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const [imageViewOpen, setImageViewOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [itemIndex, setItemIndex] = useState(0);

  const isMounted = useRef(true);
  const intervalRef = useRef(null);
  const auth = useSelector((state) => state.auth);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const addCommentMutation = useAddComment();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const closeGallery = () => {
    setImageViewOpen(false);
    setZoom(1);
    setRotation(0);
  };

  const handleComment = useCallback(
    (event) => {
      event.preventDefault();
      if (inputValue.trim() === "" && uploadedImages.length === 0) {
        enqueueSnackbar("Comment cannot be empty.", { variant: "warning" });
        return;
      }

      const fileIds = uploadedImages.map((image) => image.id);
      const isPublicBool = commentTabIndex === 1;

      addCommentMutation.mutate({
        repairOrderId: repairOrderId,
        inputValue,
        isPublicBool,
        fileIds,
        user: {
          id: auth.userId,
          name: auth.userName,
          organizationId: auth.organizationId,
        },
      });
      setInputValue("");
      setUploadedImg([]);
    },
    [
      repairOrderId,
      inputValue,
      uploadedImages,
      commentTabIndex,
      enqueueSnackbar,
      addCommentMutation,
      auth.userId,
      auth.userName,
      auth.organizationId,
    ]
  );

  const hanldeNewCustomerData = (newCustomer) => {
    setRepairOrder((prevState) => {
      return { ...prevState, customer: newCustomer };
    });
  };

  const handleFileDelete = useCallback(
    async (imageId) => {
      try {
        const newUploadedImages = uploadedImages.filter(
          (img) => img.id !== imageId
        );

        setUploadedImg(newUploadedImages);

        await axios.delete(
          `/repair-order-comments/delete-comment-file/${imageId}`
        );

        // enqueueSnackbar("Image deleted successfully!", { variant: "success" });
      } catch (error) {
        enqueueSnackbar("Error deleting image", { variant: "error" });
        console.error("Error deleting image:", error);
      }
    },
    [enqueueSnackbar, uploadedImages]
  );

  const handleFileUpload = useCallback(
    async (event) => {
      const files = Array.from(event.target.files);

      const validImageTypes = [
        "image/jpeg",
        "image/png",
        "image/webp",
        "image/jpg",
        "image/heic",
        "image/heif",
      ];
      // const imageFiles = files.filter((file) =>
      //     validImageTypes.includes(file.type)
      // );
      const imageFiles = files;
      if (files.length != imageFiles.length) {
        enqueueSnackbar(
          "Please upload only image files (JPEG, PNG, WEBP, JPG).",
          { variant: "error" }
        );
      }
      if (imageFiles.length == 0) return;

      setFilesToUpload([...imageFiles]);
      const formData = new FormData();

      imageFiles.forEach((file) => {
        formData.append("commentImage", file);
      });

      try {
        setIsUploading(true);
        setUploadProgress(0);

        const response = await axios.post(
          "/repair-order-comments/upload-file",
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const percentComplete = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setUploadProgress(percentComplete);
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // enqueueSnackbar("File uploaded successfully!", { variant: "success" });
        setFilesToUpload([]);
        setUploadedImg((prevUploadedImg) => [
          ...prevUploadedImg,
          ...response.data.data,
        ]);

        // console.log("Upload successful:", response.data);
      } catch (error) {
        enqueueSnackbar("Error uploading images", { variant: "error" });
        //Remove the files that were not uploaded
        setFilesToUpload([]);
        console.error("Error uploading images:", error);
      } finally {
        setIsUploading(false);
        setUploadProgress(null);
      }
    },
    [enqueueSnackbar, uploadedImages]
  );

  const handleImageViewClose = useCallback(() => {
    setImageViewOpen(false);
  }, [setImageViewOpen]);

  const sortAndMergeImages = (comments) => {
    let allImages = [];
    comments.forEach((comment) => {
      comment.commentFiles.forEach((img) => {
        allImages.push({
          originalUrl: img.filePath,
          url: `${process.env.REACT_APP_API_URL}${img.filePath}`,
          createdAt: comment.createdAt,
          user: comment.user,
        });
      });
    });

    allImages.sort((a, b) => a.createdAt - b.createdAt);

    // const sortedImageUrls = allImages.map((imgObj) => imgObj.url);
    //add src to each image object
    allImages.forEach((imgObj) => {
      imgObj.src = imgObj.url;
    });

    return allImages;
  };

  const handleImageClick = (originalUrl) => {
    const searchUrl = `${process.env.REACT_APP_API_URL}${originalUrl}`;
    const index = images.findIndex((img) => img.url === searchUrl);
    setItemIndex(index);
    setImageViewOpen(true);
  };

  useEffect(() => {
    if (outsourcedFromId != null || outsourcedToId != null) {
      setCommentTabIndex(1);
    }
  }, []);

  useEffect(() => {
    if (comments) {
      setImages(sortAndMergeImages(comments));
    }
  }, [comments]);

  return (
    <>
      <Box
        id={"article"}
        display={"flex"}
        flex={"1 1 auto"}
        flexDirection={"column"}
        minHeight={"1px"}
        backgroundColor={"white"}
      >
        <Box
          display={"flex"}
          flex={"1 1 auto"}
          flexDirection={"column"}
          minHeight={"1px"}
          sx={{ overflowY: "auto" }}
        >
          <Box id="panel-content" flex={"1 1 auto"}>
            <Box flex={"1 1 auto"}>
              {rightPanelBeta ? (
                <OrderEditGeneralV2
                  parent={parent}
                  repairOrderId={repairOrderId}
                  brand={brand}
                  model={model}
                  malfunction={malfunction}
                  note={note}
                  imei={imei}
                  estimatedServices={estimatedServices}
                  password={password}
                  isPasswordPattern={isPasswordPattern}
                  outsourcedFrom={outsourcedFrom}
                  outsourcedTo={outsourcedTo}
                  deviceCategory={deviceCategory}
                  deviceType={deviceType}
                  deviceCategoryId={deviceCategoryId}
                  customer={customer}
                />
              ) : (
                <OrderEditGeneral
                  parent={parent}
                  repairOrderId={repairOrderId}
                  brand={brand}
                  model={model}
                  malfunction={malfunction}
                  note={note}
                  imei={imei}
                  estimatedServices={estimatedServices}
                  password={password}
                  isPasswordPattern={isPasswordPattern}
                  outsourcedFrom={outsourcedFrom}
                  outsourcedTo={outsourcedTo}
                  deviceCategory={deviceCategory}
                  deviceType={deviceType}
                  deviceCategoryId={deviceCategoryId}
                  customer={customer}
                />
              )}
            </Box>
            <Stack gap={"10px"} borderTop={"1px solid lightGrey"} p={"15px"}>
              <Stack
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
                gap={"5px"}
              >
                <Typography variant="h5">{t("Comments")}</Typography>
                {!outsourcedFromId && !outsourcedToId ? null : (
                  <Tabs
                    value={commentTabIndex}
                    onChange={(e, newValue) => setCommentTabIndex(newValue)}
                  >
                    <Tab
                      label={t("Private")}
                      icon={<LockOutlined sx={{ fontSize: "15px" }} />}
                      iconPosition={"start"}
                      sx={{ minHeight: "40px", padding: "6px 12px" }}
                    />
                    <Tab
                      label={outsourcedToId ? t("Partner") : t("Customer")}
                      icon={<PersonOutline sx={{ fontSize: "15px" }} />}
                      iconPosition={"start"}
                      sx={{ minHeight: "40px", padding: "6px 12px" }}
                    />
                  </Tabs>
                )}
              </Stack>
              <Box sx={{ width: "100%" }}>
                <CommentForm
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  handleComment={handleComment}
                  handleFileUpload={handleFileUpload}
                  uploadedImages={uploadedImages}
                  handleFileDelete={handleFileDelete}
                  filesToUpload={filesToUpload}
                  isUploading={isUploading}
                  uploadProgress={uploadProgress}
                  theme={theme}
                  t={t}
                />
              </Box>
              <Comments
                repairOrderId={repairOrderId}
                commentTabIndex={commentTabIndex}
                comments={comments}
                outsourcedFrom={outsourcedFrom}
                outsourcedTo={outsourcedTo}
                // setComments={setComments}
                // setCommentTabIndex={setCommentTabIndex}
                orderIsShared={
                  outsourcedFromId != null || outsourcedToId != null
                }
                isDoingAction={isDoingAction}
                setIsDoingAction={setIsDoingAction}
                handleImageClick={handleImageClick}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
      <ImageViewer
        images={images}
        initialIndex={itemIndex}
        open={imageViewOpen}
        onClose={handleImageViewClose}
      />
    </>
  );
};

export default memo(RightPanel);
