import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import axios from "axios-config";
import { patchPaymentField } from "api/repair-orders";

const useUpdatePaymentField = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ paymentId, key, value }) => patchPaymentField({ paymentId, key, value }),
    {
      onMutate: async ({ repairOrderId, paymentId, key, value }) => {
        await queryClient.cancelQueries(["repairOrder", repairOrderId]);

        const previousOrder = queryClient.getQueryData([
          "repairOrder",
          repairOrderId,
        ]);

        if (!previousOrder) {
          console.error("No previous order found");
          console.log("repairOrderId", repairOrderId);
          return;
        }

        queryClient.setQueryData(["repairOrder", repairOrderId], (old) => ({
          ...old,
          repairPayments: old.repairPayments.map((repairPayment) =>
            repairPayment.id === paymentId
              ? { ...repairPayment, [key]: value }
              : repairPayment
          ),
        }));

        return { previousOrder };
      },
      onError: (error, variables, context) => {
        if (context?.previousOrder) {
          queryClient.setQueryData(
            ["repairOrder", variables.repairOrderId],
            context.previousOrder
          );
        }
        enqueueSnackbar("Failed to update field", { variant: "error" });
      },
      onSuccess: () => {
        enqueueSnackbar("Field updated successfully", { variant: "success" });
      },
      //   onSettled: (data, error, variables) => {
      //     queryClient.invalidateQueries(["repairOrder", variables.repairOrderId]);
      //   },
    }
  );
};

export default useUpdatePaymentField;
