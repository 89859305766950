import * as React from "react";
import { useSnackbar } from "notistack";
import axios from "../../axios-config";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton,
  Typography,
  Stack,
  Divider,
  Popover,
  Button,
  ButtonGroup,
  circularProgressClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import BaseItemList from "../../components/ItemList/BaseItemList";
import EditServiceDialog from "./Dialogs/EditServiceDialog";

//Icons
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import EditPaymentDialog from "./Dialogs/EditPaymentDialog";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../utils/currency";

const PaymentItemList = ({ item, onDelete, setPayments, fromOutsource }) => {
  const currency = useSelector((state) => state.auth.currency);

  let dayjs = require("dayjs");
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [mouseHover, setMouseHover] = React.useState(false);
  const [editDialog, setEditDialog] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const onDeleteButtonClick = (e) => {
    e.stopPropagation();
    if (onDelete !== undefined) onDelete(item);
  };

  const handleCloseDialog = () => {
    setEditDialog(false);
  };

  const paymentMethods = {
    0: "Cash",
    1: "Card",
    2: "Transfer",
  };

  return (
    <>
      <BaseItemList
        sx={{
          border: "solid 1px lightgrey",

          "&:hover": {
            border: fromOutsource ? "" : "solid 1px",
            borderColor: fromOutsource ? "" : "black",
            cursor: fromOutsource ? "default" : "pointer",
          },
        }}
        onMouseEnter={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
        itemIcon={<PaymentsOutlinedIcon color="darkGrey" />}
        onClick={() => (fromOutsource ? null : setEditDialog(true))}
        mainStack={
          <>
            <Typography sx={{ fontWeight: 500 }}>
              {t(paymentMethods[item.paymentMethod])}
            </Typography>
            <Typography flexGrow={1} variant={"caption"}>
              ({item.paymentType === "in" ? "Pay in" : "Pay out"})
            </Typography>
            <Typography
              variant="body"
              fontWeight={500}
              noWrap
              color={item.paymentType === "out" ? "red.main" : "green.main"}
            >
              {formatCurrency(item.amount, currency)}{" "}
            </Typography>
          </>
        }
        footerStack={
          <>
            <div>{dayjs(item.updatedAt).format("DD.MMM.YYYY HH:mm")}</div>
          </>
        }
        onDeleteButtonClick={
          onDelete !== undefined && !item.isCreating
            ? onDeleteButtonClick
            : null
        }
      />
      <EditPaymentDialog
        payment={item}
        dialogOpen={editDialog}
        handleDialogClose={handleCloseDialog}
        onEdit={(payments) => setPayments(payments)}
      />
    </>
  );
};

export default PaymentItemList;
