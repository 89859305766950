import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Zoom } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import { CloseRounded, ZoomIn, ZoomOut } from "@mui/icons-material";
import { Box } from "@mui/system";
import dayjs from "dayjs";

const ImageViewer = ({ images, initialIndex, open, onClose }) => {
  const [imageIndex, setImageIndex] = useState(initialIndex);
  const swiperRef = useRef(null);
  const thumbnailRefs = useRef([]);

  useEffect(() => {
    if (open && swiperRef.current) {
      setImageIndex(initialIndex);
      swiperRef.current.slideTo(initialIndex, 0); // Slide without animation
    }
  }, [open, initialIndex]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        swiperRef.current?.slideNext();
      } else if (event.key === "ArrowLeft") {
        swiperRef.current?.slidePrev();
      }
    };

    if (open) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  const handleZoomIn = () => {
    if (swiperRef.current) {
      //current zoom level
      let currentZoom = swiperRef.current.zoom.scale;
      swiperRef.current.zoom.in(currentZoom + 0.5);
    }
  };

  const handleZoomOut = () => {
    if (swiperRef.current) {
      swiperRef.current.zoom.out();
    }
  };

  const handleThumbnailClick = (idx) => {
    setImageIndex(idx);
    if (swiperRef.current) {
      swiperRef.current.slideTo(idx);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      scroll="body"
      PaperProps={{
        style: {
          backgroundColor: "rgba(10, 18, 29, 0.8)",
          backdropFilter: "blur(10px)",
        },
      }}
    >
      <AppBar
        sx={{
          position: "static",
          backgroundColor: "transparent",
          borderRadius: 0,
        }}
        elevation={0}
      >
        <Toolbar>
          <Stack flex={1}>
            <Typography variant="body" component="div" flex={1}>
              {images[imageIndex]?.user?.name}
            </Typography>
            <Typography variant="body2" component="div" flex={1}>
              {dayjs(images[imageIndex]?.createdAt).format("DD/MM/YYYY HH:mm")}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "10px",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<ZoomIn />}
              onClick={handleZoomIn}
            >
              Zoom In
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ZoomOut />}
              onClick={handleZoomOut}
            >
              Zoom Out
            </Button>
          </Stack>
          <IconButton color="inherit" onClick={onClose} aria-label="close">
            <CloseRounded />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        onSlideChange={(swiper) => setImageIndex(swiper.activeIndex)}
        slidesPerView={1}
        centeredSlides={true}
        navigation={true}
        zoom={{
          maxRatio: 3,
          minRatio: 1,
          zoomedSlideClass: "swiper-slide-zoomed",
        }}
        spaceBetween={10}
        modules={[Zoom, Navigation, Pagination]}
        loop={false}
        style={{ height: "calc(100vh - 170px)", width: "100%" }}
        grabCursor={true}
        initialSlide={initialIndex}
        // activeIndex={imageIndex}
      >
        {images.map((img, idx) => (
          <SwiperSlide key={idx}>
            <div
              className="swiper-zoom-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img
                src={img.src}
                alt={`Slide ${idx}`}
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "80vh",
                  objectFit: "contain",
                }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Stack
        direction="row"
        sx={{
          overflowX: "auto",
          whiteSpace: "nowrap",
          justifyContent: "center",
          pb: "10px",
          height: "100px",
        }}
      >
        {images.map((image, idx) => (
          <Box
            key={idx}
            component="img"
            src={image.src}
            alt={`Thumbnail ${idx}`}
            ref={(el) => (thumbnailRefs.current[idx] = el)}
            sx={{
              width: "75px",
              height: "75px",
              cursor: "pointer",
              border:
                imageIndex === idx ? "2px solid blue" : "2px solid transparent",
              objectFit: "cover",
              mr: 1,
            }}
            onClick={() => handleThumbnailClick(idx)}
          />
        ))}
      </Stack>
    </Dialog>
  );
};

export default React.memo(ImageViewer);
