import React from "react";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useMediaQuery } from "@mui/material";

const LabelTypography = ({ text, icon }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Stack
      direction={"row"}
      alignSelf={"flex-start"}
      alignItems={"center"}
      spacing={1}
      minWidth={isSmallScreen ? "100px" : "130px"}
      width={isSmallScreen ? "100px" : "130px"}
    >
      {/* {icon} */}
      <Typography variant="subtitle2" color={grey[700]}>
        {text}
      </Typography>
    </Stack>
  );
};

export default LabelTypography;
