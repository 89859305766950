import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  Divider,
  Link,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import EmergencyIconFilled from "../Icons/EmergencyIconFilled";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { FaceOutlined } from "@mui/icons-material";

import UpdatedAtDate from "../UpdatedAtDate/UpdatedAtDate";
import CreatedAtDate from "../CreatedAtDate/CreatedAtDate";
import DeliveredAtDate from "../DeliveredAtDate/DeliveredAtDate";
import AssignUserButton from "components/AssignUserButton/AssignUserButton";
import useAssignUserToRepair from "hooks/mutations/orders/useAssignUserToRepair";

const TypographyWithIcon = ({ text, icon }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        flex: 1,
        minWidth: 0,
      }}
    >
      {React.cloneElement(icon, { sx: { fontSize: "14px" } })}
      <Typography
        fontSize="12px"
        fontWeight="800"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const EntryDetailsCell = ({
  rowData,
  ableToSelect,
  selectedOrders,
  handleSelectRow,
  handlingRequests,
  cacheKey,
}) => {
  const { t } = useTranslation();
  const requestId = `status_cell_request_handle:${rowData.id}`;

  const [isHandlingOrderRequest, setIsHandlingOrderRequest] = useState(false);
  const assignUserToRepair = useAssignUserToRepair({ cacheKeys: [cacheKey] });
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    setIsHandlingOrderRequest(
      Boolean(handlingRequests && handlingRequests.includes(requestId))
    );
  }, [handlingRequests, requestId]);

  const handleSelect = (e) => {
    e.stopPropagation();
    handleSelectRow(rowData);
  };

  const handleAssignUser = (user) => {
    assignUserToRepair.mutate({
      repairOrderId: rowData.id,
      user,
    });
  };

  const assignToMe = () => {
    if (auth.userName && auth.userId) {
      const user = { id: auth.userId, name: auth.userName, email: auth.email };
      assignUserToRepair.mutate({
        repairOrderId: rowData.id,
        user,
      });
    }
  };

  const renderRequestContent = () => {
    if (rowData.isChangingStatus) {
      return <CircularProgress size={25} />;
    }
    return (
      <Box display="inline-flex" alignItems="center" gap="5px">
        <Typography
          ml={0}
          fontSize="inherit"
          fontWeight="inherit"
          color="blue"
          display="inline"
        >
          {rowData.organization.id}#{rowData.organizationCounter}{" "}
        </Typography>
      </Box>
    );
  };

  const renderLinkContent = () => {
    const linkText = rowData.outsourcedFrom
      ? `${rowData.outsourcedFrom.organization.id}#${rowData.outsourcedFrom.organizationCounter}`
      : `${rowData.organizationId}#${rowData.organizationCounter}`;
    return (
      <Link
        component={RouterLink}
        color="#0000EE"
        underline="hover"
        variant="h4"
        to={`/editEntry/${rowData.id}`}
      >
        {linkText}
      </Link>
    );
  };

  const showOutsourcedFrom = () => {
    if (rowData.outsourcedFromId && rowData.outsourcedFrom) {
      return (
        <TypographyWithIcon
          text={rowData.outsourcedFrom.organization.name}
          icon={<CallReceivedIcon />}
        />
      );
    }
    if (rowData.isRequest) {
      return (
        <TypographyWithIcon
          text={rowData.organization.name}
          icon={<CallReceivedIcon />}
        />
      );
    }
    return null;
  };

  const showOutsourcedTo = () => {
    if (rowData.outsourcedToId && rowData.outsourcedTo) {
      return (
        <TypographyWithIcon
          text={rowData.outsourcedTo.organization.name}
          icon={<CallMadeIcon />}
        />
      );
    }
    return null;
  };

  const ShowOutsourcedToRequest = () => (
    <TypographyWithIcon
      text={rowData.repairOrderOutsourceRequest.outsourceReceiver.name}
      icon={<CallMadeIcon />}
    />
  );

  return (
    <Box
      className="cellContent orderColumnCell"
      sx={{ display: "flex", flexDirection: "column", gap: "2px" }}
    >
      {/* Row 1: Checkbox, Title/Link, Emergency Icon */}
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          {ableToSelect && (
            <Checkbox
              checked={selectedOrders.some(
                (selectedOrder) => selectedOrder.id === rowData.id
              )}
              onChange={handleSelect}
            />
          )}
          <Box fontWeight={600} fontSize="1.375rem" ml={ableToSelect ? 0.5 : 0}>
            {rowData.isRequest ? renderRequestContent() : renderLinkContent()}
          </Box>
        </Box>

        {rowData.levelOfAttention === 1 && (
          <Tooltip enterDelay={20} title={t("Emergency")}>
            <div>
              <EmergencyIconFilled />
            </div>
          </Tooltip>
        )}
      </Box>

      {/* Row 2: Customer, Outsourced From/To, Outsource Request */}
      <Box display="flex" gap={1}>
        {rowData.customer && (
          <TypographyWithIcon
            text={rowData.customer.name}
            icon={<FaceOutlined />}
          />
        )}
        {showOutsourcedFrom()}
        {showOutsourcedTo()}
        {rowData.repairOrderOutsourceRequest && <ShowOutsourcedToRequest />}
      </Box>

      <Divider sx={{ borderColor: "lightGrey.main" }} />

      {/* Row 3: Dates */}
      <Box display="inline-flex" gap={1}>
        <CreatedAtDate createdAt={rowData.createdAt} />
        {rowData.deliveredAt ? (
          <DeliveredAtDate deliveredAt={rowData.deliveredAt} noTime noDate />
        ) : (
          <UpdatedAtDate updatedAt={rowData.updatedAt} />
        )}
      </Box>

      {/* Row 4: Assign User (Only if not a Request) */}
      {!rowData.isRequest && (
        <Box display="inline-flex" gap={1}>
          <AssignUserButton
            assignedTo={rowData.assignedTo}
            assignToMe={assignToMe}
            onSelectUser={handleAssignUser}
            includeNotAssigned
            removableAssignee
          />
        </Box>
      )}
    </Box>
  );
};

export default EntryDetailsCell;
