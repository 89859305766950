import axios from "axios-config";
import ConsoleHelper from "utils/ConsoleHelper";

export const createOutsourceRequest = async ({
  repairOrderId,
  toOrganizationId,
}) => {
  if (!repairOrderId || !toOrganizationId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.put(
    `/repair-orders/outsource/${repairOrderId}`,
    {
      toOrganizationId,
    }
  );

  if (response.status !== 200) throw new Error("Failed to add refund");

  return response.data;
};

export const cancelOutsourceRequest = async ({ repairOrderId }) => {
  if (!repairOrderId) {
    throw new Error("Missing required parameters");
  }

  const response = await axios.delete(
    `/repair-orders/outsource/${repairOrderId}`
  );

  if (response.status !== 200) throw new Error("Failed to add refund");

  return response.data;
};

export const acceptOutsourceProposedService = async ({
  repairOrderId,
  serviceId,
}) => {
  if (!repairOrderId || !serviceId) {
    throw new Error("Missing required parameters");
  }
  const response = await axios.patch(
    `/repair-orders/services/${serviceId}/accept-outsource/${repairOrderId}`
  );

  if (response.status !== 200) throw new Error("Failed to add refund");

  return response.data;
};

export const declineOutsourceProposedService = async ({
  repairOrderId,
  serviceId,
}) => {
  if (!repairOrderId || !serviceId) {
    throw new Error("Missing required parameters");
  }
  const response = await axios.patch(
    `/repair-orders/services/${serviceId}/decline-outsource/${repairOrderId}`
  );

  if (response.status !== 200) throw new Error("Failed to add refund");

  return response.data;
};

export const removeOutsourceAcceptService = async ({
  repairOrderId,
  serviceId,
}) => {
  if (!repairOrderId || !serviceId) {
    throw new Error("Missing required parameters");
  }
  // /repair-orders/services/${service.id}/removePartnerAcceptance/${repairOrder.id}
  const response = await axios.patch(
    `/repair-orders/services/${serviceId}/remove-outsource-acceptance/${repairOrderId}`
  );

  if (response.status !== 200) throw new Error("Failed to revert acceptance");

  return response.data;
};
