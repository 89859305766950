import { useState, useRef, useEffect } from "react";
import {
  Paper,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Popover,
  Stack,
  OutlinedInput,
  Box,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  Typography,
  darken,
} from "@mui/material";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from "@mui/material";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { dataTableActions } from "../../store/data-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import SortIcon from "@mui/icons-material/Sort";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined";

import { useTranslation } from "react-i18next";
import PartnersFilter from "./PartnersFilter";
import SearchBar from "./SearchBar";
import DueToPartner from "../HeaderWidgets/DueToPartners";
import DueFromServices from "../HeaderWidgets/DueFromServices";
import EmergencyIconFilled from "../Icons/EmergencyIconFilled";
import axios from "axios-config";
import {
  red,
  blue,
  green,
  yellow,
  purple,
  orange,
  cyan,
  pink,
  grey,
  teal,
} from "@mui/material/colors";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { PermIdentity, RemoveCircle } from "@mui/icons-material";
import UserFilter from "./UserFilter";
import NewFeatureTooltip from "components/NewFeatureTooltip/NewFeatureTooltip";

const fetchRepairFilters = async () => {
  const { data } = await axios.get("/repair-filters");
  return data;
};

const Sorts = ({ searchInFields, setSearchInFields, searchFieldsBucket }) => {
  const { t } = useTranslation();
  const [filterDeleteMode, setFilterDeleteMode] = useState(false);
  const [userPopoverAnchor, setUserPopoverAnchor] = useState(null);

  const openUserPopover = (event) => {
    event.stopPropagation();
    setUserPopoverAnchor(event.currentTarget);
  };

  const handleUserPopoverClose = (event) => {
    event && event.stopPropagation();
    setUserPopoverAnchor(null);
  };

  const dispatch = useDispatch();
  const statusesSort = useSelector((state) => state.dataTable.statuses);
  const assigneeFilter = useSelector((state) => state.dataTable.assigneeFilter);
  const urgenciesToggle = useSelector(
    (state) => state.dataTable.urgenciesToggle
  );

  const setStatusesSort = (statuses) =>
    dispatch(dataTableActions.setStatuses(statuses));
  const setStatusesLoaded = (value) =>
    dispatch(dataTableActions.setStatusesLoaded(value));
  // const hideAllStatuses = () => dispatch(dataTableActions.hideAllStatuses());
  // const showAllStatuses = () => dispatch(dataTableActions.showAllStatuses());
  const toggleUrgency = () => dispatch(dataTableActions.toggleUrgency());

  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [createFilterOpen, setCreateFilterOpen] = useState(false);
  const queryClient = useQueryClient();
  const [value, setValue] = useState(1);
  const handleSelection = (newValue) => {
    setValue(newValue);
    setOpen(false);
  };

  const [selectedSort, setSelectedSort] = useState("status");
  // const {
  //   data: filters,
  //   isLoading,
  //   error,
  //   refetch,
  // } = useQuery("repairFilters", fetchRepairFilters, {
  //   staleTime: Infinity,
  //   enabled: open, // Only fetch when popover is open
  // });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const statuses = reorder(
      statusesSort,
      result.source.index,
      result.destination.index
    );

    setStatusesSort(statuses);
    updateDefaultStatusesFilter(statuses);
  };

  const updateDefaultStatusesFilter = async (statuses) => {
    try {
      const response = await axios.patch(`/repair-filters/default`, {
        statuses,
      });
      // const data = response.data;
      // if (data.statuses) {
      //     setStatusesSort(data.statuses);
      // }
      // setStatusesSort(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleHideButtonClick = (index) => {
    const newStatuses = statusesSort.map((status, i) =>
      i === index ? { ...status, isHidden: !status.isHidden } : status
    );
    setStatusesSort(newStatuses);
    updateDefaultStatusesFilter(newStatuses);
  };

  const statusParentCheck = () => {
    let hiddenAmount = 0;
    let statusesLength = statusesSort.length;
    for (let i = 0; i < statusesLength; i++) {
      if (statusesSort[i].isHidden) {
        hiddenAmount++;
      }
    }

    let isUnchecked = hiddenAmount === statusesLength;
    if (isUnchecked) {
      return false;
    }
    return true;
  };
  const statusIsIntermediate = () => {
    let hiddenAmount = 0;
    let statusesLength = statusesSort.length;
    for (let i = 0; i < statusesLength; i++) {
      if (statusesSort[i].isHidden) {
        hiddenAmount++;
      }
    }
    let isItermediate = hiddenAmount !== 0 && hiddenAmount !== statusesLength;
    if (isItermediate) {
      return true;
    }
    return false;
  };
  const handleCheckAll = () => {
    const isChecked = statusParentCheck();
    if (isChecked) {
      hideAllStatuses();
    } else {
      showAllStatuses();
    }
  };

  const hideAllStatuses = () => {
    const newStatuses = statusesSort.map((status) => ({
      ...status,
      isHidden: true,
    }));
    setStatusesSort(newStatuses);
    updateDefaultStatusesFilter(newStatuses);
  };

  const showAllStatuses = () => {
    const newStatuses = statusesSort.map((status) => ({
      ...status,
      isHidden: false,
    }));
    setStatusesSort(newStatuses);
    updateDefaultStatusesFilter(newStatuses);
  };

  const getDefaultRepairsFilter = async () => {
    try {
      const response = await axios.get(`/repair-filters/default`);
      const data = response.data;
      if (Array.isArray(data.statuses)) {
        setStatusesSort(data.statuses);
        return;
      }
      try {
        const statusesArray = JSON.parse(data.statuses);
        if (Array.isArray(statusesArray)) {
          setStatusesSort(statusesArray);
        } else {
          console.error("data.statuses is not an array");
        }
      } catch (err) {
        console.error("Failed to parse data.statuses:", err);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setStatusesLoaded(true);
    }
  };

  const deleteFilterMutation = useMutation(
    async (id) => {
      const response = await axios.delete(`/repair-filters/${id}`);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("repairFilters");
      },
    }
  );

  useEffect(() => {
    getDefaultRepairsFilter();
  }, []);

  // useEffect(() => {
  //   if (open) {
  //     refetch();
  //   }
  // }, [open, refetch]);

  const handleUserAssigneeSelectFilter = (filteredUser) => {
    console.log(filteredUser);
    dispatch(dataTableActions.setAssigneeFilter(filteredUser));
  };

  return (
    <>
      <>
        <Button
          variant="outlined"
          color="black"
          ref={selectRef}
          onClick={() => setOpen(true)}
          startIcon={<SortIcon />}
        >
          {t("Status")}
        </Button>
        <PartnersFilter />
        <Button
          variant="outlined"
          color={urgenciesToggle ? "red" : "black"}
          startIcon={
            urgenciesToggle ? <EmergencyIconFilled /> : <WhatshotIcon />
          }
          onClick={toggleUrgency}
        >
          {t("Emergencies")}
        </Button>

        {/* <Button variant='outlined' color='black' startIcon={<AttachMoneyOutlinedIcon/>} onClick={() => setOpen(true)}>{t("Payments filter")}</Button> */}
        <NewFeatureTooltip
          hintName="assignee-filter"
          title={t("newFeatureTooltip.assigneeFilter.title")}
          description={t("newFeatureTooltip.assigneeFilter.description")}
          badge={t("newFeatureTooltip.newBadge")}
        >
          <Button
            variant="outlined"
            color="black"
            onClick={openUserPopover}
            startIcon={<PermIdentity />} // You can import & use this icon if desired
          >
            {t("filters.assignee")}
          </Button>
        </NewFeatureTooltip>
        <SearchBar
          searchInFields={searchInFields}
          setSearchInFields={setSearchInFields}
          searchFieldsBucket={searchFieldsBucket}
        />
        <DueFromServices />
        <DueToPartner />
      </>

      <Popover
        id="status-sort"
        open={open}
        anchorEl={selectRef.current}
        onClose={() => handleSelection(value)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={"15px"}>
          <Stack>
            {/* <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateFilterOpen(true)}
              >
                {t("Create New Filter")}
              </Button>
              <Button
                disabled={filters?.length === 0}
                variant="outlined"
                color="black"
                onClick={() => setFilterDeleteMode(!filterDeleteMode)}
              >
                {filterDeleteMode ? t("Disable Delete") : t("Eanble Delete")}
              </Button>
            </Stack>
            {filters?.length > 0 && (
              <Typography variant="body">{t("Filters")}</Typography>
            )}
            <Stack
              direction={"row"}
              alignItems={"center"}
              flexWrap={"wrap"}
              maxWidth={"200px"}
              gap={1}
            >
              {filters?.map((filter) => {
                return (
                  <Button
                    endIcon={filterDeleteMode ? <RemoveCircle /> : null}
                    key={filter.id}
                    sx={{
                      backgroundColor: filter.backgroundColor,
                      color: "#fff",
                      mr: 1,
                      textTransform: "none",
                      transition: "background-color 0.2s", // smooth transition
                      "&:hover": {
                        backgroundColor: darken(filter.backgroundColor, 0.2),
                      },
                    }}
                    onClick={() => {
                      filterDeleteMode
                        ? deleteFilterMutation.mutate(filter.id)
                        : setStatusesSort(filter.statuses);
                    }}
                  >
                    {filter.name}
                  </Button>
                );
              })}
            </Stack> */}
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                {t("Status priority")}
              </FormLabel>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <FormControlLabel
                  label={t("Statuses")}
                  control={
                    <Checkbox
                      checked={statusParentCheck()}
                      indeterminate={statusIsIntermediate()}
                      onChange={handleCheckAll}
                    />
                  }
                />
                {/* <Button>Reset to default</Button> */}
              </Stack>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={selectedSort}
                onChange={(e) => setSelectedSort(e.target.value)}
              >
                {/* <FormControlLabel value="status" control={<Radio />} label={t("Status")} /> */}
                {selectedSort == "status" ? (
                  <>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <List
                            sx={{ pt: "0px" }}
                            // {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {statusesSort?.map((status, i) => (
                              <Draggable
                                key={status.id}
                                draggableId={status.id}
                                index={i}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <ListItem
                                      key={status.id}
                                      sx={{ py: "0px" }}
                                      secondaryAction={
                                        <IconButton
                                          color={
                                            status.isHidden
                                              ? "secondary"
                                              : "primary"
                                          }
                                          size="small"
                                          onClick={() =>
                                            handleHideButtonClick(i)
                                          }
                                        >
                                          {status.isHidden ? (
                                            <VisibilityOffIcon fontSize="inherit" />
                                          ) : (
                                            <VisibilityIcon fontSize="inherit" />
                                          )}
                                        </IconButton>
                                      }
                                    >
                                      <ListItemIcon sx={{ minWidth: "30px" }}>
                                        <DragIndicatorIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={t(status.value)} />
                                    </ListItem>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </List>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </>
                ) : null}
              </RadioGroup>
            </FormControl>
          </Stack>
        </Box>
      </Popover>
      <Popover
        open={Boolean(userPopoverAnchor)}
        anchorEl={userPopoverAnchor}
        onClose={handleUserPopoverClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Box p={2} width={"300px"}>
          <UserFilter
            selectedUsers={assigneeFilter}
            onFilterChange={handleUserAssigneeSelectFilter}
          />
        </Box>
      </Popover>
      <CreateFilterDialog
        open={createFilterOpen}
        onClose={() => setCreateFilterOpen(false)}
        currentSorts={statusesSort}
      />
    </>
  );
};

const CreateFilterDialog = ({ open, onClose, currentSorts }) => {
  const { t } = useTranslation();
  const [newFilterName, setNewFilterName] = useState("");
  const [backgroundColor, setBackgroundColor] = useState(red[500]);
  const queryClient = useQueryClient();
  const handleSave = async () => {
    try {
      const filterData = {
        name: newFilterName,
        statuses: currentSorts,
        backgroundColor: backgroundColor,
      };
      const response = await axios.post("/repair-filters", filterData);
      console.log("Filter saved:", response.data);
      // Invalidate the repairFilters query to refetch the updated list
      queryClient.invalidateQueries("repairFilters");
      onClose();
    } catch (error) {
      console.error("Error saving filter:", error);
    }
  };
  const colorOptions = [
    red[500],
    blue[500],
    green[500],
    yellow[700],
    purple[500],
    orange[500],
    cyan[500],
    pink[400],
    grey[500],
    teal[500],
  ];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("Create a new filter")}</DialogTitle>
      <DialogContent>
        <TextField
          variant="filled"
          label={t("Filter")}
          fullWidth
          value={newFilterName}
          onChange={(e) => setNewFilterName(e.target.value.slice(0, 20))}
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
            maxLength: 20,
          }}
          helperText={`${newFilterName.length}/20`}
        />
        {/* Pick a color */}
        <Box mt={2}>
          <Typography variant="subtitle1">{t("Pick a color")}</Typography>
          <Box display="flex" flexWrap="wrap" mt={1}>
            {colorOptions.map((col) => (
              <IconButton
                key={col}
                onClick={() => setBackgroundColor(col)}
                disableRipple
                sx={{
                  bgcolor: col,
                  borderRadius: "50%",
                  width: 40,
                  height: 40,
                  m: 0.5,
                  // Use an outline when this color is selected
                  outline: backgroundColor === col ? "2px solid #555" : "none",
                  outlineOffset: backgroundColor === col ? 2 : 0,
                  "&:hover": {
                    bgcolor: col,
                  },
                }}
              />
            ))}
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        {/* Preview the filter button */}
        <Box mt={2}>
          <Typography variant="subtitle1">{t("Preview")}</Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: backgroundColor,
              color: "#fff",
              mt: 1,
            }}
          >
            {newFilterName || t("Filter Button")}
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <Button
          onClick={handleSave}
          disabled={newFilterName.length === 0}
          variant="contained"
        >
          {t("Create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Sorts;
