import React, { forwardRef, memo, useCallback } from "react";
import {
  Button,
  Popover,
  Box,
  Autocomplete,
  Avatar,
  Typography,
  TextField,
  ListItemButton,
  Paper,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import PermIdentityRounded from "@mui/icons-material/PermIdentityRounded";
import { useTranslation } from "react-i18next";
import useUserSelect from "hooks/useUserSelect";
import { Close } from "@mui/icons-material";

const ellipsisStyle = {
  display: "inline-block",
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const AssignUserButton = ({
  assignedTo,
  onSelectUser,
  assignToMe,
  includeNotAssigned = false,
  removableAssignee = false,
}) => {
  const { t } = useTranslation();

  // For single selection, we do multiSelect = false
  const {
    anchorEl,
    isSelecting,
    users,
    openAutocomplete,
    setOpenAutocomplete,
    openPopover,
    closePopover,
    handleSelect,
    filterOptions,
    loading,
  } = useUserSelect({ multiSelect: false, includeNotAssigned });

  const handleButtonClick = useCallback(
    (event) => {
      // Close the autocomplete dropdown before opening the popover
      setOpenAutocomplete(false);
      openPopover(event);
    },
    [openPopover, setOpenAutocomplete]
  );

  const handlePopoverClose = useCallback(
    (event) => {
      closePopover(event);
    },
    [closePopover]
  );

  const handleOptionSelect = useCallback(
    (user) => {
      handleSelect(user);
      onSelectUser(user.id === "none" ? null : user);
    },
    [handleSelect, onSelectUser]
  );

  const handleAssignToMe = useCallback(
    (event) => {
      event.stopPropagation();
      assignToMe();
      closePopover(event);
    },
    [assignToMe, closePopover]
  );

  const handleInputClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (!openAutocomplete) {
        setOpenAutocomplete(true);
      }
    },
    [openAutocomplete, setOpenAutocomplete]
  );

  return (
    <>
      <Button
        variant="text"
        color="black"
        startIcon={
          assignedTo ? (
            <Avatar sx={{ width: 24, height: 24 }}>
              <span style={{ fontSize: "14px" }}>
                {assignedTo.name.charAt(0).toUpperCase()}
              </span>
            </Avatar>
          ) : (
            <PermIdentityRounded
              sx={{ border: "1px dashed black", borderRadius: "50%", p: 0 }}
            />
          )
        }
        onClick={handleButtonClick}
      >
        {assignedTo ? (
          <span style={ellipsisStyle}>{assignedTo.name}</span>
        ) : (
          t("assignUser.notAssigned")
        )}
      </Button>

      {isSelecting && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          PaperProps={{
            onClick: (e) => e.stopPropagation(),
            sx: { minWidth: "400px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              p: 2,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Autocomplete
              size="small"
              variant="outlined"
              id="user-details"
              loading={loading}
              loadingText={t("general.loading")}
              options={users}
              value={assignedTo || null}
              getOptionLabel={(option) => option.name}
              filterOptions={filterOptions}
              onMouseDown={(e) => e.stopPropagation()}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableClearable={!removableAssignee}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  sx={{ width: "350px" }}
                  onClick={(e) => e.stopPropagation()}
                />
              )}
              renderOption={(props, option) => (
                <OptionList
                  {...props}
                  key={option.id}
                  option={option}
                  handleOptionSelect={handleOptionSelect}
                />
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onClick={handleInputClick}
                  onFocus={(e) => e.stopPropagation()} // Prevent autofocus from opening the dropdown
                  placeholder={t("assignUser.placeholder")}
                  autoComplete="off"
                />
              )}
              sx={{ width: "55%" }}
              open={openAutocomplete}
              onOpen={(e) => {
                e.stopPropagation();
                setOpenAutocomplete(true);
              }}
              onClose={(e) => {
                e.stopPropagation();
                setOpenAutocomplete(false);
              }}
              disableCloseOnSelect
              onChange={(event, value) => {
                if (value === null) {
                  onSelectUser(null);
                }
              }}
            />
            <Typography component="div" sx={{ mx: 1 }}>
              {t("assignUser.or")}
            </Typography>
            <Button size="medium" variant="outlined" onClick={handleAssignToMe}>
              {t("assignUser.assignToMe")}
            </Button>
          </Box>
        </Popover>
      )}
    </>
  );
};

const OptionList = forwardRef(({ option, handleOptionSelect }, ref) => {
  const onClick = (event) => {
    event.stopPropagation();
    handleOptionSelect(option);
  };

  return (
    <div ref={ref} onClick={(e) => e.stopPropagation()}>
      <ListItemButton onClick={onClick}>
        {option.id !== "none" && (
          <ListItemIcon sx={{ minWidth: "10px" }}>
            <Avatar sx={{ mr: 2, width: 24, height: 24, fontSize: "14px" }}>
              {option.name.charAt(0).toUpperCase()}
            </Avatar>
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              {option.name}
            </Typography>
          }
          secondary={
            <Typography variant="caption" sx={{ fontSize: "12px" }}>
              {option.email}
            </Typography>
          }
        />
      </ListItemButton>
    </div>
  );
});

export default memo(AssignUserButton);
