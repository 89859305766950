// useUpdateOrderStatusMutation.js
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { createFollowUpRepairOrder } from "api/repair-orders";

const useCreateFollowUp = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  //   const [followUpMalfunction, setFollowUpMalfunction] = useState("");
  //   const [followUpNote, setFollowUpNote] = useState("");
  //   const [password, setPassword] = useState("");
  //   const [isPasswordPattern, setIsPasswordPattern] = useState(false);
  return useMutation(createFollowUpRepairOrder, {
    onMutate: async ({
      repairOrderId,
      followUpData: { malfunction, note, password, isPasswordPattern },
    }) => {
      console.log("Mutation logic here");
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: ["repairOrder", repairOrderId],
      });

      // Snapshot the previous data
      const previousOrder = queryClient.getQueryData([
        "repairOrder",
        repairOrderId,
      ]);
      if (!previousOrder) {
        console.error("No previous order found");
        console.log("repairOrderId", repairOrderId);
        return;
      }

      return { previousOrder };
    },
    onError: (error, variables, context) => {
      if (error?.response?.data?.message) {
        enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
      } else {
        enqueueSnackbar("Error creating repair", { variant: "error" });
      }
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar("Repair created successfully", { variant: "success" });
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(["repairOrder", variables.repairOrderId]);
    },
  });
};

export default useCreateFollowUp;
