// AUTOCOMPLETE
import {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useCallback,
  memo,
} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  TextField,
  Typography,
  Chip,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Button,
  ListItemButton,
  InputAdornment,
  Skeleton,
} from "@mui/material";

import Fuse from "fuse.js";

//  ICONS
import { useTranslation } from "react-i18next";
import axios from "axios-config";

import { AddOutlined, Circle, Inventory2Outlined } from "@mui/icons-material";
import { WarningTwoTone } from "@mui/icons-material";
import AddNewProductDialog from "../AllProducts/AddNewProductDialog";

const ProductSearch = ({
  required,
  warehouse,
  state,
  onOptionSelect,
  sx,
  newProductInitialQuantity,
}) => {
  const { t } = useTranslation();
  const fuseRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [newProductDialogOpen, setNewProductDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [fetchedOnce, setFetchedOnce] = useState(false);

  const handleOptionSelect = useCallback(
    (option) => {
      onOptionSelect(option);
      setOpen(false);
    },
    [onOptionSelect]
  );

  const hanldeAddNewProduct = (event) => {
    event.stopPropagation();
    setNewProductDialogOpen(true);
  };

  const handleNewProductDialogClose = () => {
    setNewProductDialogOpen(false);
    setSearchText("");
  };

  // Fetch products once
  useEffect(() => {
    if (!fetchedOnce && open && warehouse?.id) {
      (async () => {
        try {
          setLoading(true);
          const response = await axios.get(
            `/inventory/warehouse/${warehouse.id}/products`
          );
          if (response.status === 200) {
            setOptions(response.data);
          }
        } catch (error) {
          console.error("Error fetching inventory data:", error);
        }
        setFetchedOnce(true);
        setLoading(false);
      })();
    }
  }, [open, fetchedOnce, warehouse?.id]);

  // Build the Fuse instance once products are fetched
  useEffect(() => {
    if (options.length > 0) {
      fuseRef.current = new Fuse(options, {
        shouldSort: true,
        useExtendedSearch: true,
        tokenize: true,
        matchAllTokens: true,
        ignoreLocation: true,
        keys: [
          { name: "name", weight: 0.8 },
          { name: "sku", weight: 0.1 },
          { name: "barcode", weight: 0.1 },
        ],
        threshold: 0.2,
        distance: 50,
        minMatchCharLength: 1,
      });
    }
  }, [options]);

  // Filter method using Fuse
  const filterOptions = (opts, { inputValue }) => {
    if (!fuseRef.current) return opts;
    const results = fuseRef.current.search(inputValue).map(({ item }) => item);
    // Sort by quantityInStock descending, then take only top 10
    return results
      .sort((a, b) => (b.quantityInStock || 0) - (a.quantityInStock || 0))
      .slice(0, 15);
  };

  if (!warehouse || !warehouse.id) {
    return (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <WarningTwoTone sx={{ color: "red.main" }} />
        <Typography variant="body" fontWeight={500}>
          {t("There is no warehouse selected")}
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      <Autocomplete
        sx={{ ...sx }}
        filterOptions={filterOptions}
        id="products"
        value={state}
        inputValue={searchText} // control the displayed text onInputChange=
        onInputChange={(event, newValue, reason) => {
          if (reason === "reset") return;
          setSearchText(newValue);
        }}
        open={open}
        onOpen={(event) => {
          if (searchText.trim() !== "") {
            // Force a slight change to re-trigger filtering
            setSearchText((prev) => prev + " ");
            setTimeout(() => {
              setSearchText((prev) => prev.trim());
            }, 0);
          }
          if (!open) setOpen(true);
        }}
        onClose={() => open && setOpen(false)}
        disableCloseOnSelect
        onMouseDown={(e) => e.preventDefault()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (option?.name ? option.name : "")}
        options={options}
        noOptionsText={
          searchText.length === 0
            ? t("productSearch.search")
            : t("productSearch.noResults")
        }
        loading={loading}
        loadingText={
          <>
            {[...Array(3)].map((_, i) => (
              <Skeleton key={i} height={50} />
            ))}
          </>
        }
        PaperComponent={(props) => (
          <Paper
            {...props}
            sx={{ height: "250px", p: "15px" }}
            onMouseDown={(e) => e.preventDefault()}
          >
            <Stack spacing={2}>
              <Stack spacing={1}>
                <Stack direction={"row"}>
                  <Button
                    variant="outlined"
                    onClick={hanldeAddNewProduct}
                    startIcon={<AddOutlined />}
                    size="small"
                    color="black"
                  >
                    {t("New product")}
                  </Button>
                </Stack>
              </Stack>
              <Stack spacing={1}>
                <Typography variant="caption">Products</Typography>
                {props.children}
              </Stack>
            </Stack>
          </Paper>
        )}
        ListboxComponent={forwardRef(({ children }, ref) => (
          <List ref={ref} sx={{ overflow: "hidden", height: "100%" }}>
            {children}
          </List>
        ))}
        renderOption={(props, option) => (
          <OptionList
            {...props}
            key={option.id}
            option={option}
            handleOptionSelect={handleOptionSelect}
          />
        )}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            onClick={() => !open && setOpen(true)}
            required={required}
            label={t("productSearch.label")}
            placeholder={t("productSearch.placeholder")}
            variant="filled"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start" style={{ marginTop: "-3px" }}>
                  <Inventory2Outlined />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <AddNewProductDialog
        open={newProductDialogOpen}
        onClose={handleNewProductDialogClose}
        onNewProductAdded={(product) => {
          if (product?.productInventory?.quantityInStock > 0) {
            onOptionSelect(product);
          }
        }}
        warehouse={warehouse}
        newProductInitialQuantity={newProductInitialQuantity || 0}
        newProductInitialName={searchText}
      />
    </>
  );
};

const OptionList = forwardRef(
  ({ option, handleOptionSelect, ...rest }, ref) => {
    const { t } = useTranslation();
    const handleOnClick = useCallback(() => {
      handleOptionSelect(option);
    }, [option, handleOptionSelect]);

    return (
      <div ref={ref} {...rest}>
        <ListItemButton
          disabled={option.quantityInStock === 0}
          onClick={handleOnClick}
        >
          <ListItemIcon sx={{ minWidth: "26px" }}>
            <Circle sx={{ fontSize: "8px" }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {option.name}
              </div>
            }
            secondary={
              <span>
                {option.quantityInStock <= 0 ? (
                  <span style={{ color: "red" }}>{t("Out of stock")}</span>
                ) : (
                  <span>
                    {t("Available")}: {option.quantityInStock}
                  </span>
                )}

                {option.barcode && (
                  <>
                    <br />
                    {option.barcode}
                  </>
                )}
                {option.sku && (
                  <>
                    <br />
                    {option.sku}
                  </>
                )}
              </span>
            }
          />
        </ListItemButton>
      </div>
    );
  }
);

export default memo(ProductSearch);
