import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import axios from "axios-config";
import { patchDevice } from "api/repair-orders";

const usePatchDeviceName = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(patchDevice, {
    onMutate: async ({ repairOrderId, brand, model }) => {
      await queryClient.cancelQueries(["repairOrder", repairOrderId]);

      const previousOrder = queryClient.getQueryData([
        "repairOrder",
        repairOrderId,
      ]);

      queryClient.setQueryData(["repairOrder", repairOrderId], (old) => ({
        ...old,
        brand: brand,
        model: model,
      }));

      return { previousOrder };
    },
    onError: (error, variables, context) => {
      if (context?.previousOrder) {
        queryClient.setQueryData(
          ["repairOrder", variables.repairOrderId],
          context.previousOrder
        );
      }
      enqueueSnackbar("Failed to update field", { variant: "error" });
    },
    onSuccess: () => {
      enqueueSnackbar("Field updated successfully", { variant: "success" });
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(["repairOrder", variables.repairOrderId]);
    },
  });
};

export default usePatchDeviceName;
