import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import axios from "axios-config";
import { pathServiceField } from "api/repair-orders";

const useUpdateServiceField = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ repairOrderId, serviceId, key, value }) =>
      pathServiceField({ repairOrderId, serviceId, key, value }),
    {
      onMutate: async ({ repairOrderId, serviceId, key, value }) => {
        await queryClient.cancelQueries(["repairOrder", repairOrderId]);

        const previousOrder = queryClient.getQueryData([
          "repairOrder",
          repairOrderId,
        ]);
        if (!previousOrder) {
          console.log("previousOrder is null");
        }
        queryClient.setQueryData(["repairOrder", repairOrderId], (old) => ({
          ...old,
          //services key
          services: old.services.map((service) =>
            service.id === serviceId
              ? {
                  ...service,
                  [key]: value,
                }
              : service
          ),
        }));

        return { previousOrder };
      },
      onError: (error, variables, context) => {
        if (context?.previousOrder) {
          queryClient.setQueryData(
            ["repairOrder", variables.repairOrderId],
            context.previousOrder
          );
        }
        enqueueSnackbar("Failed to update field", { variant: "error" });
      },
      onSuccess: () => {
        enqueueSnackbar("Field updated successfully", { variant: "success" });
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(["repairOrder", variables.repairOrderId]);
      },
    }
  );
};

export default useUpdateServiceField;
