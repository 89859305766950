import * as React from "react";
import {
  Stack,
  Box,
  TextField,
  Divider,
  Typography,
  Button,
  IconButton,
  Popover,
  InputAdornment,
  Grid,
  Skeleton,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useMediaQuery,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useState } from "react";
import axios from "../../axios-config";
import { useSnackbar } from "notistack";
import DeviceImage from "../../components/DeviceImage/DeviceImage";
import CategorySearch from "../../components/NewEntryStepper/CategorySearch";
import ReactiveTextField from "../../components/ReactiveTextField/ReactiveTextField";
import Section from "../../components/Section/Section";
import OutsourcePartnerSelect from "../../components/OutsourcePartnerSelect";

//ICONS
import EditIcon from "@mui/icons-material/ModeEdit";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import {
  FaceOutlined,
  GroupOutlined,
  LocationCityOutlined,
  DocumentScannerOutlined,
  AnnouncementOutlined,
  StickyNote2Outlined,
  WarningAmberRounded,
  LocationOnOutlined,
  ArrowDownwardOutlined,
  ExpandMoreOutlined,
  CloseRounded,
  PermIdentityRounded,
} from "@mui/icons-material";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import CustomersSearch from "../../components/NewEntryStepper/CustomersSearch";
import { getCurrencySymbol } from "../../utils/currency";
import PasswordTextField from "components/NewEntryStepper/PasswordTextField";
import useUpdateRepairField from "hooks/mutations/orders/useUpdateRepairField";
import usePatchDeviceName from "hooks/mutations/orders/usePatchDeviceName";
import EdtiableNumberSoft from "./EdtiableNumberSoft";
import EditableTextSoft from "./EditableTextSoft";
import { grey } from "@mui/material/colors";
import LabelTypography from "./LabelTypography";
import PasswordFieldV2 from "./EditablePasswordV2";
import EditablePasswordV2 from "./EditablePasswordV2";
import usePatchRepairCustomer from "hooks/mutations/orders/usePatchCustomer";
import usePatchRepairDeviceCategory from "hooks/mutations/orders/usePatchRepairCategory";
import CategoryButton from "./CategoryButton";
import usePatchRepairPassword from "hooks/mutations/orders/usePatchRepairPassword";
import DeleteDialogWarningGeneric from "common/DeleteDialogWarningGeneric";
import EditCustomerDialog from "./Dialogs/EditCustomerDialog";
import usePatchCustomerData from "hooks/mutations/orders/usePatchCustomerData";

const OrderEditGeneralV2 = ({
  // repairOrder,
  repairOrderId,
  brand,
  model,
  malfunction,
  note,
  imei,
  estimatedServices,
  password,
  isPasswordPattern,
  deviceCategory,
  outsourcedFrom,
  customer,
  parent,
}) => {
  const currency = useSelector((state) => state.auth.currency);

  const { t } = useTranslation();

  const [editDeviceDialogOpen, setEditDeviceDialogOpen] = useState(false);

  const [openDeleteCustomerDialog, setOpenDeleteCustomerDialog] =
    useState(false);
  const [categoryAnchor, setCategoryAnchor] = React.useState(null);
  const categoryOpen = Boolean(categoryAnchor);

  const updateRepairField = useUpdateRepairField();

  const patchCustomer = usePatchRepairCustomer();

  const patchRepairDeviceCategory = usePatchRepairDeviceCategory();
  const onCustomerSelect = async (customer) => {
    patchCustomer.mutate({ repairOrderId, customer });
  };
  // const openCategoryPopover = async (event) => {
  //   setCategoryAnchor(event.currentTarget);
  // };

  const openDeviceEdit = () => {
    setEditDeviceDialogOpen(true);
  };

  const handleDeviceCategoryUpdate = (selectedCategory) => {
    patchRepairDeviceCategory.mutate({
      repairOrderId,
      deviceCategory: selectedCategory,
    });
  };

  // /:repairOrderId/customer

  const outsourcedFromSection2 = () => {
    if (outsourcedFrom) {
      const partner = outsourcedFrom.organization;
      return (
        <Box
        //   border={"1px solid"}
        //   borderColor={grey[400]}
        //   p={1}
        //   borderRadius={1}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <GroupOutlined color={"black"} sx={{ mr: "5px" }} />
            <Typography fontSize="1" alignItems={"center"} display={"inline"}>
              {partner.name}
            </Typography>
          </Stack>
          {partner?.city?.length > 0 ? (
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <LocationOnOutlined color={"black"} sx={{ mr: "5px" }} />
              <Typography>{partner.city}</Typography>
            </Stack>
          ) : null}
        </Box>
      );
    }
  };

  const handleFieldUpdate = (key, newValue) => {
    updateRepairField.mutate({ id: repairOrderId, key, value: newValue });
  };

  const handleRemovePattern = () => {
    patchRepairPassword.mutate({
      repairOrderId,
      password: "",
      isPasswordPattern: false,
    });
  };

  const patchRepairPassword = usePatchRepairPassword();

  const updatePassword = ({ password, isPasswordPattern }) => {
    patchRepairPassword.mutate({
      repairOrderId,
      password,
      isPasswordPattern: isPasswordPattern,
    });
  };

  return (
    <>
      <Stack sx={{ px: "20px", py: "20px" }} spacing={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={1}
          sx={{
            backgroundColor: grey["A100"],
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          {/* <LabelTypography text={t("Device")} /> */}
          {/* {device()} */}
          <DeviceImage
            hideIfNoImage
            brand={brand}
            // height={"50px"}
            // width={"70px"}
            // maxWidth={"50px"}
            // minWidth={"50px"}
          />
          <Stack alignItems={"flex-start"} spacing={0}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography variant="h5" fontWeight={"800"}>
                {brand} {model}
              </Typography>
              <IconButton onClick={openDeviceEdit} size="small">
                <EditIcon />
              </IconButton>
            </Stack>
            <CategoryButton
              deviceCategory={deviceCategory}
              onCategoryUpdate={handleDeviceCategoryUpdate}
            />
          </Stack>
        </Stack>
        {/* <Stack direction={"row"} alignItems={"center"}>
          <LabelTypography text={t("Category")} />

        
        </Stack> */}
        <Stack direction={"row"} alignItems={"start"}>
          <LabelTypography text={t("Customer")} />
          <Stack
            alignItems={"flex-start"}
            direction={"column"}
            spacing={1}
            // alignItems={{ xs: "start", sm: "end" }}
          >
            {outsourcedFrom ? null : (
              <ClientInput
                repairOrderId={repairOrderId}
                parent={parent}
                customer={customer}
                onCustomerSelect={onCustomerSelect}
              />
            )}
            {outsourcedFromSection2()}
          </Stack>
        </Stack>
        <Popover
          open={categoryOpen}
          anchorEl={categoryAnchor}
          onClose={() => {
            setCategoryAnchor(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box p={2} width={"250px"}>
            {/* <CategorySearch
              onCategoryUpdate={onCategoryUpdate}
              value={deviceCategory}
            /> */}
          </Box>
        </Popover>

        <Stack direction={"row"}>
          <LabelTypography text={t("Fields")} />
          <StyledTable>
            <StyledTableBody>
              <StyledTableRow>
                <LeftTableCell>
                  <Typography variant="body2" color={grey[800]}>
                    {t("Estimated services")}
                  </Typography>
                </LeftTableCell>
                <StyledTableCell>
                  <EdtiableNumberSoft
                    fullWidth
                    value={estimatedServices || ""}
                    onUpdate={(value) =>
                      handleFieldUpdate("estimatedServices", value)
                    }
                    placeholder="0.00"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {getCurrencySymbol(currency)}
                        </InputAdornment>
                      ),
                    }}
                    onlyFloat
                    onlyPositive
                    withinRange={[0, 999999]}
                  />
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <LeftTableCell>
                  <Typography variant="body2" color={grey[800]}>
                    {t("Imei")}
                  </Typography>{" "}
                </LeftTableCell>
                <StyledTableCell>
                  <EditableTextSoft
                    fullWidth
                    label={t("Imei")}
                    placeholder={t("Enter IMEI")}
                    value={imei}
                    onUpdate={(value) => handleFieldUpdate("imei", value)}
                  />
                </StyledTableCell>
              </StyledTableRow>
              {/* <StyledTableRow>
                <LeftTableCell>
                  <Typography variant="body2" color={grey[800]}>
                    {t("Password")}
                  </Typography>
                </LeftTableCell>
                <StyledTableCell>
                  <EditablePasswordV2
                    password={password}
                    isPasswordPattern={isPasswordPattern}
                    onChange={updatePassword}
                    removePattern={handleRemovePattern}
                  />
                </StyledTableCell>
              </StyledTableRow> */}
            </StyledTableBody>
          </StyledTable>
        </Stack>
        <Stack direction={"row"} flex={1}>
          <LabelTypography
            text={t("Malfunction")}
            icon={<WarningAmberRounded fontSize="10px" color={"black"} />}
          />

          <Stack spacing={2} flex={1}>
            <Tooltip
              title={
                outsourcedFrom
                  ? t("orderEditGeneral.cantEditWhenOutsourcedFromPartner")
                  : ""
              }
            >
              <Box component="span" sx={{ display: "block", width: "100%" }}>
                <EditableTextSoft
                  fullWidth
                  placeholder={t("What's malfunctioning?")}
                  value={malfunction}
                  onUpdate={(value) => handleFieldUpdate("malfunction", value)}
                  InputProps={{
                    multiline: true,
                    minRows: 2,
                    maxRows: 6,
                    overflow: "hidden",
                  }}
                  disabled={outsourcedFrom !== null}
                  sx={{
                    "& .MuiInputBase-root.Mui-disabled": {
                      opacity: 1,
                      backgroundColor: "inherit",
                      color: "black !important",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      color: "black !important",
                      WebkitTextFillColor: "black !important",
                    },
                  }}
                />
              </Box>
            </Tooltip>
            {/* <Stack flex={1}>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography variant="subtitle2" color={grey[900]}>
              {t("Note")}
              </Typography>
              </Stack>
              </Stack> */}
            <Divider />
          </Stack>
        </Stack>
        <Stack direction={"row"} flex={1}>
          <LabelTypography
            text={t("Note")}
            icon={<StickyNote2Outlined fontSize="10px" color={"black"} />}
          />
          <Tooltip
            title={
              outsourcedFrom ? t("Can't edit when repair is from partner.") : ""
            }
          >
            <Box component="span" sx={{ display: "block", width: "100%" }}>
              <EditableTextSoft
                fullWidth
                label={t("Note")}
                placeholder={t("Enter note")}
                value={note}
                onUpdate={(value) => handleFieldUpdate("note", value)}
                InputProps={{
                  multiline: true,
                  minRows: 2,
                  maxRows: 6,
                }}
                disabled={outsourcedFrom !== null}
                sx={{
                  "& .MuiInputBase-root.Mui-disabled": {
                    opacity: 1,
                    backgroundColor: "inherit",
                  },
                  // target native disabled input element
                  "& input:disabled": {
                    color: "black !important",
                    WebkitTextFillColor: "black !important",
                  },
                }}
              />
            </Box>
          </Tooltip>
        </Stack>
        <Stack direction={"row"} flex={1} alignItems={"center"}>
          <LabelTypography
            text={t("Password")}
            icon={<StickyNote2Outlined fontSize="10px" color={"black"} />}
          />
          <EditablePasswordV2
            password={password}
            isPasswordPattern={isPasswordPattern}
            onChange={updatePassword}
            removePattern={handleRemovePattern}
          />
        </Stack>
      </Stack>
      <DeleteDialogWarningGeneric
        dialogOpen={openDeleteCustomerDialog}
        handleDialogClose={() => setOpenDeleteCustomerDialog(false)}
        onDeleteAccept={() => {
          setOpenDeleteCustomerDialog(false);
          onCustomerSelect(null);
        }}
        title={t("dialogs.removeCustomer.title")}
        warningText={t("dialogs.removeCustomer.message")}
        confirmText={t("dialogs.removeCustomer.confirm")}
      />
      <EditDeviceDialog
        open={editDeviceDialogOpen}
        repairOrderId={repairOrderId}
        onClose={() => setEditDeviceDialogOpen(false)}
        brand={brand}
        model={model}
      />
    </>
  );
};

// Define a styled Table component
const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: 8, // Rounded corners
  overflow: "hidden",
  border: "1px solid #ccc", // Flat 1px border
  borderCollapse: "separate",
  borderSpacing: 0,
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  //   padding: 0,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td": {
    borderBottom: "none",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  borderBottom: "1px solid #ccc",
  // Add left border to all cells except the first.
  "&:not(:first-of-type)": {
    borderLeft: "1px solid #ccc",
  },
}));

// Separate styled components for left and right cells.
const LeftTableCell = styled(StyledTableCell)(({ theme }) => ({
  width: "30%", // Adjust this percentage as needed.
  whiteSpace: "nowrap", // Prevent label wrapping.
}));

const RightTableCell = styled(StyledTableCell)(({ theme }) => ({
  width: "70%", // Takes the remaining space.
}));

const EditDeviceDialog = ({ repairOrderId, open, onClose, brand, model }) => {
  const patchDeviceName = usePatchDeviceName();
  const [editDeviceData, setEditDeviceData] = useState({});
  const [editDeviceErrors, setEditDeviceErrors] = useState({});

  React.useEffect(() => {
    if (open) {
      setEditDeviceData({ brand, model });
    } else {
      setEditDeviceData({});
    }
  }, [open]);

  const handleDeviceInput = (key) => (event) => {
    setEditDeviceData((prevState) => {
      return { ...prevState, [key]: event.target.value };
    });
  };

  const validateNewDeviceInput = () => {
    let errors = {};
    if (!editDeviceData.brand) {
      errors.brand = "Brand is required";
    }
    if (!editDeviceData.model) {
      errors.model = "Model is required";
    }
    setEditDeviceErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleDeviceEdit = async () => {
    if (!validateNewDeviceInput()) {
      return;
    }
    if (brand === editDeviceData.brand && model === editDeviceData.model) {
      onClose();
      return;
    }
    patchDeviceName.mutate({
      repairOrderId: repairOrderId,
      brand: editDeviceData.brand,
      model: editDeviceData.model,
    });

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Device</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            variant="filled"
            fullWidth
            error={editDeviceErrors.brand ? true : false}
            helperText={editDeviceErrors.brand}
            sx={{ backgroundColor: "#F1F9FD" }}
            InputLabelProps={{ style: { fontWeight: 700 } }}
            label="Brand"
            value={editDeviceData.brand ? editDeviceData.brand : ""}
            onChange={handleDeviceInput("brand")}
          />
          <TextField
            variant="filled"
            fullWidth
            error={editDeviceErrors.model ? true : false}
            helperText={editDeviceErrors.model}
            sx={{ backgroundColor: "#F1F9FD" }}
            InputLabelProps={{ style: { fontWeight: 700 } }}
            label="Model"
            value={editDeviceData.model ? editDeviceData.model : ""}
            onChange={handleDeviceInput("model")}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={onClose}>
          Cancel
        </Button>
        <Button variant={"outlined"} onClick={handleDeviceEdit}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ClientInput = ({ repairOrderId, parent, customer, onCustomerSelect }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [editCustomer, setEditCustomer] = useState(false);
  const [openDeleteCustomerDialog, setOpenDeleteCustomerDialog] =
    useState(false);

  const patchCustomerData = usePatchCustomerData({
    cacheKeys: [["repairOrder", repairOrderId]],
  });
  const onOpenDeleteCustomerDialog = () => {
    setOpenDeleteCustomerDialog(true);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsSelecting(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsSelecting(false);
  };
  const handleNewCustomerData = ({ customer, key, value }) => {
    patchCustomerData.mutate({
      customerId: customer.id,
      key,
      value,
    });
  };

  if (customerLoading) {
    return <Skeleton variant="rounded" width={210} height={40} />;
  }
  if (!customer) {
    return (
      <>
        <Button
          variant="text"
          color="black"
          startIcon={
            <PermIdentityRounded
              sx={{
                border: "1px dashed black",
                borderRadius: "50%",
                padding: "0px",
              }}
            />
          }
          onClick={handleClick}
        >
          {t("No customer")}
        </Button>
        {isSelecting && (
          <Popover
            id="assign-task"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPaper-root": {
                minWidth: "400px", // Control width here
              },
            }}
          >
            <Grid
              component="div"
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <CustomersSearch onOptionSelect={onCustomerSelect} />
            </Grid>
          </Popover>
        )}
      </>
    );
  }

  const handleEditCustomerDialogClose = () => {
    setEditCustomer(false);
  };

  return (
    <>
      <Stack direction={"column"} alignItems={"flex-start"} spacing={1}>
        <Stack direction={"row"} spacing={1}>
          <Button
            color="black"
            variant="text"
            startIcon={<FaceOutlined />}
            onClick={() => setEditCustomer(true)}
          >
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "230px",
              }}
            >
              {customer?.name}
            </Typography>
          </Button>
          {parent ? null : (
            <IconButton size="small" onClick={onOpenDeleteCustomerDialog}>
              <CloseRounded />
            </IconButton>
          )}
        </Stack>
        {customer?.phone?.length > 0 ? (
          <Stack direction={"row"} alignItems={"center"}>
            <PhoneInTalkOutlinedIcon
              color={"black"}
              sx={{ fontSize: "1.2rem", mr: "5px" }}
            />
            <Typography mr={0}>{customer.phone}</Typography>
          </Stack>
        ) : null}
        {customer?.email?.length > 0 ? (
          <Stack direction={"row"} alignItems={"center"}>
            <EmailOutlinedIcon
              color={"black"}
              sx={{ fontSize: "1.2rem", mr: "5px" }}
            />
            <Typography mr={0}>{customer.email}</Typography>
          </Stack>
        ) : null}
      </Stack>
      <EditCustomerDialog
        dialogOpen={editCustomer}
        customer={customer}
        handleDialogClose={handleEditCustomerDialogClose}
        onEdit={handleNewCustomerData}
      />
      <DeleteDialogWarningGeneric
        dialogOpen={openDeleteCustomerDialog}
        handleDialogClose={() => setOpenDeleteCustomerDialog(false)}
        onDeleteAccept={() => {
          setOpenDeleteCustomerDialog(false);
          onCustomerSelect(null);
        }}
        title={t("dialogs.removeCustomer.title")}
        warningText={t("dialogs.removeCustomer.message")}
        confirmText={t("dialogs.removeCustomer.confirm")}
      />
    </>
  );
};

export default React.memo(OrderEditGeneralV2);
