import React from "react";

import {
  Stack,
  Chip,
  Typography,
  Box,
  Button,
  ButtonGroup,
} from "@mui/material";

import RepairedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeclinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import IrreproducibleIcon from "@mui/icons-material/HelpOutlineOutlined";
import IrreparableIcon from "@mui/icons-material/PendingOutlined";
import WarrantyIcon from "@mui/icons-material/GppGoodOutlined";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";

import { useTranslation } from "react-i18next";
import { repairResolutions } from "utils/repair-statuses-helpers";
import { requiresResolution, getStatusById } from "utils/status-options";
import useStatusUpdateLogic from "hooks/useStatusUpdateLogic";
import useUpdateOrderStatusAndResolutionMutation from "hooks/mutations/orders/useUpdateOrderStatusAndResolutionMutation";
import dayjs from "dayjs";
import useRemoveResolution from "hooks/mutations/orders/useRemoveResolution";
import FollowUpDialog from "../Dialogs/FollowUpDialog";

const ResolutionsSection = ({
  repairOrderId,
  children,
  outsourcedFrom,
  resolutionType,
  outsourcedTo,
  deliveredAt,
  resolutionAt,
}) => {
  const { t } = useTranslation();
  const [followUpOpen, setFollowUpOpen] = React.useState(false);
  const updateOrderStatusAndResolution =
    useUpdateOrderStatusAndResolutionMutation();

  const onResolutionSet = (resolution) => {
    const newStatus = getStatusById(9);
    updateOrderStatusAndResolution.mutate({
      repairOrderId: repairOrderId,
      status: newStatus,
      resolution: resolution,
    });
  };

  const handleOpenFollowUpRepairOrder = () => {
    setFollowUpOpen(true);
  };

  return (
    <>
      <Box px={"25px"} py={"25px"}>
        <Stack direction={"column"} spacing={1}>
          {outsourcedTo && !outsourcedTo.deliveredAt ? (
            t("The resolutions are handled by partner.")
          ) : resolutionType ? (
            <ChosenResolution
              repairOrderId={repairOrderId}
              resolutionType={resolutionType}
              deliveredAt={deliveredAt}
              resolutionAt={resolutionAt}
            />
          ) : (
            <Resolutions onResolutionSet={onResolutionSet} />
          )}
          {resolutionType && deliveredAt && !outsourcedFrom && !children ? (
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography>
                {t("New entry of the same problem or a possible warranty?")}
              </Typography>
              <Button
                size="small"
                endIcon={<KeyboardDoubleArrowRightRoundedIcon />}
                onClick={handleOpenFollowUpRepairOrder}
              >
                {t("Follow up repair")}
              </Button>
            </Stack>
          ) : null}
        </Stack>

        {/* {repairOrder.invoice ? (
      <Typography>
      Invoiced to reference: #{repairOrder.invoice.refId}
      </Typography>
      ) : null} */}
      </Box>

      <FollowUpDialog
        repairOrderId={repairOrderId}
        open={followUpOpen}
        onClose={() => setFollowUpOpen(false)}
      />
    </>
  );
};

const resolutionIcons = {
  Repaired: { icon: <RepairedIcon />, color: "primary" },
  Declined: { icon: <DeclinedIcon />, color: "red" },
  Irreparable: { icon: <IrreparableIcon />, color: "pink" },
  Irreproducible: { icon: <IrreproducibleIcon />, color: "danube" },
  Warranty: { icon: <WarrantyIcon />, color: "darkBlue" },
};

const Resolutions = ({ onResolutionSet }) => {
  const keys = Object.keys(repairResolutions);
  const { t } = useTranslation();

  return (
    <>
      {/* <Typography fontWeight={'500'} fontSize={'1.35rem'} lineHeight={'2'}> */}
      <Typography variant="h5">
        {t("Complete the repair by choosing a resolution")}:
      </Typography>
      <Stack direction="row" flexWrap={"wrap"} gap={2}>
        {keys.map((key) => (
          <Chip
            color={resolutionIcons[key].color}
            index={repairResolutions[key].id}
            icon={resolutionIcons[key].icon}
            variant={"outlined"}
            key={repairResolutions[key].id}
            sx={{ fontWeight: "500" }}
            label={t(repairResolutions[key].name)}
            onClick={() => onResolutionSet(repairResolutions[key])}
          />
        ))}
      </Stack>
    </>
  );
};

const ChosenResolution = ({
  repairOrderId,
  resolutionType,
  deliveredAt,
  resolutionAt,
}) => {
  const resolutionDate = dayjs(resolutionAt).format("DD MMM. YYYY");
  const { t } = useTranslation();
  const removeResolution = useRemoveResolution();
  const handleRemoveResolution = () => {
    removeResolution.mutate({ repairOrderId: repairOrderId });
  };
  return (
    <Stack>
      <Stack direction={"row"} alignItems={"center"} gap={2} flexWrap={"wrap"}>
        <Typography variant="h6">
          {t("Repair completed with the resolution")}:
        </Typography>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Chip
            color={resolutionIcons[resolutionType.name].color}
            icon={resolutionIcons[resolutionType.name].icon}
            variant={"outlined"}
            label={t(resolutionType.name)}
            onDelete={deliveredAt ? null : handleRemoveResolution}
          />
          <Typography variant="body2">{resolutionDate}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ResolutionsSection;
