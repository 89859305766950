import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import ServicesList from "../../containers/EditOrder/ServicesList";
import ServiceItemListShort from "../ItemList/ServiceItemListShort";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getAcceptedAndRefundedServices,
  processService,
  servicesCalculation,
} from "../../utils/service-helpers";
import StyledToggleButtonGroup from "../StyledToggleButtonGroup/StyledToggleButtonGroup";
import { useCurrencyFormatter } from "hooks/useCurrencyFormatter";

const BudgetCell = ({ rowData }) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencyFormatter();

  const currency = useSelector((state) => state.auth.currency);
  const [partnerServices, setPartnerServices] = useState(null);
  const [processedServices, setProcessedServices] = useState(null);
  const countAcceptedService = () => {
    let accepted = 0;
    rowData.services.forEach((service) => {
      if (service.isAccepted) {
        accepted++;
      }
    });
    return accepted;
  };
  const countDeclinedService = () => {
    let declined = 0;
    rowData.services.forEach((service) => {
      if (service.isAccepted == false) {
        declined++;
      }
    });
    return declined;
  };

  useEffect(() => {
    if (rowData.outsourcedTo?.services) {
      setPartnerServices(rowData.outsourcedTo.services);
    }
    const process = servicesCalculation(rowData.services);
    setProcessedServices(process);
  }, [rowData]);
  if (rowData.isRequest) {
    return null;
  }

  // Order is just in our organization
  // const acceptedAndRefundedServices = rowData.services.filter(service => service.isAccepted || service.refundedServiceId);
  // const acceptedAndRefundedServicesProcessed = servicesCalculation(acceptedAndRefundedServices);
  const acceptedAndRefundedServices = getAcceptedAndRefundedServices(
    rowData.services
  );
  const acceptedAndRefundedServicesProcessed =
    acceptedAndRefundedServices.reduce((acc, service) => {
      const processed = processService(service);
      return (acc +=
        processed.unitPrice * processed.quantity - processed.discount);
    }, 0);

  const declinedAndProposedServices = rowData.services.filter(
    (service) =>
      (!service.isAccepted || service.isAccepted === null) &&
      !service.refundedServiceId
  );
  const declinedAndProposedServicesProcessed = servicesCalculation(
    declinedAndProposedServices
  );

  //In case the order is outsourced to a partner
  const acceptedAndRefundedServicesByPartner =
    rowData.outsourcedTo?.services?.filter(
      (service) => service.isAccepted || service.refundedServiceId
    );
  const acceptedAndRefundedServicesByPartnerProcessed =
    acceptedAndRefundedServicesByPartner?.reduce((acc, service) => {
      const processed = processService(service);
      return (acc +=
        processed.unitPrice * processed.quantity - processed.discount);
    }, 0);

  const declinedAndProposedServicesByPartner =
    rowData.outsourcedTo?.services?.filter(
      (service) =>
        (!service.isAccepted || service.isAccepted === null) &&
        !service.refundedServiceId
    );
  const declinedAndProposedServicesByPartnerProcessed =
    declinedAndProposedServicesByPartner?.reduce((acc, service) => {
      const processed = processService(service);
      return (acc +=
        processed.unitPrice * processed.quantity - processed.discount);
    }, 0);

  return (
    <Box
      className={""}
      overflow={"auto"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {acceptedAndRefundedServices.length > 0 ? (
        <>
          {" "}
          <Typography variant="caption" fontWeight={600}>
            {t("Services")}:{" "}
            {formatCurrency(acceptedAndRefundedServicesProcessed, currency)}
          </Typography>
          <ServicesList
            servicesListItems={acceptedAndRefundedServices?.map((service) => (
              <ServiceItemListShort
                key={service.id}
                service={service}
                // onAccept={onServiceAccept}
                // setServices={setServices}
              />
            ))}
            // onSolutionUpdate={setRepairOrder}
            sx={{ mb: "5px" }}
          />
        </>
      ) : null}
      {declinedAndProposedServices.length > 0 ? (
        <>
          {" "}
          <Typography variant="caption" fontWeight={600}>
            {t("Proposed services")}:{" "}
            {formatCurrency(
              declinedAndProposedServicesProcessed.totalProposed,
              currency
            )}
          </Typography>
          <ServicesList
            servicesListItems={declinedAndProposedServices?.map((service) => (
              <ServiceItemListShort
                key={service.id}
                service={service}
                // onAccept={onServiceAccept}
                // setServices={setServices}
              />
            ))}
            // onSolutionUpdate={setRepairOrder}
            sx={{ mb: "5px" }}
          />
        </>
      ) : null}
      {/* {rowData.services?.length > 2 ? `${rowData.services.length - 2} more` : null} */}
      {/* <Stack direction={'row'} alignItems={'start'} justifyContent={'flex-start'} gap={1} >
            <Stack>
                <Box>
                    Our Services
                </Box>
                <Stack direction={'row'} gap={1}>
                    <ServiceCard name={"cambio pantalla org"} price={'75.00'} />
                    <ServiceCard name={"Cambio tapa trasera compatible"} price={'35.00'} />
                    <ServiceCard name={"cambio Micro SD Card Reader Slot Flex"} price={'40.00'} />
                </Stack>
            </Stack>
        </Stack> */}

      {/* From Partner */}
      {acceptedAndRefundedServicesByPartner?.length > 0 ? (
        <>
          <Typography variant="caption" fontWeight={600}>
            {t("Partner services")}:{" "}
            {formatCurrency(
              acceptedAndRefundedServicesByPartnerProcessed,
              currency
            )}
          </Typography>
          <ServicesList
            servicesListItems={acceptedAndRefundedServicesByPartner?.map(
              (service) => (
                <ServiceItemListShort
                  key={service.id}
                  service={service}
                  // onAccept={onServiceAccept}
                  // setServices={setServices}
                />
              )
            )}
            // onSolutionUpdate={setRepairOrder}
            sx={{ mb: "5px" }}
          />
        </>
      ) : null}

      {declinedAndProposedServicesByPartner?.length > 0 ? (
        <>
          <Typography variant="caption" fontWeight={600}>
            {t("Partner proposed services")}{" "}
            {formatCurrency(
              declinedAndProposedServicesByPartnerProcessed,
              currency
            )}
          </Typography>
          <ServicesList
            servicesListItems={declinedAndProposedServicesByPartner?.map(
              (service) => (
                <ServiceItemListShort
                  key={service.id}
                  service={service}
                  // onAccept={onServiceAccept}
                  // setServices={setServices}
                />
              )
            )}
            // onSolutionUpdate={setRepairOrder}
            sx={{ mb: "5px" }}
          />
        </>
      ) : null}
    </Box>
  );
};

export default BudgetCell;
