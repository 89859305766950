// useStatusLogic.js
import { useState } from "react";
import ResolutionSelectDialog from "components/DataTable/ResolutionSelectDialog";
import ConsoleHelper from "utils/ConsoleHelper";
import useRepairStatusUpdate from "hooks/mutations/orders/useRepairStatusUpdate";
import useUpdateOrderStatusAndResolution from "hooks/mutations/orders/useUpdateOrderStatusAndResolutionMutation";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "common/ConfirmDialog";
import { Alert, AlertTitle, Typography } from "@mui/material";
import PropTypes from "prop-types";

/**
 * Custom hook to handle status updates for a repair order.
 *
 * @param {number} repairOrderId - The repair order ID.
 * @param {Object} resolutionType - The resolution type object.
 * @param {Array} queyKeys - An array of query keys used for updating the status.
 * @param {Function} callback - A callback function to be executed
 */

const useStatusUpdateLogic = (
  repairOrderId,
  resolutionType,
  queyKeys,
  callback
) => {
  const { t } = useTranslation();

  const [statusSelected, setStatusSelected] = useState(null);
  const updateStatus = useRepairStatusUpdate(queyKeys);
  const updateStatusAndResolution = useUpdateOrderStatusAndResolution(queyKeys);
  const [resolutionSelectDialogOpen, setResolutionSelectDialogOpen] =
    useState(false);
  const [resolutionRemoveDialogOpen, setResolutionRemoveDialogOpen] =
    useState(false);

  const onStatusSelect = (status) => {
    console.log("======== START useStatusUpdateLogic =======");
    console.log("status", status);
    console.log("resolutionType", resolutionType);
    console.log("======== END useStatusUpdateLogic =======");

    if (status.requiresResolution && !resolutionType) {
      ConsoleHelper("requires resolution");
      setStatusSelected(status);
      setResolutionSelectDialogOpen(true);
      return;
    }
    if (!status.requiresResolution && resolutionType) {
      setStatusSelected(status);
      setResolutionRemoveDialogOpen(true);
      return;
    }
    handleStatusChange(status);
  };

  const handleStatusChange = (status) => {
    updateStatus.mutate({
      repairOrderId: repairOrderId,
      status: status,
    });
    if (callback) {
      callback(status);
    }
  };

  const handleStatusChangeWithResolution = (status, resolution) => {
    updateStatusAndResolution.mutate({
      repairOrderId: repairOrderId,
      status: status,
      resolution: resolution,
    });
    //TODO: remove this temporary callback, used for EditOrderPage until we finish the react-query refactor
    if (callback) {
      callback(status, resolution);
    }
  };

  const handleResolutionSelect = (resolution) => {
    const status = statusSelected;
    setStatusSelected(null);
    handleStatusChangeWithResolution(status, resolution);
    setResolutionSelectDialogOpen(false);
  };

  const handleResolutionRemoveConfirm = (event) => {
    event.stopPropagation();
    handleStatusChange(statusSelected);
    setStatusSelected(null);
    setResolutionRemoveDialogOpen(false);
  };

  const handleResolutionRemoveCancel = (event) => {
    event.stopPropagation();
    setResolutionRemoveDialogOpen(false);
  };

  const handleResolutionDialogClose = (event, reason) => {
    event.stopPropagation();
    setResolutionSelectDialogOpen(false);
  };

  const ResolutionDialogs = (
    <>
      <ResolutionSelectDialog
        open={resolutionSelectDialogOpen}
        onClose={(event, reason) => handleResolutionDialogClose(event, reason)}
        handleResolutionSelect={handleResolutionSelect}
      />
      <ConfirmationDialog
        title={t("dialogs.removeResolution.title")}
        message={
          <Alert severity="warning">
            <AlertTitle>{t("alert.warningTitle")}</AlertTitle>
            <Typography variant="body1">
              {t("dialogs.removeResolution.message")}
            </Typography>
          </Alert>
        }
        open={resolutionRemoveDialogOpen}
        onClose={handleResolutionRemoveCancel}
        onConfirm={handleResolutionRemoveConfirm}
      />
    </>
  );

  return {
    onStatusSelect,
    ResolutionDialogs,
  };
};

useStatusUpdateLogic.propTypes = {
  repairOrderId: PropTypes.number.isRequired,
  resolutionType: PropTypes.object,
  queyKeys: PropTypes.array.isRequired,
  callback: PropTypes.func,
};

export default useStatusUpdateLogic;
