// useUpdateOrderStatusMutation.js
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { patchRepairDeviceCategory } from "api/repair-orders";

const usePatchRepairDeviceCategory = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ repairOrderId, deviceCategory }) => {
      const deviceCategoryId = deviceCategory ? deviceCategory.id : null;
      return patchRepairDeviceCategory({ repairOrderId, deviceCategoryId });
    },
    {
      onMutate: async ({ repairOrderId, deviceCategory }) => {
        // Cancel any outgoing refetches
        await queryClient.cancelQueries({
          queryKey: ["repairOrder", repairOrderId],
        });

        // Snapshot the previous data
        const previousOrder = queryClient.getQueryData([
          "repairOrder",
          repairOrderId,
        ]);

        if (!previousOrder) {
          console.error("No previous order found");
          console.log("repairOrderId", repairOrderId);
          return;
        }

        // Optimistically update the repair order with the new comment
        queryClient.setQueryData(["repairOrder", repairOrderId], (old) => ({
          ...old,
          updatedAt: dayjs().toISOString(),
          deviceCategory: deviceCategory,
          deviceCategoryId: deviceCategory ? deviceCategory.id : null,
        }));

        // Return context with the previous order for rollback
        return { previousOrder };
      },
      onError: (error, variables, context) => {
        if (context?.previousOrder) {
          queryClient.setQueryData(
            ["repairOrder", variables.repairOrderId],
            context.previousOrder
          );
        }
        console.log(error.response);
        console.error(
          error?.response?.data?.message || "Error updating customer"
        );
        enqueueSnackbar("Failed to update customer", { variant: "error" });
      },
      onSuccess: (data, variables, context) => {
        enqueueSnackbar("Customer updated successfully", {
          variant: "success",
        });
      },
      onSettled: (data, error, variables) => {
        // queryClient.invalidateQueries(["repairOrder", variables.repairOrderId]);
      },
    }
  );
};

export default usePatchRepairDeviceCategory;
