import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { useState, memo } from "react";
import PatternLock from "react-17-pattern-lock";
import "./PatternStyle.css";
import { useTranslation } from "react-i18next";

const PatternLockDialog = ({ open, onClose, onAction }) => {
  const { t } = useTranslation();
  const [path, setPath] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const handleChange = (newPath) => {
    setPath(newPath);
  };
  const reset = () => {
    setPath([]);
    setDisabled(false);
  };
  const onDialogClose = () => {
    onClose();
    reset();
  };
  const onActionClick = () => {
    const pathValue = path.map((index) => index + 1).join("");
    onAction(pathValue);
    reset();
  };

  return (
    <Dialog
      onClose={onDialogClose}
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>{t("dialogs.patternLock.title")}</DialogTitle>
      <Stack direction={"column"} alignItems={"center"}>
        <p>
          {path.length == 0 && "-"} {path.map((index) => index + 1).join("")}
        </p>
      </Stack>
      <PatternLock
        path={path}
        width={280}
        size={3}
        disabled={disabled}
        onChange={handleChange}
        onFinish={() => {
          if (path.length < 3) {
            setPath([]);
            return;
          }
          setDisabled(true);
          // setValue(path.map((index) => index + 1).join(""));
          // setIsPasswordPattern(true);
        }}
        style={{
          margin: "0 auto",
        }}
      />
      <Stack direction={"column"} alignItems={"center"}>
        <Button startIcon={<Refresh />} onClick={reset}>
          {t("dialogs.patternLock.reset")}
        </Button>
      </Stack>
      <DialogActions>
        <Button
          onClick={onDialogClose}
          // variant="contained"
          size="small"
          sx={{ borderRadius: "4px" }}
        >
          {t("dialogs.patternLock.cancel")}
        </Button>
        <Button
          onClick={onActionClick}
          variant="contained"
          size="small"
          sx={{ borderRadius: "4px" }}
        >
          {t("dialogs.patternLock.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(PatternLockDialog);
