import React, { useState, useRef, useCallback, useEffect } from "react";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { useQuery } from "react-query";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import { RequestQuoteOutlined } from "@mui/icons-material";

import axios from "axios-config";
import ToolBarHelper from "../../components/ToolBarHelper/ToolBarHelper";
import PrintComponent from "../../components/Printing/PrintComponent";
import ReminderDialog from "./Dialogs/ReminderDialog";
import RepairOrderLogPopover from "./RepairOrderLogPopover";
import LeftPanelSkeleton from "./LeftPanelSkeleton";
import RightPanelSkeleton from "./RightPanelSkeleton";
import LeftPanel from "./LeftPanel/LeftPanel";
import RightPanel from "../RepairOrderGeneral/RightPanel";
import QuoteCreatorDialog from "./Dialogs/QuoteCreatorDialog";

import {
  customerToInvoiceData,
  organizationToInvoiceData,
  servicesToItems,
} from "../../utils/InvoiceHelpers";
import { formatCurrency } from "../../utils/currency";
import { fetchRepairOrder } from "api/repair-orders";

import "./service-table.css";

const EditOrderPage = React.memo(() => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // Printing reference
  const printRef = useRef(null);

  // Local state
  const [isCopied, setIsCopied] = useState(false);
  const [rightPanelBeta, setRightPanelBeta] = useState(() => {
    const stored = window.localStorage.getItem("rightPanelBeta");
    return stored ? JSON.parse(stored) : false;
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [estimateDialogOpen, setEstimateDialogOpen] = useState(false);
  const [organization, setOrganization] = useState({});
  const [repairPayments, setRepairPayments] = useState([]);
  const [repairLogAnchor, setRepairLogAnchor] = useState(null);

  const reminderPopoverOpen = Boolean(anchorEl);
  const currency = useSelector((state) => state.auth.currency);

  // Route params
  const repairOrderId = parseInt(useParams().repairOrderId, 10);
  useEffect(() => {
    window.localStorage.setItem(
      "rightPanelBeta",
      JSON.stringify(rightPanelBeta)
    );
  }, [rightPanelBeta]);
  // Fetch repair order via react-query
  const { data: repairOrder, isLoading } = useQuery(
    ["repairOrder", repairOrderId],
    () => fetchRepairOrder({ repairOrderId }),
    {
      retry: 1,
      refetchInterval: 5000,
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onError: (err) => {
        const errorMessage =
          err.response?.data?.message || "Something went wrong!";
        if (err.response?.status === 404) {
          navigate("/");
        }
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
    }
  );

  // Reminder popover handlers
  const handleReminderOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleReminderClose = () => {
    setAnchorEl(null);
  };

  // Repair log popover handlers
  const closeRepairLogs = () => {
    setRepairLogAnchor(null);
  };
  const openRepairLogsPopover = (event) => {
    setRepairLogAnchor(event.currentTarget);
  };

  // Copy to clipboard
  const handleCopy = () => {
    if (!repairOrder) return;

    let clipboardContent = "";
    const ref = repairOrder.outsourcedFrom
      ? `${repairOrder.outsourcedFrom.organization.id}#${repairOrder.outsourcedFrom.organizationCounter}`
      : `${repairOrder.organizationId}#${repairOrder.organizationCounter}`;

    clipboardContent += `Ref: ${ref}\n`;
    clipboardContent += `${t("Device")}: ${repairOrder.brand} ${
      repairOrder.model
    }\n`;
    clipboardContent += `${t("Malfunction")}: ${repairOrder.malfunction}\n`;

    if (repairOrder.services) {
      clipboardContent += `${t("Services")}: [\n`;
      repairOrder.services.forEach((service) => {
        const priceValue =
          service.unitPrice * service.quantity - service.discount;
        const priceStr = formatCurrency(priceValue, currency);
        clipboardContent += `         ${service.name}, ${priceStr}\n`;
      });
      clipboardContent += `]`;
    }
    navigator.clipboard.writeText(clipboardContent);

    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  // Print
  const handleOnBeforeGetContent = async () => {
    // Fetch payments before printing
    const paymentsResponse = await axios.get(
      `/repair-orders/${repairOrder.id}/payments`
    );
    if (paymentsResponse.status === 200) {
      setRepairPayments(paymentsResponse.data);
    }
    // Fetch org data
    const orgResponse = await axios.get(`/organizations`);
    if (orgResponse.status === 200 || orgResponse.status === 201) {
      setOrganization(orgResponse.data);
    }
  };

  const reactToPrintContent = useCallback(() => printRef.current, [printRef]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Ticket",
    onBeforeGetContent: handleOnBeforeGetContent,
  });

  // Quick invoice creation
  const handleQuickInvoice = async () => {
    try {
      if (!repairOrder?.customer) {
        enqueueSnackbar(t("No customer selected."), { variant: "error" });
        return;
      }
      // get accepted services
      const servicesResponse = await axios.get(
        `/repair-orders/${repairOrder.id}/services`
      );
      if (servicesResponse.status !== 200) {
        throw new Error();
      }
      const acceptedServices = servicesResponse.data.services.filter(
        (s) => s.isAccepted
      );
      if (acceptedServices.length === 0) {
        enqueueSnackbar(
          t("Unable to invoice. There are no accepted services."),
          { variant: "error" }
        );
        return;
      }
      // get last invoice reference
      const lastInvoiceResponse = await axios.get("invoices/last");
      if (lastInvoiceResponse.status !== 200) {
        throw new Error();
      }
      let refId = lastInvoiceResponse.data?.refId
        ? lastInvoiceResponse.data.refId + 1
        : 1;

      // get org data
      const orgResponse = await axios.get("/organizations");
      if (orgResponse.status !== 200) {
        throw new Error();
      }

      // Build invoice data
      const customerData = repairOrder.customer.taxIdentifier
        ? customerToInvoiceData(repairOrder.customer)
        : null;

      const orgData = organizationToInvoiceData(
        orgResponse.data,
        !customerData
      );

      if (!orgData) return;

      const items = servicesToItems(
        repairOrder,
        repairOrder.services.filter((s) => s.isAccepted)
      );
      if (!items) return;

      const dayjs = require("dayjs");
      const invoiceData = {
        refId,
        fileId: lastInvoiceResponse.data?.fileId,
        billingFrom: orgData,
        billingTo: customerData,
        invoiceTo: "customer",
        customerSelect: repairOrder.customer,
        partnersSelect: null,
        paymentInfo: null,
        terms: null,
        items,
        date: dayjs().toISOString(),
        taxIncluded: true,
        taxAmount: 21,
      };

      const newInvoiceResp = await axios.post(
        "/invoices/customer",
        invoiceData
      );
      if (newInvoiceResp?.status === 201) {
        enqueueSnackbar(t("Invoice created successfully!"), {
          variant: "success",
        });
      }
    } catch (err) {
      const message =
        err?.response?.data?.message || t("Something went wrong!");
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  // Quote creation
  const handleCreateQuote = () => setEstimateDialogOpen(true);
  const handleQuoteDialogClose = () => setEstimateDialogOpen(false);

  // Return skeleton while loading
  if (isLoading) {
    return (
      <Box className="container mainContainer docsContainer">
        <ToolBarHelper>
          <Stack
            direction="row"
            gap={1}
            flexWrap="wrap"
            alignItems="flex-start"
          >
            <Button
              color="black"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/")}
            >
              {t("Back")}
            </Button>
            <Stack direction="row" alignItems="center" gap={1}>
              <Skeleton variant="rectangular" height={36} width={100} />
              <Skeleton variant="rectangular" height={36} width={100} />
              <Skeleton variant="rectangular" height={36} width={100} />
              <Skeleton variant="rectangular" height={36} width={100} />
            </Stack>
          </Stack>
        </ToolBarHelper>

        <div className="content">
          <Box
            className="splitLeft"
            borderRight="1px solid"
            borderColor="lightGrey.main"
            backgroundColor="white"
          >
            <LeftPanelSkeleton />
          </Box>
          <Box
            className="splitRight"
            display="flex"
            flexDirection="column"
            minHeight="1px"
            sx={{ overflowY: "hidden" }}
          >
            <RightPanelSkeleton />
          </Box>
        </div>
      </Box>
    );
  }

  // Main UI once loaded
  return (
    <Box className="container mainContainer docsContainer">
      <ToolBarHelper>
        <Stack direction="row" gap={1} flexWrap="wrap" alignItems="flex-start">
          <Button
            color="black"
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/")}
          >
            {t("Back")}
          </Button>
          <Button
            color="black"
            variant="outlined"
            startIcon={<NotificationAddOutlinedIcon />}
            onClick={handleReminderOpen}
          >
            {t("New reminder")}
          </Button>
          <Button
            color="black"
            variant="outlined"
            startIcon={<LocalPrintshopOutlinedIcon />}
            onClick={handlePrint}
          >
            {t("Print ticket")}
          </Button>
          {repairOrder?.customer && (
            <Button
              color="black"
              variant="outlined"
              startIcon={<RequestQuoteOutlined />}
              onClick={handleCreateQuote}
            >
              {t("Create quote")}
            </Button>
          )}
          <Button
            color="black"
            onClick={handleCopy}
            variant="outlined"
            sx={{ color: isCopied ? "blue.main" : "secondary" }}
            startIcon={isCopied ? <DoneAllIcon /> : <ContentCopyIcon />}
          >
            {isCopied ? t("Copied to clipboard") : t("Copy order data")}
          </Button>
          {!repairOrder.outsourcedFrom && (
            <Button
              disabled={!repairOrder.customer}
              color="black"
              variant="outlined"
              onClick={handleQuickInvoice}
              startIcon={<PostAddOutlinedIcon />}
            >
              {t("Quick invoice")}
            </Button>
          )}
          <Button
            color="black"
            onClick={openRepairLogsPopover}
            variant="outlined"
            startIcon={<ManageHistoryOutlinedIcon />}
          >
            {t("View logs")}
          </Button>
          <Button
            color="black"
            onClick={() => setRightPanelBeta((prev) => !prev)}
            variant="outlined"
          >
            {t("editRepair.rightPanelBeta")}
          </Button>
        </Stack>

        <ReminderDialog
          open={reminderPopoverOpen}
          anchorEl={anchorEl}
          handleClose={handleReminderClose}
          repairOrderId={repairOrder.id}
          organizationCounter={repairOrder.organizationCounter}
        />
      </ToolBarHelper>

      <RepairOrderLogPopover
        closeRepairLogs={closeRepairLogs}
        anchor={repairLogAnchor}
        repairOrderId={repairOrderId}
      />

      <div className="content">
        {/* Hidden section for printing */}
        <div style={{ display: "none" }}>
          <PrintComponent
            ref={printRef}
            repairOrder={repairOrder}
            organization={organization}
            repairPayments={repairPayments}
          />
        </div>

        {/* Left Panel */}
        <Box
          className="splitLeft"
          borderRight="1px solid"
          borderColor="lightGrey.main"
          backgroundColor="white.main"
          sx={{ pb: "25px" }}
        >
          <LeftPanel
            services={repairOrder.services}
            repairOrderParts={repairOrder.repairOrderParts}
            repairPayments={repairOrder.repairPayments}
            parent={repairOrder.parent}
            outsourcedTo={repairOrder.outsourcedTo}
            outsourcedFrom={repairOrder.outsourcedFrom}
            children={repairOrder.children}
            repairOrderId={repairOrder.id}
            organizationId={repairOrder.organizationId}
            organizationCounter={repairOrder.organizationCounter}
            deliveredAt={repairOrder.deliveredAt}
            createdAt={repairOrder.createdAt}
            updatedAt={repairOrder.updatedAt}
            levelOfAttention={repairOrder.levelOfAttention}
            resolutionAt={repairOrder.resolutionAt}
            resolutionType={repairOrder.resolutionType}
            repairOrderStatus={repairOrder.repairOrderStatus}
            repairOrderOutsourceRequest={
              repairOrder.repairOrderOutsourceRequest
            }
            invoice={repairOrder.invoice}
          />
        </Box>

        {/* Right Panel */}
        <Box
          className="splitRight"
          display="flex"
          flexDirection="column"
          minHeight="1px"
          sx={{
            overflowY: "hidden",
            backgroundColor: "white.main",
            pb: "25px",
          }}
        >
          <RightPanel
            parent={repairOrder.parent}
            repairOrderId={repairOrder.id}
            outsourcedFromId={repairOrder.outsourcedFromId}
            outsourcedToId={repairOrder.outsourcedToId}
            outsourcedFrom={repairOrder.outsourcedFrom}
            outsourcedTo={repairOrder.outsourcedTo}
            customer={repairOrder.customer}
            comments={repairOrder.comments}
            brand={repairOrder.brand}
            model={repairOrder.model}
            malfunction={repairOrder.malfunction}
            note={repairOrder.note}
            imei={repairOrder.imei}
            estimatedServices={repairOrder.estimatedServices}
            password={repairOrder.password}
            isPasswordPattern={repairOrder.isPasswordPattern}
            deviceCategory={repairOrder.deviceCategory}
            deviceType={repairOrder.deviceType}
            deviceCategoryId={repairOrder.deviceCategoryId}
            rightPanelBeta={rightPanelBeta}
          />
        </Box>
      </div>

      {/* Quote Dialog */}
      {repairOrder?.customer && (
        <QuoteCreatorDialog
          open={estimateDialogOpen}
          onClose={handleQuoteDialogClose}
          customer={repairOrder?.customer}
          services={repairOrder?.services}
        />
      )}
    </Box>
  );
});

export default EditOrderPage;
