import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import axios from "axios-config";
import { patchRepairField } from "api/repair-orders";

const useUpdateRepairField = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ id, key, value }) => patchRepairField({ id, key, value }),
    {
      onMutate: async ({ id, key, value }) => {
        await queryClient.cancelQueries(["repairOrder", id]);

        const previousOrder = queryClient.getQueryData(["repairOrder", id]);

        queryClient.setQueryData(["repairOrder", id], (old) => ({
          ...old,
          [key]: value,
        }));

        return { previousOrder };
      },
      onError: (error, variables, context) => {
        if (context?.previousOrder) {
          queryClient.setQueryData(
            ["repairOrder", variables.id],
            context.previousOrder
          );
        }
        if (error?.response?.data?.message) {
          enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
        } else {
          enqueueSnackbar("Failed to update field", { variant: "error" });
        }
      },
      onSuccess: () => {
        enqueueSnackbar("Field updated successfully", { variant: "success" });
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(["repairOrder", variables.id]);
      },
    }
  );
};

export default useUpdateRepairField;
