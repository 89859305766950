import { useState, useEffect } from "react";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  ToggleButtonGroup,
  FormControl,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  ToggleButton,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { processService } from "../../../utils/service-helpers";
import axios from "../../../axios-config";
import { useSnackbar } from "notistack";

import { getCurrencySymbol } from "../../../utils/currency";
import { LoadingButton } from "@mui/lab";
import { PermIdentityRounded } from "@mui/icons-material";
import AssignUserButton from "components/AssignUserButton/AssignUserButton";
import { use } from "i18next";
import { z } from "zod";

import useAddService from "hooks/mutations/orders/useAddService";

const NewServiceTab = ({
  repairOrderId,
  resolutionType,
  handleAddServiceClose,
  isHandlingRequest,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [stock, setStock] = useState(null);

  const serviceSchema = z.object({
    name: z.string().min(1, "Service name is required"),
    quantity: z
      .union([z.string(), z.number()])
      .transform((val) => Number(val) || 0)
      .pipe(z.number().min(1, "At least 1")),
    unitPrice: z
      .union([z.string(), z.number()])
      .transform((val) => Number(val) || 0)
      .pipe(z.number().min(-Infinity, "Unit price can be negative")),
    discount: z
      .union([z.string(), z.number()])
      .optional()
      .transform((val) => Number(val) || 0),
  });

  const currency = useSelector((state) => state.auth.currency);

  const [name, setName] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [discount, setDiscount] = useState("");
  const [errors, setErrors] = useState({});

  const [assignedTo, setAssignedTo] = useState(null);
  // let's get the auth from redux
  const auth = useSelector((state) => state.auth);

  const nameChange = (event) => {
    setName(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, name: undefined }));
  };

  const unitPriceChange = (event) => {
    const input = event.target.value;
    const sanitized = input.replace(/[^0-9.]/g, "");
    // const sanitized = input.replace(/(?!^-)[^0-9.]/g, ""); // for negative values

    const parts = sanitized.split(".");
    if (parts.length > 2) {
      setUnitPrice(parts[0] + "." + parts[1]);
    } else {
      setUnitPrice(sanitized);
    }
    setErrors((prevErrors) => ({ ...prevErrors, name: undefined }));
  };

  const discountChange = (event) => {
    const input = event.target.value;
    const sanitized = input.replace(/[^0-9.]/g, "");
    // const sanitized = input.replace(/(?!^-)[^0-9.]/g, ""); // for negative values

    const parts = sanitized.split(".");
    if (parts.length > 2) {
      setDiscount(parts[0] + "." + parts[1]);
    } else {
      setDiscount(sanitized);
    }
    setErrors((prevErrors) => ({ ...prevErrors, discount: undefined }));
  };

  const quantityChange = (event) => {
    const input = event.target.value;
    const sanitized = input.replace(/[^0-9]/g, "");
    setErrors((prevErrors) => ({ ...prevErrors, quantity: undefined }));
    setQuantity(sanitized);
  };

  const addService = useAddService();

  const handleStockChange = (event, newStock) => {
    setStock(newStock);
  };

  const assignToMe = () => {
    if (auth.userName && auth.userId) {
      const user = { id: auth.userId, name: auth.userName, email: auth.email };
      onSelectUser(user);
    }
  };
  const onSelectUser = (user) => {
    setAssignedTo(user);
  };

  useEffect(() => {
    assignToMe();
  }, []);

  const handleAddNewService = () => {
    try {
      const parsedData = serviceSchema.parse({
        name: name,
        quantity: quantity,
        unitPrice: unitPrice,
        discount: discount,
      });

      addService.mutate({
        repairOrderId,
        unitPrice: parsedData.unitPrice,
        name: parsedData.name,
        quantity: parsedData.quantity,
        stock: stock === "null" ? null : stock,
        discount: parsedData.discount,
        assignedTo: assignedTo,
      });
      handleAddServiceClose();
    } catch (err) {
      if (err instanceof z.ZodError) {
        const fieldErrors = {};
        err.errors.forEach((error) => {
          fieldErrors[error.path[0]] = error.message;
        });
        setErrors(fieldErrors);
        enqueueSnackbar("Please fix the highlighted errors.", {
          variant: "warning",
        });
      } else {
        enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
      }
    }
  };

  if (resolutionType) {
    return (
      <>
        <DialogContent>
          <DialogContentText>
            {t(
              "The repair has already a resolution set. No other services can be proposed."
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddServiceClose}>{t("Cancel")}</Button>
        </DialogActions>
      </>
    );
  }
  return (
    <>
      <DialogContent>
        <DialogContentText>
          {t("Propose a service, will be calculated once accepted")}.
        </DialogContentText>
        <Stack direction={"row"} spacing={2} pt={3}>
          <TextField
            autoFocus
            id="name"
            label={t("Service name")}
            fullWidth
            variant="filled"
            value={name}
            multiline
            minRows={2}
            maxRows={2}
            onChange={nameChange}
            error={!!errors.name}
            helperText={errors.name}
          />
        </Stack>
        <Stack direction={"row"} spacing={2} pt={3}>
          <TextField
            id="unit-price"
            label={t("Unit price")}
            variant="filled"
            value={unitPrice}
            placeholder="0.00"
            onChange={unitPriceChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {getCurrencySymbol(currency)}
                </InputAdornment>
              ),
            }}
            error={!!errors.unitPrice}
            helperText={errors.unitPrice}
          />
          <TextField
            id="quantity"
            label={t("Quantity")}
            variant="filled"
            value={quantity}
            onChange={quantityChange}
            sx={{ width: "150px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">x</InputAdornment>
              ),
            }}
            error={!!errors.quantity}
            helperText={errors.quantity}
          />
          <TextField
            id="discount"
            label={t("Discount")}
            variant="filled"
            value={discount}
            placeholder="0.00"
            onChange={discountChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {getCurrencySymbol(currency)}
                </InputAdornment>
              ),
            }}
            error={!!errors.discount}
            helperText={errors.discount}
          />
        </Stack>
        <Stack direction={"row"} spacing={2} pt={3} alignItems={"center"}>
          <Typography>{t("dialogs.newService.assignee")}:</Typography>
          <AssignUserButton
            assignedTo={assignedTo}
            onSelectUser={onSelectUser}
            assignToMe={assignToMe}
          />
        </Stack>
        <Stack direction={"row"} spacing={2} pt={3}>
          <ToggleButtonGroup
            color="primary"
            value={stock ? stock : "null"}
            exclusive
            onChange={handleStockChange}
          >
            <ToggleButton value="1">{t("In stock")}</ToggleButton>
            <ToggleButton value={"null"}>{t("Unknown")}</ToggleButton>
            <ToggleButton value="0">{t("Out of stock")}</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddServiceClose}>{t("Cancel")}</Button>
        <LoadingButton
          onClick={handleAddNewService}
          loading={isHandlingRequest}
        >
          {t("Add")}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default NewServiceTab;
