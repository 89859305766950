import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

import EditableText from "./EditableText";
// import PatternPopover from "components/PatternDialog/PatternPopover";
import PatternLockDialog from "components/PatternDialog/PatternLockDialog";
import {
  IconButton,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  DialogContent,
  Tooltip,
} from "@mui/material";
import {
  Pattern,
  HighlightOffOutlined,
  PlayArrow,
  Pause,
  DeleteOutlineOutlined,
} from "@mui/icons-material";
import EditableTextSoft from "./EditableTextSoft";
import AnimatedPatternLock from "components/Password/AnimatedPatternLock";
import { Stack } from "@mui/system";
import { set } from "lodash";

const EditablePasswordV2 = ({
  password,
  isPasswordPattern,
  removePattern,
  onChange,
}) => {
  const [shouldAnimate, setShouldAnimate] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [deletePatternOpen, setDeletePatternOpen] = React.useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = (value) => {
    setOpen(false);
    if (value.length < 3) {
      return;
    }
    onChange({
      password: value,
      isPasswordPattern: true,
    });
  };

  const handleRemovePattern = () => {
    removePattern();
    setDeletePatternOpen(false);
  };

  const handleRemovePatternOpen = () => {
    setDeletePatternOpen(true);
  };

  const handleChange = (value) => {
    onChange({ password: value, isPasswordPattern: false });
  };

  const toggleAnimation = () => {
    setShouldAnimate((prev) => !prev);
  };

  return (
    <>
      {isPasswordPattern ? (
        <Stack alignItems={"start"}>
          {/* <Typography variant="caption">{password}</Typography> */}
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Stack direction="column" spacing={0} alignItems={"center"}>
              <AnimatedPatternLock
                value={password}
                shouldAnimate={shouldAnimate}
              />
              <Stack direction="row" spacing={1} alignItems={"center"}>
                <IconButton size="small" onClick={toggleAnimation}>
                  {shouldAnimate ? <Pause /> : <PlayArrow />}
                </IconButton>
                <IconButton size="small" onClick={handleRemovePatternOpen}>
                  <DeleteOutlineOutlined />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        // <Stack direction="row" spacing={1} alignItems={"center"}>
        <Stack direction="row" spacing={1} alignItems={"center"} flex={1}>
          <Tooltip title={t("Pattern")}>
            <IconButton size="small" onClick={handleClickOpen}>
              <Pattern />
            </IconButton>
          </Tooltip>
          <EditableTextSoft
            fullWidth
            placeholder={t("Password")}
            value={isPasswordPattern ? "" : password}
            onUpdate={handleChange}
            label={` ${isPasswordPattern ? t("Pattern") : t("Password")}`}
            disabled={isPasswordPattern}
          />
        </Stack>
        // </Stack>
      )}
      <PatternLockDialog
        open={open}
        onClose={handleClose}
        onAction={handleAction}
      />
      <Dialog
        open={deletePatternOpen}
        onClose={() => setDeletePatternOpen(false)}
      >
        <DialogTitle>{t("Remove Pattern")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Are you sure you want to remove the pattern?")}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setDeletePatternOpen(false)} autoFocus>
            {t("No")}
          </Button>
          <Button onClick={handleRemovePattern} color="primary">
            {t("Yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditablePasswordV2;
