// useUpdateOrderStatusMutation.js
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack"; // Correct import for useSnackbar
import dayjs from "dayjs";
import { cancelOutsourceRequest } from "api/repair-order-outsource";

const useCancelOutsourceRequest = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar(); // Use the useSnackbar hook to get enqueueSnackbar

  return useMutation(
    ({ repairOrderId }) =>
      cancelOutsourceRequest({
        repairOrderId,
      }),
    {
      onMutate: async ({ repairOrderId }) => {
        // Cancel any outgoing refetches
        console.log("ON MUTATE");
        await queryClient.cancelQueries({
          queryKey: ["repairOrder", repairOrderId],
        });
        // Snapshot the previous data
        const previousOrder = queryClient.getQueryData([
          "repairOrder",
          repairOrderId,
        ]);
        if (!previousOrder) {
          console.error("No previous order found");
          console.log("repairOrderId", repairOrderId);
          return;
        }

        // // Optimistically update the repair order with the new comment
        // queryClient.setQueryData(["repairOrder", repairOrderId], (old) => ({
        //   ...old,
        //   updatedAt: dayjs().toISOString(),
        //   repairOrderOutsourceRequest: null,
        // }));

        // Return context with the previous order for rollback
        return { previousOrder };
      },
      onError: (error, variables, context) => {
        if (context?.previousOrder) {
          queryClient.setQueryData(
            ["repairOrder", variables.repairOrderId],
            context.previousOrder
          );
        }
        console.error(
          error?.response?.data?.message || "Error updating service"
        );
        enqueueSnackbar("Failed to update service", { variant: "error" });
      },
      onSuccess: (data, variables) => {
        enqueueSnackbar("Service updated successfully", { variant: "success" });
      },
      onSettled: (data, error, variables) => {
        console.log("invalidateQueries", variables.repairOrderId);
        queryClient.invalidateQueries(["repairOrder", variables.repairOrderId]);
      },
    }
  );
};

export default useCancelOutsourceRequest;
