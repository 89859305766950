// import { Fragment } from "react";
// import { useState } from "react";
import { connect } from "react-redux";
// import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  // Stepper,
  // Step,
  // StepLabel,
  Button,
  // Typography,
  DialogActions,
  DialogContent,
  Grid,
  FormControl,
  TextField,
} from "@mui/material";
import * as actions from "../../store/actions";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { newCustomerModalActions } from "../../store/new-customer-modal";
import axios from "../../axios-config";
import { useTranslation } from "react-i18next";

const NewClientModal = ({ open, onClose, onNewClientAdd, inputValue }) => {
  const { t } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.newClientModal);

  useEffect(() => {
    if (inputValue.length > 0) {
      // console.log("Input value: ", inputValue)
      // is it phone number or name?
      const phone = inputValue.match(/^[0-9]+$/) ? inputValue : "";
      const name = inputValue.match(/^[0-9]+$/) ? "" : inputValue;
      if (phone.length > 0) {
        dispatch(newCustomerModalActions.updatePhone(phone));
      } else {
        dispatch(newCustomerModalActions.updateName(name));
      }
    }
  }, [open]);

  useEffect(() => {
    // reset form on close
    if (!open) {
      dispatch(newCustomerModalActions.clear());
    }
  }, [open]);

  const handleAddNewClient = async () => {
    try {
      const response = await axios.post(
        `/repair-orders/customer`,
        modalState.customerData
      );
      if (response.status === 200 || response.status === 201) {
        if (onNewClientAdd) {
          onNewClientAdd(response.data);
        }
        handleClose();
        dispatch(newCustomerModalActions.clear());
        enqueueSnackbar(`Client added succesfully`, { variant: "success" });
      }
    } catch (err) {
      enqueueSnackbar(`Something went wrong!`, { variant: "error" });
    }
  };

  const submitDisableHandler = () => {
    const validName = modalState.customerData.name.length > 0;
    const validPhone = modalState.customerData.phone.length > 0;
    const validEmail = String(modalState.customerData.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (validName) return false;
    return true;
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">
        {t("dialogs.newCustomer.title")}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal" variant="outlined">
          <TextField
            required
            label={t("dialogs.newCustomer.name")}
            id="name"
            variant="filled"
            size="small"
            value={modalState.customerData.name}
            onChange={(e) =>
              dispatch(newCustomerModalActions.updateName(e.target.value))
            }
          />
        </FormControl>
        <FormControl fullWidth margin="normal" variant="outlined">
          <TextField
            label={t("dialogs.newCustomer.phone")}
            id="phone"
            variant="filled"
            size="small"
            value={modalState.customerData.phone}
            onChange={(e) =>
              dispatch(newCustomerModalActions.updatePhone(e.target.value))
            }
          />
        </FormControl>
        <FormControl fullWidth margin="normal" variant="outlined">
          <TextField
            label={t("dialogs.newCustomer.email")}
            id="email"
            variant="filled"
            size="small"
            value={modalState.customerData.email}
            onChange={(e) =>
              dispatch(newCustomerModalActions.updateEmail(e.target.value))
            }
          />
        </FormControl>
        <FormControl fullWidth margin="normal" variant="outlined">
          <TextField
            label={t("dialogs.newCustomer.taxIdentifier")}
            id="taxIdentifier"
            variant="filled"
            size="small"
            value={modalState.customerData.taxIdentifier}
            onChange={(e) =>
              dispatch(
                newCustomerModalActions.updateTaxIdentifier(e.target.value)
              )
            }
          />
        </FormControl>
        <FormControl fullWidth margin="normal" variant="outlined">
          <TextField
            label={t("dialogs.newCustomer.address")}
            id="address"
            variant="filled"
            size="small"
            value={modalState.customerData.address}
            onChange={(e) =>
              dispatch(newCustomerModalActions.updateAddress(e.target.value))
            }
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button disableElevation color="primary" onClick={handleClose}>
          {t("dialogs.newCustomer.cancel")}
        </Button>
        <Button
          disabled={submitDisableHandler()}
          disableElevation
          variant="contained"
          color="primary"
          onClick={handleAddNewClient}
        >
          {t("dialogs.newCustomer.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideNewEntryModal: () => dispatch(actions.toggleNewClientModal(false)),
    addNewClient: (customer, token) =>
      dispatch(actions.addNewClient(customer, token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewClientModal);
