// LineChart.js
import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { format, parseISO, startOfWeek, startOfMonth } from "date-fns";
import { Stack, Typography } from "@mui/material";
import { Circle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { differenceInMonths } from "date-fns";

const NewRepairsLineChart = ({ repairs }) => {
  let dayjs = require("dayjs");
  const { t } = useTranslation();
  const groupOrdersByWeek = (orders) => {
    const groupedOrders = {};

    orders.forEach((order) => {
      const weekStart = format(
        startOfWeek(parseISO(order.createdAt)),
        "yyyy-MM-dd"
      );
      if (!groupedOrders[weekStart]) {
        groupedOrders[weekStart] = 0;
      }
      groupedOrders[weekStart]++;
    });

    return Object.keys(groupedOrders).map((week) => ({
      x: week,
      y: groupedOrders[week],
    }));
  };

  const groupOrdersByMonth = (orders) => {
    const groupedOrders = {};

    orders.forEach((order) => {
      const month = format(startOfMonth(parseISO(order.createdAt)), "yyyy-MM");
      if (!groupedOrders[month]) {
        groupedOrders[month] = 0;
      }
      groupedOrders[month]++;
    });

    return Object.keys(groupedOrders).map((month) => ({
      x: month,
      y: groupedOrders[month],
    }));
  };

  const processData = (orders) => {
    if (orders.length === 0) return [];

    const startDate = new Date(orders[0].createdAt);
    const endDate = new Date(orders[orders.length - 1].createdAt);

    const periodLengthInMonths = differenceInMonths(endDate, startDate);

    if (periodLengthInMonths === 0) {
      // Show by day if period is within the same month
      return orders
        .map((order) => ({
          x: format(parseISO(order.createdAt), "yyyy-MM-dd"),
          y: 1,
        }))
        .reduce((acc, order) => {
          const existing = acc.find((o) => o.x === order.x);
          if (existing) {
            existing.y += 1;
          } else {
            acc.push(order);
          }
          return acc;
        }, []);
    } else if (periodLengthInMonths <= 3) {
      // Show by week if period is between 1 and 3 months
      return groupOrdersByWeek(orders);
    } else {
      // Show by month if period is more than 3 months
      return groupOrdersByMonth(orders);
    }
  };

  const generateTicks = (data) => {
    const tickCount = Math.min(4, data.length); // Ensure the number of ticks does not exceed 4
    const tickInterval = Math.ceil(data.length / tickCount); // Determine interval based on the length of data

    return data
      .filter((_, index) => index % tickInterval === 0)
      .map((d) => d.x);
  };

  const generateYTicks = (data) => {
    const maxOrders = Math.max(
      ...data.map((d) => Math.max(...d.data.map((point) => point.y)))
    );
    const tickCount = 6;
    const tickInterval = Math.ceil(maxOrders / tickCount); // Determine interval based on the maximum number of orders

    return Array.from({ length: tickCount }, (_, i) => i * tickInterval);
  };

  const processedData = processData(repairs);

  const data = [
    {
      id: t("charts.repairs"),
      data: processedData,
    },
  ];
  const xTickValues = generateTicks(processedData);
  const yTickValues = generateYTicks(data);
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 35, right: 35, bottom: 50, left: 50 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "0",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 15,
        tickRotation: 0,
        // legend: 'Time',
        legendOffset: 36,
        legendPosition: "middle",
        tickValues: xTickValues, // Use the dynamically generated ticks
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: t("charts.repairs"),
        legendOffset: -40,
        legendPosition: "middle",
        tickValues: yTickValues, // Use the array of integers as tick values
      }}
      colors={{ scheme: "nivo" }}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      animate={false}
      tooltip={(slice) => {
        return (
          <Stack
            style={{
              backgroundColor: "white",
              padding: "8px",
              //   px: "10px",
              border: "1px solid",
              borderColor: "lightGrey.main",
              borderRadius: "5px",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            }}
          >
            <Typography variant="body2">
              {/* {endDate.diff(startDate, 'month') > 2 ?
                                    dayjs(slice.point.data.x).format("MMM.YYYY") : dayjs(slice.point.data.x).format("DD.MMM.YYYY")} */}
              {slice.point.data.x}
            </Typography>
            <Stack direction={"row"} alignItems={"center"}>
              <Circle
                sx={{ color: slice.point.serieColor, fontSize: "18px" }}
              />
              <Typography>
                {slice.point.serieId}: {slice.point.data.y}
              </Typography>
            </Stack>
          </Stack>
        );
      }}
      // legends={[
      //     {
      //         anchor: 'bottom-right',
      //         direction: 'column',
      //         justify: false,
      //         translateX: 100,
      //         translateY: 0,
      //         itemsSpacing: 0,
      //         itemDirection: 'left-to-right',
      //         itemWidth: 80,
      //         itemHeight: 20,
      //         itemOpacity: 0.75,
      //         symbolSize: 12,
      //         symbolShape: 'circle',
      //         symbolBorderColor: 'rgba(0, 0, 0, .5)',
      //         effects: [
      //             {
      //                 on: 'hover',
      //                 style: {
      //                     itemBackground: 'rgba(0, 0, 0, .03)',
      //                     itemOpacity: 1
      //                 }
      //             }
      //         ]
      //     }
      // ]}
    />
  );
};

export default NewRepairsLineChart;
