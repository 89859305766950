// EditableText.jsx
import React, { useRef, useState } from "react";
import { TextField, Typography } from "@mui/material";
import { set } from "lodash";
import sanitizations from "utils/sanitizations";
import SoftTextField from "common/SoftTextField";

function EditableNumberSoft({
  label,
  value,
  onUpdate,
  validate,
  placeholder,
  onlyFloat,
  onlyPositive,
  onlyInteger,
  onlyPositiveInteger,
  onlyNegative,
  greaterThan,
  lessThan,
  withinRange,
  ...props
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const [error, setError] = useState("");

  const saveTriggeredRef = useRef(false); // Added flag to prevent double save on enter key

  // Handler to initiate editing
  const handleStartEditing = () => {
    setIsEditing(true);
  };

  const preventDoubleSaveOnEnterKey = () => {
    if (saveTriggeredRef.current) {
      saveTriggeredRef.current = false; // Reset the flag
      return true;
    }
    return false;
  };

  // Handler to save changes
  const handleSave = () => {
    //Maybe we'll need this later
    // if (validate) {
    //   const validation = validate(formattedValue);
    //   if (!validation.isValid) {
    //     setError(validation.message);
    //     return;
    //   }
    // }
    if (preventDoubleSaveOnEnterKey()) return;
    if (tempValue === "") {
      setTempValue("");
      if (value !== tempValue) onUpdate("");
      setIsEditing(false);
      setError("");
      return;
    }

    const formattedValue = onlyFloat
      ? parseFloat(tempValue).toFixed(2)
      : tempValue;
    setTempValue(formattedValue);
    if (formattedValue !== value) {
      onUpdate(formattedValue);
    }
    setIsEditing(false);
    setError("");
  };

  // Handler to cancel editing
  const handleCancel = () => {
    setIsEditing(false);
    setTempValue(value);
    setError("");
  };

  const handleChange = (e) => {
    let input = e.target.value;
    // // Allow only digits and up to two decimal places
    // const sanitized = input.replace(/[^0-9.]/g, "");
    // // Optional: Further sanitize to allow only one decimal point
    // const parts = sanitized.split(".");
    // if (parts.length > 2) {
    //   setTempValue(parts[0] + "." + parts[1]);
    // } else {
    //   setTempValue(sanitized);
    // }
    if (onlyFloat) {
      console.log("onlyFloat");
    }
    if (onlyFloat) input = sanitizations.onlyFloat(input);
    if (onlyPositive) input = sanitizations.onlyPositive(input);
    if (onlyInteger) input = sanitizations.onlyInteger(input);
    if (onlyPositiveInteger) input = sanitizations.onlyPositiveInteger(input);
    if (onlyNegative) input = sanitizations.onlyNegative(input);
    if (greaterThan !== undefined)
      input = sanitizations.greaterThan(input, greaterThan);
    if (lessThan !== undefined) input = sanitizations.lessThan(input, lessThan);
    if (withinRange !== undefined)
      input = sanitizations.withinRange(input, withinRange[0], withinRange[1]);

    setTempValue(input);
  };

  return (
    <SoftTextField
      label={label}
      value={isEditing ? tempValue : value}
      onChange={handleChange}
      onClick={!isEditing ? handleStartEditing : undefined}
      onFocus={() => setTempValue(value)}
      onBlur={() => {
        if (isEditing && !saveTriggeredRef.current) handleSave();
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          saveTriggeredRef.current = true; // Set the flag
          handleSave();
          e.target.blur();
        }
        if (e.key === "Escape") {
          handleCancel();
        }
      }}
      // variant="filled"
      placeholder={placeholder}
      {...props}
      // Toggle readOnly based on editing state
      //   InputProps={{
      //     readOnly: !isEditing,
      //   }}
      //   error={!!error}
      //   helperText={error}
    />
  );
}

export default React.memo(EditableNumberSoft);
