import React, { useState, useEffect, useRef, memo } from "react";
import {
  Grid,
  TextField,
  Fab,
  Divider,
  List,
  Stack,
  ToggleButton,
  Box,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CommentText from "./CommentText";
import axios from "../../../axios-config";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import StyledToggleButtonGroup from "../../../components/StyledToggleButtonGroup/StyledToggleButtonGroup";
import { useTranslation } from "react-i18next";
import { GroupOutlined } from "@mui/icons-material";
import ConfirmationDialog from "common/ConfirmDialog";
import useDeleteComment from "hooks/mutations/orders/useDeleteComment";

function Comments({
  repairOrderId,
  commentTabIndex,
  comments,
  handleImageClick,
}) {
  const { t } = useTranslation();
  const [commentToDelete, setCommentToDelete] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const bottomRef = useRef(null);
  const auth = useSelector((state) => state.auth);

  const deleteComment = useDeleteComment();
  const handleDeleteComment = () => {
    deleteComment.mutate({
      commentId: commentToDelete.id,
      repairOrderId: repairOrderId,
    });
    setCommentToDelete(null);
  };

  if (!comments) {
    return null;
  }
  const isPublicBool = commentTabIndex === 1;

  const filteredComments = comments
    .slice()
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .filter((comment) => comment.isPublic === isPublicBool);

  let newTooltipsRendered = 0;

  const handleOnDeleteClick = (comment) => {
    setCommentToDelete(comment);
  };

  return (
    <>
      <Stack flex={"1"} height={"100%"} borderColor={"lightGrey.main"}>
        <Box flex={1} py={"0px"}>
          <Box>
            {/* <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}  >
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            {getIsPublicAsBool() ? <GroupOutlined /> : null}
                            <Typography flex={1} variant="h4">{getIsPublicAsBool() ? t('Comments with partner') : t('Private comments')} {filteredComments.length > 0 ? `(${filteredComments.length})` : null} </Typography>
                        </Stack>

                        {orderIsShared ?
                            <StyledToggleButtonGroup
                                size="small"
                                value={isPublic}
                                exclusive
                                onChange={(e) => {
                                    // console.log(e.target.value, 'target')
                                    setIsPublic(e.target.value);
                                }}
                                aria-label="text alignment"
                                sx={{ backgroundColor: "#F1F9FD" }}
                            >
                                <ToggleButton value={'private'}>{t('Private')}: {privateCommentsLength}</ToggleButton>
                                <ToggleButton value={'public'}>{t('Partner')}: {publicCommentsLength}</ToggleButton>
                            </StyledToggleButtonGroup>
                            : null}

                    </Stack> */}
            <Stack spacing={"15px"} mt={"15px"}>
              {filteredComments.map((comment) => {
                if (comment.user.id == auth.userId && newTooltipsRendered < 1) {
                  newTooltipsRendered++;
                  // RENDER NEW FEATURE TOOLTIP ONCE
                  return (
                    <CommentText
                      newFeatureRendered={true}
                      comment={comment}
                      key={comment.id}
                      auth={auth}
                      onDeleteClick={handleOnDeleteClick}
                      handleImageClick={handleImageClick}
                    />
                  );
                }
                return (
                  <CommentText
                    comment={comment}
                    key={comment.id}
                    auth={auth}
                    onDeleteClick={handleOnDeleteClick}
                    handleImageClick={handleImageClick}
                  />
                );
              })}

              {/* <Divider variant="inset" component="li" /> */}
            </Stack>

            {comments.length == 0 ? (
              <Typography>{t("No comments")}</Typography>
            ) : null}
            <div ref={bottomRef}></div>
          </Box>
        </Box>
      </Stack>

      <ConfirmationDialog
        open={Boolean(commentToDelete)}
        title={t("dialogs.deleteComment.title")}
        message={t("dialogs.deleteComment.message")}
        onConfirm={() => {
          handleDeleteComment();
        }}
        onClose={() => setCommentToDelete(false)}
      />
    </>
  );
}

export default memo(Comments);
