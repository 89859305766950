// AUTOCOMPLETE
import axios from "../../axios-config";
import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import { TextField } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { newEntryModalActions } from "../../store/new-entry-modal";
import { useTranslation } from "react-i18next";
import { yellow } from "@mui/material/colors";

const BrandSearch = ({ fullWidth }) => {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(["Haple", "Join"]);
  const loading = open && options.length === 0;
  const brandState = useSelector(
    (state) => state.newEntryModal.entryData.brand
  );

  const filter = createFilterOptions();

  const dispatch = useDispatch();
  const updateBrand = (payload) => {
    dispatch(newEntryModalActions.updateBrand(payload));
  };
  React.useEffect(async () => {
    try {
      let active = true;
      if (!loading) {
        return undefined;
      }
      const response = await axios.get(`/repair-orders/brands`);
      const data = response.data;
      if (active) {
        setOptions(
          data.map((value) => {
            return { name: value };
          })
        );
      }
      return () => {
        active = false;
      };
    } catch (err) {
      console.log(err);
    }
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      // sx={{
      //   backgroundColor:
      //     suggestionBrand !== "" && suggestionBrand === brandState
      //       ? yellow[100]
      //       : "inherit",
      //   color:
      //     suggestionBrand !== "" && suggestionBrand === brandState
      //       ? "white"
      //       : "inherit",
      //   "& .MuiFilledInput-root": {
      //     backgroundColor: "inherit",
      //   },
      //   "& .Mui-focused": {
      //     borderColor: yellow[200],
      //   },
      // }}
      fullWidth={fullWidth}
      freeSolo
      loading={loading}
      size="small"
      value={brandState}
      open={open}
      onChange={(event, newValue) => {
        // updateCategory({
        //   name: response.data.name,
        //   id: response.data.id
        // });

        updateBrand(newValue);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => {
        return option.name;
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            name: `+ "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      id="free-solo-dialog-demo"
      options={options}
      getOptionLabel={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.name;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      renderInput={(params) => (
        <TextField
          required
          {...params}
          variant={"filled"}
          label={t("Brand")}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default BrandSearch;
