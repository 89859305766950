import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Popover, Box } from "@mui/material";
import CategorySearch from "components/NewEntryStepper/CategorySearch";
import PropTypes from "prop-types";
import { ExpandMoreOutlined } from "@mui/icons-material";

const CategoryButton = ({ deviceCategory, onCategoryUpdate }) => {
  const { t } = useTranslation();
  const [categoryAnchor, setCategoryAnchor] = React.useState(null);
  const categoryOpen = Boolean(categoryAnchor);

  const openCategoryPopover = async (event) => {
    setCategoryAnchor(event.currentTarget);
  };

  const handleCategorySelect = (selectedCategory) => {
    setCategoryAnchor(null);
    onCategoryUpdate(selectedCategory);
  };

  return (
    <>
      <Button
        onClick={openCategoryPopover}
        variant="text"
        color="black"
        size="small"
        sx={{
          borderRadius: "10px",
          borderColor: "black",
          textTransform: "none",
          fontSize: "0.75rem",
        }}
        endIcon={deviceCategory && <ExpandMoreOutlined />}
      >
        {deviceCategory ? deviceCategory.name : `+ ${t("Category")}`}
      </Button>
      <Popover
        open={categoryOpen}
        anchorEl={categoryAnchor}
        onClose={() => {
          setCategoryAnchor(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box p={2} width={"250px"}>
          <CategorySearch
            onCategoryUpdate={handleCategorySelect}
            value={deviceCategory}
          />
        </Box>
      </Popover>
    </>
  );
};

CategoryButton.propTypes = {
  deviceCategory: PropTypes.object,
  onCategoryUpdate: PropTypes.func.isRequired,
};

export default CategoryButton;
