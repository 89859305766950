import React from "react";
import { Box, Stack, Typography, Tooltip, Button } from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { grey } from "@mui/material/colors";
import { formatCurrency } from "utils/currency";
import BaseItemList from "components/ItemList/BaseItemList";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ServiceProposed = ({
  service,
  onDelete,
  onClick,
  handleAcceptService,
  handleDeclineService,
}) => {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.auth.currency);

  const onDeleteButtonClick = (e) => {
    e.stopPropagation();
    if (onDelete !== undefined) onDelete(service);
  };

  const handleAcceptClick = (e) => {
    e.stopPropagation();
    handleAcceptService(service);
  };

  const handleDeclineClick = (e) => {
    e.stopPropagation();
    handleDeclineService(service);
  };

  const InStock = () => {
    if (service.stock === true || service.stock === "true")
      return <Box>👍 IN STOCK</Box>;
    if (service.stock === false || service.stock === "false")
      return <Box>❌ OUT OF STOCK</Box>;
    return null;
  };

  const handleItemClick = () => {
    onClick(service);
  };

  return (
    <>
      <BaseItemList
        onClick={
          onClick !== undefined && !service.isCreating ? handleItemClick : null
        }
        itemIcon={<CircleOutlinedIcon color={grey[600]} />}
        mainStack={
          <>
            <Stack flexGrow={1} overflow={"auto"}>
              {/* <Tooltip enterDelay={20} title={service.name}> */}
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  fontWeight: 500,
                }}
              >
                {service.name}
              </Typography>
              {/* </Tooltip> */}
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography variant="caption" color={grey[600]} noWrap>
                ({formatCurrency(service.unitPrice, currency)} x
                {service.quantity})
              </Typography>
              <Typography variant="caption" color={grey[600]} noWrap>
                {service.discount <= 0 ? null : <>-</>}
              </Typography>
              <Typography variant="caption" color={grey[600]} noWrap>
                {service.discount <= 0 ? null : (
                  <>
                    {formatCurrency(service.discount, currency)} {t("Discount")}
                  </>
                )}
              </Typography>
              <Typography variant="body" fontWeight={500} noWrap>
                {formatCurrency(
                  service.unitPrice * service.quantity - service.discount,
                  currency
                )}
              </Typography>
            </Stack>
          </>
        }
        footerStack={
          <>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Button
                size="small"
                color="green"
                variant="outlined"
                disabled={service.isCreating}
                onClick={handleAcceptClick}
              >
                {t("Accept")}
              </Button>
              <Button
                size="small"
                color="red"
                variant="outlined"
                onClick={handleDeclineClick}
                disabled={service.isCreating}
              >
                {t("Decline")}{" "}
              </Button>
              <InStock />
            </Stack>
          </>
        }
        onDeleteButtonClick={
          onDelete !== undefined && !service.isCreating
            ? onDeleteButtonClick
            : null
        }
      />
    </>
  );
};

export default ServiceProposed;
