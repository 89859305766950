import * as React from "react";
import { useEffect, useState } from "react";
import axios from "../axios-config";
import { useDispatch } from "react-redux";
import { dataTableActions } from "../store/data-table";

import {
  Button,
  Checkbox,
  Grid,
  Stack,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Box,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
  Tooltip,
  CircularProgress,
  Chip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useCreateOutsourceRequest from "hooks/mutations/orders/outsource/useCreateOutsourceRequest";
import LabelTypography from "containers/EditOrder/LabelTypography";
import dayjs from "dayjs";
import useCancelOutsourceRequest from "hooks/mutations/orders/outsource/useCancelOutsourceRequest";
import { LoadingButton } from "@mui/lab";

const OutsourcePartnerSelect = ({
  repairOrderId,
  repairOrderOutsourceRequest,
}) => {
  //   return null;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOutsourceTo, setSelectedOutsourceTo] = useState("");
  const createOutsourceRequest = useCreateOutsourceRequest();
  const cancelOutsourceRequest = useCancelOutsourceRequest();
  //   const dispatch = useDispatch();

  useEffect(async () => {
    try {
      const response = await axios.get(`organizations/partners`);
      if (response.data) {
        setPartners(response.data);
        if (repairOrderOutsourceRequest) {
          //   setSelectedOutsourceTo(
          //     repairOrder.repairOrderOutsourceRequest.toOrganizationId
          //   );
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);
  const cancelRequest = () => {
    cancelOutsourceRequest.mutate({
      repairOrderId: repairOrderId,
    });
  };
  const handleOutsourceSelect = async (e) => {
    const value = e.target.value;
    createOutsourceRequest.mutate({
      repairOrderId: repairOrderId,
      toOrganization: value,
    });
  };

  if (repairOrderOutsourceRequest) {
    return (
      <>
        {/* Show current request */}
        <Stack spacing={2}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <LabelTypography text={t("outsourcePartnerSelect.outsourceTo")} />
            <Typography variant="body" fontWeight={500}>
              {repairOrderOutsourceRequest.outsourceReceiver?.name}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <LabelTypography
              text={t("outsourcePartnerSelect.outsourceStatus")}
            />
            <Chip label={t("outsourcePartnerSelect.pendingPartner")} />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <LabelTypography text={t("outsourcePartnerSelect.outsourceAt")} />
            <Typography>
              {dayjs(repairOrderOutsourceRequest.createdAt).format(
                "DD.MMM.YYYY HH:mm"
              )}
            </Typography>
          </Stack>
          {/* Cancel */}
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <LoadingButton
              size="small"
              color="black"
              variant="contained"
              onClick={cancelRequest}
              loading={cancelOutsourceRequest.isLoading}
            >
              {t("outsourcePartnerSelect.cancelOutsource")}
            </LoadingButton>
          </Stack>
        </Stack>
      </>
    );
  }
  return (
    <FormControl fullWidth variant="filled">
      <InputLabel>{t("Outsource to partner")}</InputLabel>
      <Select
        labelId="partner-select-label"
        id="partner-select"
        label={t("Outsource to partner")}
        value={selectedOutsourceTo}
        onChange={handleOutsourceSelect}
        endAdornment={
          loading ? (
            <Box sx={{ display: "flex", marginRight: "25px" }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : null
        }
      >
        <MenuItem value={0}>{t("Select a partner")}</MenuItem>
        {partners.map((partner) => (
          <MenuItem value={partner} key={partner.id}>
            {partner.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OutsourcePartnerSelect;
