import { useState, useEffect, memo } from "react";
import { Box, display, maxWidth, minWidth } from "@mui/system";
import {
  Typography,
  Button,
  IconButton,
  Tab,
  Tabs,
  InputAdornment,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import axios from "../../../axios-config";
import { useSnackbar } from "notistack";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import ReactiveTextField from "../../../components/ReactiveTextField/ReactiveTextField";
import { useSelector } from "react-redux";
import DebouncedTextField from "../../../common/DebouncedTextField";
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from "../../../utils/currency";
import AssignUserButton from "components/AssignUserButton/AssignUserButton";
import { ca } from "react-date-range/dist/locale";
import EditableText from "../EditableText";
import EditableNumber from "../EditableNumber";
import useUpdateServiceField from "hooks/mutations/orders/useUpdateServiceField";
import useAssignUserToService from "hooks/mutations/orders/useAssignUserToService";

const EditServiceDialog = ({
  open,
  onClose,
  selectedServiceId,
  services,
  resolutionType,
}) => {
  const service = services.find((s) => s.id === selectedServiceId);

  const updateServiceFiled = useUpdateServiceField();
  const assignUserToService = useAssignUserToService();
  const auth = useSelector((state) => state.auth);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const currency = useSelector((state) => state.auth.currency);

  const assignToMe = () => {
    if (auth.userName && auth.userId) {
      const user = { id: auth.userId, name: auth.userName, email: auth.email };
      assignUserToService.mutate({
        repairOrderId: service.repairOrderId,
        serviceId: service.id,
        user,
      });
    }
  };

  const onSelectUser = (user) => {
    assignUserToService.mutate({
      repairOrderId: service.repairOrderId,
      serviceId: service.id,
      user,
    });
  };

  const onBlur = (key, value) => {
    updateServiceFiled.mutate({
      repairOrderId: service.repairOrderId,
      serviceId: service.id,
      key,
      value,
    });
  };

  if (service === null || service === undefined) return null;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"xs"}>
      <DialogTitle variant="h4">Service Edit</DialogTitle>
      <DialogContent>
        <Stack direction={"row"} spacing={2} pt={3}>
          <EditableText
            label={t("Name")}
            value={service.name}
            disabled={resolutionType !== null && resolutionType !== undefined}
            onUpdate={(value) => onBlur("name", value)}
            multiline
            maxRows={3}
            minRows={1}
          />
        </Stack>
        <Stack direction={"row"} spacing={2} pt={3}>
          {/* Change it with debounce */}
          <EditableNumber
            label={t("Unit price")}
            value={service.unitPrice}
            onUpdate={(value) => onBlur("unitPrice", value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {getCurrencySymbol(currency)}
                </InputAdornment>
              ),
            }}
            disabled={resolutionType !== null && resolutionType !== undefined}
            onlyFloat
            onlyPositive
            withinRange={[0, 9999999]}
          />
          <EditableNumber
            label={t("Quantity")}
            value={service.quantity}
            onUpdate={(value) => onBlur("quantity", value)}
            sx={{
              maxWidth: "130px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">x</InputAdornment>
              ),
            }}
            onlyPositiveInteger
            // withinRange={[1, 99]}
          />
          <EditableNumber
            label={t("Discount")}
            value={service.discount}
            onUpdate={(value) => onBlur("discount", value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {getCurrencySymbol(currency)}
                </InputAdornment>
              ),
            }}
            disabled={resolutionType !== null && resolutionType !== undefined}
            onlyFloat
            onlyPositive
            withinRange={[0, 9999999]}
          />
        </Stack>
        <Stack direction={"row"} spacing={2} pt={3} alignItems={"center"}>
          <Typography>{t("dialogs.newService.assignee")}:</Typography>
          <AssignUserButton
            assignedTo={service.assignedTo}
            onSelectUser={onSelectUser}
            assignToMe={assignToMe}
          />
        </Stack>
        {!resolutionType && (
          <Stack direction={"row"} spacing={2} pt={3}>
            <ToggleButtonGroup
              color="primary"
              value={service.stock === null ? "null" : service.stock.toString()}
              exclusive
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  onBlur(
                    "stock",
                    newValue === "null" ? null : newValue === "true"
                  );
                }
              }}
            >
              <ToggleButton value={"true"}>{t("In stock")}</ToggleButton>
              <ToggleButton value={"null"}>{t("Unknown")}</ToggleButton>
              <ToggleButton value={"false"}>{t("Out of stock")}</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(EditServiceDialog);
