// useUpdateOrderStatusMutation.js
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack"; // Correct import for useSnackbar
import dayjs from "dayjs";
import { createOutsourceRequest } from "api/repair-order-outsource";

const useCreateOutsourceRequest = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar(); // Use the useSnackbar hook to get enqueueSnackbar

  return useMutation(
    ({ repairOrderId, toOrganization }) =>
      createOutsourceRequest({
        repairOrderId,
        toOrganizationId: toOrganization.id,
      }),
    {
      onMutate: async ({ repairOrderId, toOrganization }) => {
        // Cancel any outgoing refetches
        await queryClient.cancelQueries({
          queryKey: ["repairOrder", repairOrderId],
        });
        // Snapshot the previous data
        const previousOrder = queryClient.getQueryData([
          "repairOrder",
          repairOrderId,
        ]);
        if (!previousOrder) {
          console.error("No previous order found");
          console.log("repairOrderId", repairOrderId);
          return;
        }

        //   // Optimistically update the repair order with the new comment
        //   queryClient.setQueryData(["repairOrder", repairOrderId], (old) => ({
        //     ...old,
        //     updatedAt: dayjs().toISOString(),
        //     services: old.services.map((service) =>
        //       service.id === serviceId
        //         ? {
        //             ...service,
        //             isAccepted: true,
        //             decisionAt: dayjs().toISOString(),
        //           }
        //         : service
        //     ),
        //   }));

        // Return context with the previous order for rollback
        return { previousOrder };
      },
      onError: (error, variables, context) => {
        if (context?.previousOrder) {
          queryClient.setQueryData(
            ["repairOrder", variables.repairOrderId],
            context.previousOrder
          );
        }
        console.error(
          error?.response?.data?.message || "Error updating service"
        );
        enqueueSnackbar("Failed to update service", { variant: "error" });
      },
      onSuccess: (data, variables) => {
        enqueueSnackbar("Service updated successfully", { variant: "success" });
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(["repairOrder", variables.repairOrderId]);
      },
    }
  );
};

export default useCreateOutsourceRequest;
