import React, { useState } from "react";
import { InputAdornment, TextField, IconButton } from "@mui/material";
import PatternPopover from "./PatternPopover";
import { Pattern, HighlightOffOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import PatternLockDialog from "components/PatternDialog/PatternLockDialog";

function PasswordTextField({
  value,
  setValue,
  isPasswordPattern,
  setIsPasswordPattern,
  onBlur,
  onRemovePattern,
  size = "small",
}) {
  // const [path, setPath] = useState([]);
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  // const [value, setValue] = useState("");
  // const [isUsingPattern, setIsUsingPattern] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = (value) => {
    setOpen(false);
    if (value.length < 3) {
      return;
    }
    setValue(value);
    setIsPasswordPattern(true);
    if (onBlur) {
      onBlur();
    }
  };

  const removePattern = () => {
    setValue("");
    setIsPasswordPattern(false);
    if (onRemovePattern) {
      onRemovePattern();
    }
  };

  return (
    <>
      <TextField
        variant="filled"
        size={size}
        fullWidth
        label={` ${isPasswordPattern ? t("Pattern") : t("Password")}`}
        // inputProps={{ inputMode: "numeric" }}
        id="outlined-adornment-weight"
        value={isPasswordPattern ? "" : value}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
        }}
        onChange={(e) => {
          setIsPasswordPattern(false);
          setValue(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <>
              {isPasswordPattern && (
                <InputAdornment
                  position="start"
                  onMouseEnter={(e) => {
                    if (isPasswordPattern) {
                      setAnchorEl(e.currentTarget);
                    }
                  }}
                  onMouseLeave={() => {
                    setAnchorEl(null);
                  }}
                >
                  <Pattern color="primary" />
                </InputAdornment>
              )}
              {isPasswordPattern && (
                <InputAdornment position="start">
                  {isPasswordPattern ? value : ""}
                </InputAdornment>
              )}
            </>
          ),
          endAdornment: (
            <>
              {!isPasswordPattern && (
                <InputAdornment position="end">
                  <IconButton
                    color={isPasswordPattern ? "primary" : "inherit"}
                    onClick={handleClickOpen}
                  >
                    <Pattern />
                  </IconButton>
                </InputAdornment>
              )}
              {isPasswordPattern && (
                <InputAdornment position="end">
                  <IconButton onClick={removePattern}>
                    <HighlightOffOutlined />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
        aria-describedby="outlined-helper-text"
      />

      <PatternPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        value={value}
        valueSetUsingPattern={isPasswordPattern}
      />

      <PatternLockDialog
        open={open}
        onClose={handleClose}
        onAction={handleAction}
      />
    </>
  );
}

export default PasswordTextField;
